export const initialCropState = {
  unit: 'px',
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

export const SIZE_LIMIT_WIDTH = 1000000;

export const SIZE_LIMIT_HEIGHT = 1000000;

export const DEFAULT_ALT = 'Draint';
