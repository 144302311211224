export const GET_WATCHLIST = 'GET_WATCHLIST';

export const GET_WATCHLIST_SUCCESS = 'GET_WATCHLIST_SUCCESS';

export const DELETE_FROM_WATCHLIST_SUCCESS = 'DELETE_FROM_WATCHLIST_SUCCESS';

export const SET_WATCHLIST_LOADING_FALSE = 'SET_WATCHLIST_LOADING_FALSE';

export const SET_RESALE_WATCHLIST_SUCCESS = 'SET_RESALE_WATCHLIST_SUCCESS';

export const SET_SALE_WATCHLIST_SUCCESS = 'SET_SALE_WATCHLIST_SUCCESS';

export const ADD_ARTWORK_TO_CART_IN_WATCHLIST =
  'ADD_ARTWORK_TO_CART_IN_WATCHLIST';

export const SET_SELECTED_ARTWORK = 'SET_SELECTED_ARTWORK';
