import * as React from 'react';

const ArrowFilledUpViolet = props => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.7401 10.6778C13.5671 10.851 13.3619 10.9375 13.1249 10.9375H0.875066C0.637935 10.9375 0.432936 10.851 0.259733 10.6778C0.0865297 10.5044 0 10.2994 0 10.0624C0 9.82545 0.0865297 9.62045 0.259733 9.4472L6.38467 3.32226C6.55806 3.14906 6.76306 3.06234 7 3.06234C7.23694 3.06234 7.44213 3.14906 7.61519 3.32226L13.7401 9.44724C13.9131 9.62045 14 9.82545 14 10.0624C14 10.2994 13.9131 10.5044 13.7401 10.6778Z"
        fill="#806BFF"
      />
    </svg>
  );
};

export default ArrowFilledUpViolet;
