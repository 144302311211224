/* eslint-disable max-len */
import * as React from 'react';

function CreditCart(props) {
  return (
    <svg width={52} height={52} viewBox="0 0 52 52" fill="none" {...props}>
      <path
        d="M42.86 26.058V9.293c0-2.52-2.05-4.57-4.57-4.57H4.57C2.05 4.723 0 6.773 0 9.293V31.84c0 2.52 2.05 4.57 4.57 4.57h26.104a10.54 10.54 0 00-.002.203c0 5.88 4.784 10.664 10.664 10.664S52 42.493 52 36.613c0-5.363-3.98-9.813-9.14-10.555zM4.57 7.77h33.72c.84 0 1.523.683 1.523 1.523v.914H3.047v-.914c0-.84.683-1.523 1.523-1.523zm-1.523 5.484h36.765V16.3H3.047v-3.047zm1.523 20.11c-.84 0-1.523-.684-1.523-1.524V19.348h36.765v6.71c-4.07.585-7.405 3.478-8.633 7.305H4.57zM41.336 44.23c-4.2 0-7.617-3.418-7.617-7.618s3.417-7.617 7.617-7.617 7.617 3.417 7.617 7.617-3.417 7.617-7.617 7.617z"
        fill="#3F4041"
      />
      <path
        d="M43.56 33.81l-2.58 2.579-.801-.802a1.524 1.524 0 00-2.155 2.154l1.88 1.88c.297.297.687.445 1.076.445.39 0 .78-.148 1.078-.446l3.656-3.656a1.523 1.523 0 10-2.154-2.154zM7.617 25.442h3.809a1.523 1.523 0 000-3.047H7.617a1.523 1.523 0 100 3.047zM16.25 27.27H7.617a1.523 1.523 0 000 3.046h8.633a1.523 1.523 0 000-3.046z"
        fill="#3F4041"
      />
    </svg>
  );
}

export default CreditCart;
