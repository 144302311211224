import { OUTLINE_COLOR } from 'constants/colors';
import React from 'react';

function Icon({ fill = OUTLINE_COLOR }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C7.1776 0 0 7.1776 0 16C0 24.8224 7.1776 32 16 32C24.8224 32 32 24.8224 32 16C32 7.1776 24.8224 0 16 0ZM8.24242 18.4242C6.9057 18.4242 5.81818 17.3367 5.81818 16C5.81818 14.6633 6.9057 13.5758 8.24242 13.5758C9.57915 13.5758 10.6667 14.6633 10.6667 16C10.6667 17.3367 9.57915 18.4242 8.24242 18.4242ZM16 18.4242C14.6633 18.4242 13.5758 17.3367 13.5758 16C13.5758 14.6633 14.6633 13.5758 16 13.5758C17.3367 13.5758 18.4242 14.6633 18.4242 16C18.4242 17.3367 17.3367 18.4242 16 18.4242ZM23.7576 18.4242C22.4208 18.4242 21.3333 17.3367 21.3333 16C21.3333 14.6633 22.4208 13.5758 23.7576 13.5758C25.0943 13.5758 26.1818 14.6633 26.1818 16C26.1818 17.3367 25.0943 18.4242 23.7576 18.4242Z"
        fill={fill}
      />
    </svg>
  );
}

export default Icon;
