export const GET_WRAPPED_ORDERS_SUCCESS = 'GET_WRAPPED_ORDERS_SUCCESS';

export const GET_SHIPMENT_ORDERS_SUCCESS = 'GET_SHIPMENT_ORDERS_SUCCESS';

export const GET_WRAPPED_ORDERS_TOTAL = 'GET_WRAPPED_ORDERS_TOTAL';

export const FETCHING_SHIPPING_ORDERS = 'FETCHING_SHIPPING_ORDERS';

export const GET_MANUAL_SHIPMENT_ORDERS_SUCCESS =
  'GET_MANUAL_SHIPMENT_ORDERS_SUCCESS';
