import * as React from 'react';

const MoneyBig = props => {
  return (
    <svg
      width="42"
      height="23"
      viewBox="0 0 42 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.0287 21.9898L3.46065 19.903C1.94286 19.8027 0.848891 18.9875 1.01706 18.0823L3.91857 2.46764C4.08683 1.56237 5.45358 0.909832 6.97129 1.01019L38.5393 3.09696C40.0571 3.19732 41.1512 4.0125 40.9829 4.91773L38.0814 20.5324C37.9132 21.4376 36.5465 22.0902 35.0287 21.9898Z"
        fill="#37B972"
      />
      <path
        d="M39.7248 19.3484L8.22188 22.9703C6.70725 23.1444 5.24837 22.5352 4.96343 21.6095L0.0486104 5.64299C-0.23633 4.71734 0.760551 3.82575 2.27518 3.65161L33.7781 0.0297079C35.2928 -0.144432 36.7516 0.464805 37.0366 1.39046L41.9514 17.357C42.2363 18.2827 41.2394 19.1743 39.7248 19.3484Z"
        fill="#239958"
      />
      <path
        d="M5 21L5.14626 21.5189C5.42415 22.5049 6.84697 23.1538 8.32415 22.9684L24 21H5Z"
        fill="#239F85"
      />
      <path
        d="M34.9183 21.1224H2.86288C1.32164 21.1224 0.0722656 20.3594 0.0722656 19.4182V3.18368C0.0722656 2.24247 1.32164 1.47949 2.86288 1.47949H34.9183C36.4596 1.47949 37.709 2.24247 37.709 3.18368V19.4182C37.709 20.3594 36.4596 21.1224 34.9183 21.1224Z"
        fill="#38BC73"
      />
      <path
        d="M33.3887 19.4732H4.44365C3.05202 19.4732 1.92383 18.8381 1.92383 18.0546V4.54067C1.92383 3.75721 3.05202 3.12207 4.44365 3.12207H33.3887C34.7804 3.12207 35.9086 3.75721 35.9086 4.54067V18.0546C35.9086 18.8381 34.7804 19.4732 33.3887 19.4732Z"
        fill="#5AC98B"
      />
      <circle cx="18.5" cy="11.5" r="4.5" fill="#37B972" />
    </svg>
  );
};

export default MoneyBig;
