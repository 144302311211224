import * as React from 'react';

const HeartBlank = ({ color }) => {
  return (
    <svg
      width="34"
      height="29"
      viewBox="0 0 34 29"
      fill={color || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.4614 3.6423C28.8049 1.93842 26.532 1 24.0608 1C22.2136 1 20.522 1.55384 19.0327 2.646C18.2813 3.19729 17.6003 3.87175 17 4.65898C16.3999 3.87199 15.7188 3.19729 14.967 2.646C13.478 1.55384 11.7864 1 9.93921 1C7.46802 1 5.19482 1.93842 3.53833 3.6423C1.90161 5.32627 1 7.62682 1 10.1205C1 12.6871 2.00854 15.0365 4.17383 17.5144C6.11084 19.7309 8.89478 21.981 12.1187 24.5864C13.2195 25.4762 14.4673 26.4848 15.7629 27.5592C16.1052 27.8435 16.5444 28 17 28C17.4553 28 17.8948 27.8435 18.2366 27.5596C19.5322 26.4851 20.7808 25.476 21.8821 24.5858C25.1055 21.9807 27.8894 19.7309 29.8264 17.5142C31.9917 15.0365 33 12.6871 33 10.1202C33 7.62682 32.0984 5.32627 30.4614 3.6423Z"
        stroke="#D80027"
        strokeWidth="2"
      />
    </svg>
  );
};

export default HeartBlank;
