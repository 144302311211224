/* eslint-disable max-len */
import * as React from 'react';

function Sale(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        d="M32.683 13.642c-.57-.223-1.286-.501-1.436-.768a3.726 3.726 0 01.04-1.7c.162-1.075.327-2.188-.376-2.888-.703-.7-1.833-.542-2.915-.371a3.706 3.706 0 01-1.672.046 3.705 3.705 0 01-.796-1.477c-.4-1.02-.814-2.076-1.78-2.34-.964-.262-1.849.432-2.697 1.114-.41.403-.907.706-1.454.885-.311 0-.907-.477-1.383-.86-.83-.658-1.767-1.407-2.759-1.141-.992.265-1.428 1.382-1.814 2.367-.224.571-.501 1.287-.767 1.437a3.771 3.771 0 01-1.702-.04c-1.082-.166-2.195-.325-2.891.376-.697.701-.543 1.834-.378 2.925.143.546.159 1.117.048 1.67-.423.371-.925.64-1.467.789-1.022.4-2.08.815-2.341 1.78-.261.964.432 1.847 1.115 2.697.404.41.707.91.886 1.458 0 .31-.477.908-.86 1.382-.66.826-1.408 1.764-1.143 2.755.266.992 1.383 1.43 2.369 1.814.57.224 1.287.501 1.436.768a3.726 3.726 0 01-.04 1.7c-.162 1.075-.327 2.188.376 2.889.704.7 1.834.541 2.916.37a3.706 3.706 0 011.671-.045c.375.424.647.93.796 1.476.4 1.02.815 2.076 1.78 2.34.965.262 1.839-.442 2.689-1.119a3.756 3.756 0 011.466-.882c.311 0 .907.477 1.383.86.826.66 1.763 1.408 2.755 1.142s1.428-1.382 1.814-2.367c.224-.572.501-1.287.768-1.437a3.694 3.694 0 011.701.04c1.074.16 2.188.323 2.887-.375.699-.699.543-1.834.38-2.92a3.723 3.723 0 01-.048-1.67 3.717 3.717 0 011.47-.793c1.022-.4 2.079-.815 2.34-1.78.262-.964-.442-1.838-1.118-2.69a3.725 3.725 0 01-.882-1.465c0-.312.476-.909.86-1.383.66-.827 1.41-1.765 1.144-2.755-.266-.991-1.385-1.43-2.37-1.814z"
        fill="#49DF88"
      />
      <path
        d="M29.807 20.95l-2.677.716a.693.693 0 01-.848-.49l-1.435-5.352c-.099-.37.12-.75.49-.849l2.677-.717a.693.693 0 01.358 1.338l-2.007.538 1.076 4.015 2.007-.538a.693.693 0 01.359 1.338z"
        fill="#FAFAFA"
      />
      <path
        d="M29.09 18.273l-2.677.717a.693.693 0 01-.358-1.338l2.677-.718a.693.693 0 11.358 1.339zM24.454 22.384l-2.677.717a.693.693 0 01-.848-.49l-1.434-5.353a.693.693 0 011.338-.359l1.255 4.684 2.008-.538a.693.693 0 11.358 1.339zM19.102 23.818a.693.693 0 01-.785-.333l-1.428-2.571.05 2.941a.693.693 0 11-1.386.025l-.097-5.712a.72.72 0 011.3-.348l2.773 4.994a.693.693 0 01-.427 1.004z"
        fill="#FAFAFA"
      />
      <path
        d="M18.074 22.659l-1.338.359a.693.693 0 01-.359-1.339l1.338-.358a.693.693 0 01.359 1.338zM12.41 25.61a2.078 2.078 0 01-2.51-1.335.693.693 0 01.435-.878h.001a.701.701 0 01.877.435.695.695 0 101.328-.408c-.08-.3-.266-.482-.94-.465l-.143.004c-1.571.025-2.123-.798-2.31-1.498a2.08 2.08 0 013.978-1.21.693.693 0 01-.434.878h-.002a.682.682 0 01-.876-.434.695.695 0 10-1.328.407c.08.3.272.48.943.464l.143-.002c1.57-.026 2.12.799 2.307 1.497a2.078 2.078 0 01-1.47 2.546z"
        fill="#FAFAFA"
      />
    </svg>
  );
}

export default Sale;
