import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#806BFF"
        d="M8 0a8 8 0 100 16A8 8 0 008 0zm1.665 12.398c-.412.163-.74.286-.985.372-.246.085-.53.128-.855.128-.499 0-.887-.122-1.163-.365a1.178 1.178 0 01-.414-.926c0-.146.01-.295.03-.447.022-.151.055-.322.1-.514l.516-1.82c.045-.175.084-.34.116-.495.03-.156.046-.299.046-.429 0-.231-.049-.394-.144-.485-.097-.092-.279-.137-.55-.137-.133 0-.27.02-.41.061-.14.043-.26.082-.359.12L5.73 6.9c.337-.138.66-.256.968-.353.308-.099.6-.148.874-.148.495 0 .877.12 1.146.36.267.238.402.55.402.931 0 .08-.01.219-.028.418-.018.2-.053.382-.103.55l-.513 1.814c-.042.146-.079.313-.113.499-.033.186-.05.328-.05.424 0 .24.054.405.163.493.107.087.294.131.56.131.125 0 .265-.022.424-.066.157-.043.27-.082.342-.115l-.137.56zm-.09-7.367c-.24.222-.528.333-.864.333-.336 0-.626-.11-.867-.333a1.064 1.064 0 01-.361-.808c0-.315.122-.585.36-.81.242-.225.532-.336.868-.336.336 0 .625.111.863.336.24.225.36.495.36.81 0 .317-.12.586-.36.808z"
      ></path>
    </svg>
  );
}

export default Icon;