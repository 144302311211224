/* eslint-disable max-len */
import * as React from 'react';

function BlogAuthor(props) {
  return (
    <svg
      width={14}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.6 17.4v-1.114c0-.974.395-1.91 1.101-2.602A3.822 3.822 0 0 1 4.375 12.6h5.25c1.005 0 1.967.391 2.674 1.084a3.642 3.642 0 0 1 1.101 2.602V17.4H.6ZM7 9.686a4.745 4.745 0 0 1-1.784-.348 4.659 4.659 0 0 1-1.508-.987 4.537 4.537 0 0 1-1.006-1.474 4.452 4.452 0 0 1 0-3.469 4.537 4.537 0 0 1 1.006-1.473A4.659 4.659 0 0 1 5.216.947 4.745 4.745 0 0 1 7 .6c1.237 0 2.421.482 3.292 1.335a4.49 4.49 0 0 1 1.358 3.208 4.49 4.49 0 0 1-1.358 3.208A4.706 4.706 0 0 1 7 9.686Z"
        stroke="#C7C7C7"
        strokeWidth={1.2}
      />
    </svg>
  );
}

export default BlogAuthor;
