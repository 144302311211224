export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';

export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';

export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING';

export const SET_ORDER_EXPANDED = 'SET_ORDER_EXPANDED';

export const SET_ORDERS_FILTER = 'SET_ORDERS_FILTER';

export const RESET_ORDERS_FILTER = 'RESET_ORDERS_FILTER';
