import * as React from 'react';

function Offer(props) {
  return (
    <svg width={35} height={35} viewBox="0 0 35 35" fill="none" {...props}>
      <path
        d="M28.436 0H6.562a5.476 5.476 0 00-5.468 5.468v6.563c0 .603.49 1.093 1.093
        1.093h7.656V33.904c0 .07.026.131.04.197.013.07.01.14.039.21
        0 .002.004.004.004.007.075.183.206.328.361.444.037.028.07.05.11.072.162.094.339.164.54.164.136
        0 .273-.024.407-.079l.02-.009h.002l5.04-2.014 5.06 2.025c.261.105.552.105.812 0l5.064-2.025 5.062
        2.025c.336.136.72.092 1.019-.11.3-.205.481-.544.481-.907V5.468A5.476 5.476 0 0028.436 0zM9.843
        10.937H3.281V5.468a3.285 3.285 0 013.281-3.28 3.285 3.285 0 013.281
        3.28v5.469zM31.717 32.29l-3.968-1.588a1.085 1.085
        0 00-.811 0l-5.064 2.023-5.062-2.025a1.086 1.086 0 00-.811 0l-3.97
        1.588V5.468a5.405 5.405 0 00-1.123-3.28h17.528a3.285 3.285 0 013.28 3.28V32.29z"
        fill="#fff"
      />
      <path
        d="M28.437 6.562H15.312a1.094 1.094 0 000 2.187h13.125a1.094 1.094 0 000-2.187zM28.437
            15.312H15.312a1.094 1.094 0 000 2.187h13.125a1.094 1.094 0 000-2.187zM28.437
            19.686h-8.75a1.094 1.094 0 000 2.188h8.75a1.094 1.094 0 000-2.188zM28.437
            24.061h-8.75a1.094 1.094 0 000 2.187h8.75a1.094 1.094 0 000-2.187z"
        fill="#fff"
      />
    </svg>
  );
}

export default Offer;
