/* eslint-disable max-len */
import * as React from 'react';

export default function CreditCartColorDisabled(props) {
  return (
    <svg
      width={props.param}
      height={props.param}
      viewBox="0 0 50 50"
      fill="none"
      {...props}
    >
      <g opacity={0.4} clipPath="url(#prefix__clip0)">
        <path
          d="M47.025 33.89l2.931-18.716a3.484 3.484 0 00-2.887-3.992L7.517 4.834a3.483 3.483 0 00-3.99 2.886L2.12 16.473V33.78l44.904.108z"
          fill="#146AA8"
        />
        <path
          d="M47.067 11.181L35.833 9.38C28.29 20.923 16.092 28.919 6.738 33.792l40.285.097 2.93-18.716a3.484 3.484 0 00-2.886-3.992z"
          fill="#126099"
        />
        <path
          d="M3.483 15.602h40.058a3.483 3.483 0 013.483 3.484v22.641a3.483 3.483 0 01-3.483 3.484H3.483A3.484 3.484 0 010 41.727V19.086a3.483 3.483 0 013.483-3.483z"
          fill="#4398D1"
        />
        <path
          d="M43.541 15.602h-.696C34.625 30.197 19.264 40.209 9.887 45.21H43.54a3.483 3.483 0 003.484-3.483V19.086a3.483 3.483 0 00-3.484-3.483z"
          fill="#3E8CC7"
        />
        <path d="M13.06 19.957H4.352v6.967h8.708v-6.967z" fill="#FDB62F" />
        <path
          d="M6.964 22.57H4.352v1.74h2.612v-1.74zM13.062 22.57h-2.613v1.74h2.613v-1.74z"
          fill="#FD7B2F"
        />
        <path
          d="M38.315 40.857a4.354 4.354 0 100-8.709 4.354 4.354 0 000 8.709z"
          fill="#FDB62F"
        />
        <path
          d="M33.089 40.857a4.354 4.354 0 100-8.709 4.354 4.354 0 000 8.709z"
          fill="#DE4C3C"
        />
        <path
          d="M8.705 33.02H3.48v1.741h5.225V33.02zM17.412 33.02h-5.224v1.741h5.224V33.02zM26.123 33.02h-5.225v1.741h5.225V33.02zM26.122 38.244H3.48v1.742h22.642v-1.742zM32.218 19.957h-1.741v3.483h1.741v-3.483zM35.699 19.957h-1.742v3.483h1.742v-3.483zM39.183 19.957h-1.742v3.483h1.742v-3.483zM42.667 19.957h-1.741v3.483h1.741v-3.483z"
          fill="#87CED9"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h50v50H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
