import React from 'react';

const SofortIconBw = function(properties) {
  const { param: parameter } = properties;

  return (
    <svg height={parameter} viewBox="0 0 2219.89 402.5" width={parameter}>
      <defs>
        <clipPath id="prefix__aSofortIconBw">
          <path d="M1830 6h389.89v391H1830zm0 0" />
        </clipPath>
        <clipPath id="prefix__bSofortIconBw">
          <path d="M476 0h376v402.5H476zm0 0" />
        </clipPath>
        <clipPath id="prefix__cSofortIconBw">
          <path d="M1122 0h377v402.5h-377zm0 0" />
        </clipPath>
        <clipPath id="prefix__dSofortIconBw">
          <path d="M1830 5h389.89v391H1830zm0 0" />
        </clipPath>
        <clipPath id="prefix__eSofortIconBw">
          <path d="M476 0h376v402.5H476zm0 0" />
        </clipPath>
        <clipPath id="prefix__fSofortIconBw">
          <path d="M1122 0h377v402.5h-377zm0 0" />
        </clipPath>
      </defs>
      <path
        d="M613.656 6.016H390.027c-35.261 0-69.53 0-100.199 15.566-30.691 15.59-57.781 46.77-78.656 109.082-4.488 13.39-6.887 24.961-7.219 34.953-.312 10.082 1.445 18.633 5.242 26.051 4.707 9.039 12.301 17.59 21.828 24.762 9.504 7.195 20.918 13.035 33.18 16.789.465.11 6.926 1.976 14.742 4.195 7.797 2.242 16.922 4.93 22.676 6.664 3.375 1.043 7.082 2.375 10.461 4.395 3.375 2.023 6.395 4.687 8.305 8.394 1.242 2.488 1.91 5.176 1.976 8.063.09 2.91-.398 6.043-1.554 9.347-2.844 8.461-7.196 13.926-15.457 17.258-8.239 3.328-20.407 4.399-38.84 4.527H36.398L0 396.02h246.324c23.184 0 65.559 0 106.309-17.297 40.726-17.301 79.77-51.903 96.312-121.078 6.22-26.004 4.309-47.211-5.882-63.844-10.239-16.637-28.715-28.692-55.633-36.422 0 0-6.016-1.754-13.125-3.774-7.04-2.042-15.188-4.375-19.364-5.574-6.882-1.957-12.191-5.953-15.324-11.015-3.11-5.063-4.039-11.239-2.265-17.524 1.843-6.527 6.308-11.746 12.77-15.344 6.483-3.574 14.991-5.55 24.917-5.55h143.172c1.02-1.446 1.953-3.02 2.953-4.508 26.137-36.977 57.805-66.934 92.492-88.074"
        fill="#8b8b8b"
      />
      <path
        d="M613.781 5.578H390.152c-35.265 0-69.511 0-100.199 15.567-30.691 15.59-57.762 46.75-78.64 109.085-4.504 13.391-6.907 24.938-7.215 34.954-.313 10.058 1.441 18.609 5.242 26.027 4.683 9.059 12.277 17.61 21.805 24.781 9.527 7.176 20.921 13.035 33.203 16.77.464.133 6.925 1.976 14.746 4.218 7.77 2.243 16.898 4.907 22.672 6.641 3.351 1.063 7.086 2.399 10.46 4.395 3.352 2.023 6.372 4.707 8.282 8.418 1.246 2.464 1.91 5.152 1.976 8.062.09 2.906-.398 6.04-1.554 9.324-2.84 8.485-7.172 13.926-15.457 17.278-8.239 3.332-20.387 4.398-38.817 4.53H36.52L.145 395.587h246.324c23.183 0 65.554 0 106.285-17.3 40.726-17.302 79.77-51.9 96.312-121.099 6.243-25.98 4.309-47.19-5.882-63.824-10.239-16.652-28.715-28.715-55.61-36.418 0 0-6.039-1.754-13.125-3.797-7.039-2.023-15.21-4.355-19.387-5.554-6.882-1.953-12.167-5.95-15.324-11.035-3.086-5.063-4.039-11.215-2.261-17.5 1.863-6.528 6.304-11.746 12.77-15.344 6.483-3.578 14.987-5.555 24.913-5.555h143.195c1.02-1.465 1.93-3.043 2.954-4.508 26.136-36.996 57.804-66.933 92.472-88.074"
        fill="#8b8b8b"
      />
      <g clipPath="url(#prefix__aSofortIconBw)">
        <path
          d="M1831.16 6.242c7.66 6.04 14.211 12.946 19.7 20.809 14.03 20.23 19.41 44.261 16.19 71.773h71.821L1830.63 396.336h108.152l108.297-297.512h138.961l33.73-92.582H1831.16"
          fill="#c7c7c7"
        />
      </g>
      <path
        d="M1720.8 130.648c-11.59 31.204-32.378 50.477-71.929 50.477h-.18l-20.453-.043 35-94.316h19.903c36.398 0 49.988 10.66 37.66 43.882m104.98-86.164C1807.97 18.88 1771.77 6.31 1715.051 6.242h-131.313l-142 390.008h120.614l51.66-137.266c2.476 5.641 32.949 137.266 32.949 137.266h126.828s-23.07-96.715-40.039-135.777c-4.352-10.059-8.91-21.32-14.988-30.711 35.816-13.305 87.738-41.907 109.218-99.801 13.23-35.598 12.45-64.36-2.199-85.477"
        fill="#c7c7c7"
      />
      <g clipPath="url(#prefix__bSofortIconBw)">
        <path
          d="M718.7 199.215c-33.423 91.492-57.696 114.523-94.204 114.523h-.066c-35.555-.047-48.035-22.543-14.77-113.593 24.871-67.973 57.05-111.434 96.024-111.434h.023c37.02.043 45.504 21.496 12.992 110.504M833.38 42.539C813.902 14.672 780.523.504 734.133.457c-30.336 0-59.828 6.973-87.43 20.008-39.531 18.68-75.328 49.902-104.574 91.273-19.012 26.895-35.266 58.028-48.012 92.871-23.984 65.711-23.719 119.078.844 154.207 13.898 19.903 42.812 43.641 101.64 43.684h.223c107.153 0 187.145-70.242 237.797-208.727C847.992 157.11 865.645 88.73 833.38 42.54"
          fill="#8b8b8b"
        />
      </g>
      <path
        d="M1261.55 6.016h-227.91c-63.718 0-111.152 32.242-133.538 92.582L791.844 396.02h118.562l55.63-152.899 104.816.09 29.808-81.902-104.469-.067 16.809-47.41c3.54-8.484 11.89-15.234 24.172-15.234 6.84 0 83.738 0 123.578.02 1.02-1.466 1.93-3.04 2.95-4.528 26.14-36.977 63.16-66.934 97.85-88.074"
        fill="#c7c7c7"
      />
      <g clipPath="url(#prefix__cSofortIconBw)">
        <path
          d="M1365.21 199.215c-33.42 91.492-57.69 114.523-94.179 114.523h-.09c-35.582-.047-47.992-22.543-14.722-113.593 24.832-67.973 57.05-111.434 95.96-111.434h.06c37.023.043 45.511 21.496 12.972 110.504m114.7-156.676C1460.42 14.672 1427.077.504 1380.62.457c-30.312 0-59.781 6.973-87.402 20.008-39.508 18.68-75.309 49.902-104.559 91.273-19.012 26.895-35.262 58.028-47.988 92.871-24.05 65.711-23.742 119.078.816 154.207 13.91 19.903 42.82 43.641 101.672 43.684h.2c107.128 0 187.14-70.242 237.75-208.727 13.421-36.664 31.05-105.043-1.2-151.234"
          fill="#c7c7c7"
        />
      </g>
      <g clipPath="url(#prefix__dSofortIconBw)">
        <path
          d="M1831.3 5.809c7.641 6.039 14.22 12.921 19.7 20.808 14.04 20.207 19.39 44.258 16.191 71.774h71.82L1830.77 395.902h108.152l108.277-297.511h138.98l33.712-92.582H1831.3"
          fill="#8b8b8b"
        />
      </g>
      <path
        d="M1720.922 130.215c-11.57 31.176-32.383 50.453-71.934 50.453h-.16l-20.45-.043 34.981-94.293h19.922c36.39 0 49.989 10.637 37.64 43.883m104.97-86.168c-17.79-25.606-53.98-38.172-110.7-38.238H1583.88l-142.02 390.003h120.63l51.632-137.261c2.508 5.64 32.957 137.262 32.957 137.262h126.852s-23.102-96.711-40.04-135.797c-4.359-10.04-8.91-21.297-14.992-30.711 35.82-13.305 87.723-41.887 109.211-99.782 13.22-35.597 12.442-64.355-2.218-85.476"
        fill="#8b8b8b"
      />
      <g clipPath="url(#prefix__eSofortIconBw)">
        <path
          d="M718.824 198.777c-33.422 91.496-57.695 114.504-94.207 114.504h-.066c-35.551-.023-48.031-22.543-14.742-113.594 24.847-67.976 57.027-111.433 96-111.433h.023c37.043.043 45.523 21.516 12.992 110.523m114.7-156.672C814.026 14.235 780.671.066 734.257 0c-30.36 0-59.828 6.996-87.43 20.008-39.531 18.699-75.328 49.902-104.574 91.297-18.988 26.87-35.266 58.027-47.992 92.847-24.008 65.735-23.739 119.098.82 154.23 13.926 19.9 42.84 43.618 101.645 43.684h.222c107.149 0 187.14-70.242 237.797-208.75 13.367-36.664 31.047-105.043-1.223-151.21"
          fill="#8b8b8b"
        />
      </g>
      <path
        d="M1261.691 5.578h-227.91c-63.742 0-111.176 32.246-133.562 92.582L791.96 395.586h118.586l55.629-152.899 104.793.067 29.8-81.879-104.457-.066 16.829-47.414c3.507-8.504 11.879-15.254 24.14-15.254 6.84 0 83.77.02 123.61.02 1-1.442 1.91-3.044 2.93-4.509 26.16-36.996 63.179-66.933 97.87-88.074"
        fill="#8b8b8b"
      />
      <g clipPath="url(#prefix__fSofortIconBw)">
        <path
          d="M1365.34 198.777c-33.418 91.496-57.668 114.504-94.16 114.504h-.09c-35.602-.023-48.012-22.543-14.77-113.594 24.852-67.976 57.051-111.433 95.98-111.433h.071c37.02.043 45.5 21.516 12.969 110.523m114.7-156.672C1460.54 14.235 1427.21.066 1380.77 0c-30.329 0-59.801 6.996-87.41 20.008-39.532 18.699-75.32 49.902-104.57 91.297-19.009 26.87-35.24 58.027-47.99 92.847-24.03 65.735-23.741 119.098.84 154.23 13.88 19.9 42.801 43.618 101.669 43.684h.18c107.152 0 187.16-70.242 237.75-208.75 13.433-36.664 31.07-105.043-1.2-151.21"
          fill="#8b8b8b"
        />
      </g>
    </svg>
  );
};

export default SofortIconBw;
