import * as React from "react"

function Pickup(props) {
  return (
    <svg
      width={34}
      height={30}
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.033 23.05V0H5.942l-.014 15.883H0V30h7.984v-1.518h23.03a2.72 2.72 0 002.717-2.717 2.72 2.72 0 00-2.698-2.716zM5.92 19.543v8.393H2.063v-9.99h3.858v1.598zm11.318-17.48h1.891V4.8h-1.89V2.063zm-9.236 0h7.173v4.8h6.017v-4.8h7.777v20.985H18.36a.435.435 0 010-.869h3.693a2.842 2.842 0 002.839-2.838 2.855 2.855 0 00-2.426-2.808l-3.212-.472c-3.016-.444-4.927-.012-7.398.94l-3.867 1.657.014-16.596zm23.012 24.355H7.984v-2.862l.003-2.653 4.628-1.984c2.522-.97 4.006-1.16 6.339-.817l3.212.472a.776.776 0 01-.113 1.542H18.36a2.5 2.5 0 00-2.497 2.497 2.5 2.5 0 002.497 2.498h12.655a.654.654 0 010 1.307z"
        fill="#3F4041"
      />
    </svg>
  )
}

export default Pickup
