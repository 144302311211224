import Icons from 'components/icons/index';
import React from 'react';

export const plansMonthly = [
  {
    name: 'Test',
    label: 'Test',
    id: 'ff7b3bf0-2a82-4437-9672-64fc661ea1a7',
    currency: '€',
    fees: 15,
    price: '0.00',
    extra: ' ',
    period: 'month',
    periodLabel: 'monthly',
    features: [...Array(3).fill(true), ...Array(5).fill(false)],
    factor: 0.15,
  },
  {
    name: 'Basic',
    label: 'Basic',
    id: '685bef4b-d93b-4801-8a9f-04a4984a3d24',
    trialId: 'f72492c8-2b4a-481f-906a-5fac9ecbd982',
    trialName: 'Basic Trial',
    trialLabel: 'free-trial',
    trialPeriod: '14 days free',
    trialPrice: '0.00',
    currency: '€',
    fees: 7.5,
    price: '2.95',
    extra: '*',
    period: 'month',
    periodLabel: 'monthly',
    features: [...Array(4).fill(true), ...Array(2).fill(false)],
    factor: 0.075,
  },
  {
    name: 'All-In-One',
    label: 'All-In-One',
    id: '0ed4a905-d413-4f61-bc92-f23d13ed0237',
    trialId: '42bf74bf-997e-488d-8091-62db6ed14840',
    trialName: 'All-In-One Trial',
    trialLabel: 'free-trial',
    trialPeriod: '14 days free',
    trialPrice: '0.00',
    currency: '€',
    fees: 5,
    price: '6.95',
    extra: '*',
    period: 'month',
    periodLabel: 'monthly',
    features: Array(6).fill(true),
    factor: 0.05,
  },
];

export const plansYearly = [
  {
    name: 'Test',
    label: 'Test',
    id: 'ff7b3bf0-2a82-4437-9672-64fc661ea1a7',
    currency: '€',
    fees: 15,
    price: '0.00',
    period: 'year',
    periodLabel: 'yearly',
    features: [...Array(3).fill(true), ...Array(5).fill(false)],
    factor: 0.15,
  },
  {
    name: 'Basic Yearly',
    label: 'Basic',
    id: 'aad54243-1300-4a88-ac31-4c81680a43f4',
    currency: '€',
    fees: 7.5,
    price: '29.95',
    extra: '**',
    period: 'year',
    periodLabel: 'yearly',
    sale: '35.40',
    saleIcon: () => <Icons.Sale15 />,
    features: [...Array(4).fill(true), ...Array(2).fill(false)],
    factor: 0.075,
  },
  {
    name: 'All-In-One Yearly',
    label: 'All-In-One',
    id: '87e96f81-e07c-4579-ba8e-d030fd9cc1a2',
    currency: '€',
    fees: 5,
    price: '74.95',
    extra: '**',
    period: 'year',
    periodLabel: 'yearly',
    sale: '83.40',
    saleIcon: () => <Icons.Sale10 />,
    features: Array(6).fill(true),
    factor: 0.05,
  },
];

export const features = [
  // 'Offer up to 3 Paintings for sale',
  'Shipping & Payment Management',
  'Upload your Vita & show your workplace',
  'Show and sell more than 3 Paintings',
  'Earn 50% of Draint fees when your painting resells',
  'Personal Blog Page Featured articles',
  'Mailbox to communicate with your clients & automated newsletters',
];
