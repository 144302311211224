export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';

export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';

export const GET_ALL_SELECTED_DATA_SUCCESS = 'GET_ALL_SELECTED_DATA_SUCCESS';

export const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS';

export const SET_SEARCH_RESULTS_LOADING = 'SET_SEARCH_RESULTS_LOADING';

export const SET_SEARCH_RESULTS_COUNT_DATA = 'SET_SEARCH_RESULTS_COUNT_DATA';

export const SET_ARTWORKS_SEARCH_QUERY = 'SET_ARTWORKS_SEARCH_QUERY';

export const RESET_SEARCH = 'RESET_SEARCH';

export const SET_MAP_IS_OPEN = 'SET_MAP_IS_OPEN';

export const SET_SEARCH_RESULTS_PROVED_DATA = 'SET_SEARCH_RESULTS_PROVED_DATA';

export const SEARCH_SET_TOTAL_COUNT_DATA = 'SEARCH_SET_TOTAL_COUNT_DATA';
