export const NEWSLETTER_ROOT = '/newsletter';

export const SUBSCRIPTION = '/subscription';

export const CREATE = '/create';

export const AUTOMATED = '/automated';

export const WEEKLY = '/weekly';

export const STATS = '/stats';
