import * as React from 'react';

const MoneyMiddle = props => {
  return (
    <svg
      width="41"
      height="22"
      viewBox="0 0 41 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.8594 18.5072L7.8358 21.9716C6.39229 22.1382 5.00192 21.5554 4.73036 20.67L0.0463278 5.39765C-0.225233 4.51224 0.724837 3.65941 2.16835 3.49285L32.192 0.0284163C33.6355 -0.138152 35.0259 0.444596 35.2974 1.33001L39.9814 16.6024C40.253 17.4878 39.3029 18.3406 37.8594 18.5072Z"
        fill="#239958"
      />
      <path
        d="M4.58325 20.1667L4.72673 20.6424C4.99934 21.5462 6.39512 22.1411 7.84423 21.9711L23.2221 20.1667H4.58325Z"
        fill="#38BC73"
      />
      <path
        d="M33.2794 20.21H2.72915C1.26029 20.21 0.0695801 19.4829 0.0695801 18.5859V3.11367C0.0695801 2.21665 1.26029 1.4895 2.72915 1.4895H33.2794C34.7482 1.4895 35.9389 2.21665 35.9389 3.11367V18.5859C35.9389 19.4829 34.7482 20.21 33.2794 20.21Z"
        fill="#38BC73"
      />
      <path
        d="M31.8199 18.6395H4.23401C2.90773 18.6395 1.83252 18.0342 1.83252 17.2875V4.40813C1.83252 3.66147 2.90773 3.05615 4.23401 3.05615H31.8199C33.1462 3.05615 34.2214 3.66147 34.2214 4.40813V17.2875C34.2214 18.0342 33.1462 18.6395 31.8199 18.6395Z"
        fill="#5AC98B"
      />
      <circle cx="18.0278" cy="11.0004" r="4.27778" fill="#37B972" />
    </svg>
  );
};

export default MoneyMiddle;
