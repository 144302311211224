/* eslint-disable max-len */
import * as React from 'react';

function Trade(props) {
  return (
    <svg height={512} viewBox="0 0 512 512" width={512} {...props}>
      <path d="M316 103c10.997 0 21.729.919 32.309 2.247C312.547 67.371 257.613 43 196 43 88.48 43 0 117.019 0 208c0 35.083 14.345 69.492 38.705 97.617l-11.177 74.355 73.473-24.487C94.516 337.304 91 318.002 91 298c0-107.52 100.928-195 225-195zM211.293 300.959C212.67 307.814 218.734 313 226 313s13.33-5.186 14.707-12.041L226 271.545zM391.293 300.959C392.67 307.814 398.734 313 406 313s13.33-5.186 14.707-12.041L406 271.545z" />
      <path d="M316 133c-107.52 0-195 74.019-195 165s87.48 165 195 165c31.685 0 62.944-6.519 90.806-18.911L484.472 469l-11.177-73.383C497.655 367.492 512 333.083 512 298c0-90.981-88.48-165-196-165zm135 165c0 24.814-20.186 45-45 45s-45-20.186-45-45v-3.545L381.728 253H331v120h30v30h-90v-30h30V253h-50.728L271 294.455V298c0 24.814-20.186 45-45 45s-45-20.186-45-45v-3.545L216.728 223H301v-30h30v30h84.272L451 294.455z" />
    </svg>
  );
}

export default Trade;
