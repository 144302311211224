import * as React from "react"

function DashboardFeedback(props) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.875 7.875A1.878 1.878 0 000 9.75v6c0 1.034.842 1.875 1.875 1.875h2.25c.422 0 .81-.142 1.125-.38v-9.37H1.875zM18 10.688c0-.451-.178-.871-.488-1.181.35-.383.529-.897.48-1.432-.088-.953-.947-1.7-1.956-1.7h-4.633c.23-.697.597-1.974.597-3 0-1.627-1.382-3-2.25-3-.78 0-1.336.439-1.36.457a.377.377 0 00-.14.293v2.543l-2.16 4.68-.09.045v8.039c.61.288 1.383.443 1.875.443h6.884c.817 0 1.532-.55 1.7-1.31.086-.391.036-.785-.136-1.127a1.68 1.68 0 00.75-2.25c.555-.28.927-.85.927-1.5z"
        fill="#3F4041"
      />
    </svg>
  )
}

export default DashboardFeedback
