/* eslint-disable max-len */
import * as React from 'react';

export function Emoji1(props) {
  const fill1 = props.active ? '#E8354D' : '#D9D9D9';
  const fill2 = props.active ? '#701A25' : '#8B8B8B';
  return (
    <svg width={73} height={72} viewBox="0 0 73 72" fill="none" {...props}>
      <path
        d="M36.061 72.071c19.916 0 36.062-16.134 36.062-36.035C72.123 16.133 55.977 0 36.06 0 16.145 0 0 16.134 0 36.035c0 19.902 16.145 36.036 36.061 36.036z"
        fill={fill1}
      />
      <path
        d="M13.8 31.913a2.776 2.776 0 002.775 2.772h.853a2.776 2.776 0 002.774-2.772 2.776 2.776 0 00-2.774-2.772h-.853c-1.565-.07-2.774 1.209-2.774 2.772z"
        fill={fill2}
      />
      <path
        d="M14.153 25.73c-.498.924-.427 1.918.143 2.274l6.259 3.483c.569.355 1.493-.142 1.991-1.138.498-.924.427-1.919-.142-2.274l-6.26-3.483c-.64-.284-1.493.213-1.99 1.138zM57.472 25.73c.498.924.427 1.918-.142 2.274l-6.26 3.483c-.568.284-1.493-.214-1.991-1.138-.498-.924-.427-1.919.142-2.274l6.26-3.483c.569-.284 1.493.213 1.991 1.138z"
        fill={fill2}
      />
      <path
        d="M51.781 31.913a2.776 2.776 0 002.774 2.772h.925a2.776 2.776 0 002.774-2.772 2.776 2.776 0 00-2.774-2.772h-.854c-1.564-.07-2.845 1.208-2.845 2.772zM46.73 49.895a1.797 1.797 0 01-1.636-1.066c-.071-.213-2.418-5.473-9.033-5.473-5.761 0-9.104 5.686-9.175 5.686a1.755 1.755 0 01-2.419.64 1.753 1.753 0 01-.64-2.416c.213-.285 4.41-7.463 12.234-7.463 9.033 0 12.163 7.392 12.305 7.676.355.924-.071 1.919-.996 2.345-.213.071-.427.071-.64.071z"
        fill={fill2}
      />
    </svg>
  );
}

export function Emoji2(props) {
  const fill1 = props.active ? '#FC7E1E' : '#D9D9D9';
  const fill2 = props.active ? '#63320A' : '#8B8B8B';
  return (
    <svg width={73} height={72} viewBox="0 0 73 72" fill="none" {...props}>
      <path
        d="M36.124 72.071c19.916 0 36.061-16.134 36.061-36.035C72.185 16.133 56.04 0 36.124 0S.062 16.134.062 36.035c0 19.902 16.146 36.036 36.062 36.036z"
        fill={fill1}
      />
      <path
        d="M14.43 31.913a2.776 2.776 0 002.774 2.772h.853a2.776 2.776 0 002.774-2.772 2.776 2.776 0 00-2.774-2.772h-.853c-1.565-.07-2.774 1.208-2.774 2.772zM52.41 31.913a2.776 2.776 0 002.774 2.772h.854a2.776 2.776 0 002.774-2.772 2.776 2.776 0 00-2.774-2.772h-.854c-1.494-.07-2.774 1.209-2.774 2.772zM46.792 49.895a1.797 1.797 0 01-1.636-1.066c-.07-.213-2.418-5.473-9.033-5.473-5.76 0-9.104 5.686-9.175 5.686a1.755 1.755 0 01-2.418.64 1.753 1.753 0 01-.64-2.416c.142-.285 4.41-7.463 12.233-7.463 9.034 0 12.163 7.392 12.305 7.676.356.924-.07 1.919-.995 2.345-.214.071-.427.071-.64.071z"
        fill={fill2}
      />
    </svg>
  );
}
export function Emoji3(props) {
  const fill1 = props.active ? '#F9BC12' : '#D9D9D9';
  const fill2 = props.active ? '#7C4622' : '#8B8B8B';
  return (
    <svg width={73} height={72} viewBox="0 0 73 72" fill="none" {...props}>
      <path
        d="M36.186 72.071c19.916 0 36.062-16.134 36.062-36.035C72.248 16.133 56.102 0 36.186 0 16.27 0 .125 16.134.125 36.035c0 19.902 16.145 36.036 36.061 36.036z"
        fill={fill1}
      />
      <path
        d="M13.926 31.7a2.776 2.776 0 002.774 2.772h.853a2.776 2.776 0 002.774-2.772 2.776 2.776 0 00-2.774-2.772H16.7a2.776 2.776 0 00-2.774 2.772zM51.977 31.7a2.776 2.776 0 002.774 2.772h.853a2.776 2.776 0 002.774-2.772 2.776 2.776 0 00-2.774-2.772h-.853c-1.565 0-2.774 1.208-2.774 2.772zM41.023 48.403c-.213 0-.427-.071-.64-.142l-13.088-4.834c-.924-.355-1.422-1.35-1.066-2.274.355-.924 1.351-1.422 2.276-1.066l13.087 4.833c.925.355 1.423 1.35 1.067 2.274-.285.782-.925 1.209-1.636 1.209z"
        fill={fill2}
      />
    </svg>
  );
}

export function Emoji4(props) {
  const fill1 = props.active ? '#ACD91A' : '#D9D9D9';
  const fill2 = props.active ? '#495C0B' : '#8B8B8B';
  return (
    <svg width={73} height={72} viewBox="0 0 73 72" fill="none" {...props}>
      <path
        d="M36.249 72.071c19.916 0 36.061-16.134 36.061-36.035C72.31 16.133 56.165 0 36.25 0S.187 16.134.187 36.035c0 19.902 16.146 36.036 36.062 36.036z"
        fill={fill1}
      />
      <path
        d="M13.988 31.913a2.776 2.776 0 002.774 2.772h.854a2.776 2.776 0 002.774-2.772 2.776 2.776 0 00-2.774-2.772h-.854c-1.493-.07-2.774 1.209-2.774 2.772zM52.04 31.913a2.776 2.776 0 002.773 2.772h.853a2.776 2.776 0 002.775-2.772 2.776 2.776 0 00-2.775-2.772h-.853c-1.494-.07-2.774 1.208-2.774 2.772zM36.319 51.743c-7.824 0-12.02-7.178-12.234-7.463a1.753 1.753 0 01.64-2.416 1.755 1.755 0 012.418.64c.071.142 3.414 5.686 9.105 5.686 6.614 0 8.962-5.26 9.033-5.473.355-.924 1.422-1.35 2.347-.924.925.355 1.351 1.421.925 2.345-.072.213-3.272 7.605-12.234 7.605z"
        fill={fill2}
      />
    </svg>
  );
}

export function Emoji5(props) {
  const fill1 = props.active ? '#4ECC58' : '#D9D9D9';
  const fill2 = props.active ? '#1F5223' : '#8B8B8B';
  return (
    <svg width={73} height={72} viewBox="0 0 73 72" fill="none" {...props}>
      <path
        d="M36.315 72.071c19.916 0 36.062-16.134 36.062-36.035C72.377 16.133 56.232 0 36.315 0 16.4 0 .254 16.134.254 36.035c0 19.902 16.145 36.036 36.061 36.036z"
        fill={fill1}
      />
      <path
        d="M14.055 31.913a2.776 2.776 0 002.774 2.772h.853a2.776 2.776 0 002.774-2.772 2.776 2.776 0 00-2.774-2.772h-.853c-1.494-.07-2.774 1.209-2.774 2.772zM52.105 31.913a2.776 2.776 0 002.774 2.772h.854a2.776 2.776 0 002.774-2.772 2.776 2.776 0 00-2.774-2.772h-.854c-1.493-.07-2.773 1.208-2.773 2.772zM22.66 40.442s4.908 11.373 13.728 11.444c10.242 0 13.727-11.444 13.727-11.444H22.66z"
        fill={fill2}
      />
    </svg>
  );
}
