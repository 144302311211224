export const SET_PLANS_FROM_QUERY = 'SET_PLANS_FROM_QUERY';

export const OPEN_SUBSCRIPTION_MODAL = 'OPEN_SUBSCRIPTION_MODAL';

export const CLOSE_SUBSCRIPTION_MODAL = 'CLOSE_SUBSCRIPTION_MODAL';

export const CHANGE_PAYMENT_SYSTEM = 'CHANGE_PAYMENT_SYSTEM';

export const CHANGE_USER_COUNTRY = 'CHANGE_USER_COUNTRY';

export const CHANGE_SOFORT_COUNTRY_CODE = 'CHANGE_SOFORT_COUNTRY_CODE';

export const SET_LOADING_TRUE = 'SET_LOADING_TRUE';

export const SET_LOADING_FALSE = 'SET_LOADING_FALSE';

export const GET_PAYPAL_EMAIL = 'GET_PAYPAL_EMAIL';

export const GET_CHECKED_PLAN = 'GET_CHECKED_PLAN_ID';

export const SET_PLAN_PERIOD = 'SET_PLAN_PERIOD';

export const HANDLE_SET_CHECKED_PLAN = 'HANDLE_SET_CHECKED_PLAN';

export const SET_SUBSCRIPTION_MODAL = 'SET_SUBSCRIPTION_MODAL';

export const SET_SUBSCRIPTION_MODAL_TEXT = 'SET_SUBSCRIPTION_MODAL_TEXT';

export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';

export const SET_SELECTED_PLAN_ERROR = 'SET_SELECTED_PLAN_ERROR';

export const SET_LOADING_STATE = 'SET_LOADING_STATE';

export const SET_TRIAL = 'SET_TRIAL';

export const SET_TRIAL_MONTH_BASIC = 'SET_TRIAL_MONTH_BASIC';

export const SET_TRIAL_MONTH_ALL_IN = 'SET_TRIAL_MONTH_ALL_IN';

export const OPEN_PLAN_INFO_MODAL = 'OPEN_PLAN_INFO_MODAL';

export const CLOSE_PLAN_INFO_MODAL = 'CLOSE_PLAN_INFO_MODAL';
