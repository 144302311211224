/* eslint-disable max-len */
import * as React from 'react';

export default function Amex(props) {
  return (
    <svg
      width={props.param}
      height={props.param}
      viewBox="0 0 156 128"
      {...props}
    >
      <defs>
        <path
          d="M13.9 29.3c0-3.3 2.7-6 6-6h116c3.3 0 6 2.7 6 6v68c0 3.3-2.7 6-6 6h-116c-3.3 0-6-2.7-6-6v-68z"
          id="prefix__a"
        />
      </defs>
      <clipPath id="prefix__b">
        <use xlinkHref="#prefix__a" overflow="visible" />
      </clipPath>
      <path
        d="M13.9 29.3c0-3.3 2.7-6 6-6h116c3.3 0 6 2.7 6 6v68c0 3.3-2.7 6-6 6h-116c-3.3 0-6-2.7-6-6v-68z"
        clipPath="url(#prefix__b)"
        fill="#246ea9"
      />
      <g>
        <defs>
          <path
            d="M140.5 72.6h-6.4c-.6 0-1.1 0-1.4.3-.4.2-.5.6-.5 1.1 0 .6.3.9.8 1.1.4.1.8.2 1.3.2h1.9c1.9.1 3.2.4 4 1.2.1.1.2.2.3.4v-4.3zm0 9.7c-.9 1.3-2.5 1.9-4.8 1.9h-6.8V81h6.8c.7 0 1.1-.1 1.4-.4.2-.2.4-.5.4-1 0-.4-.2-.8-.4-1-.3-.2-.6-.3-1.2-.3-3.3-.1-7.4.1-7.4-4.6 0-2.1 1.4-4.4 5-4.4h7v-2.9H134c-2 0-3.4.5-4.4 1.2v-1.2H120c-1.5 0-3.3.4-4.2 1.2v-1.2H98.6v1.2c-1.4-1-3.7-1.2-4.7-1.2H82.5v1.2c-1.1-1-3.5-1.2-5-1.2H64.9L62 69.7l-2.7-3.1h-19v20.5h18.6l3-3.2 2.8 3.2h11.5v-4.8h1.1c1.5 0 3.3 0 4.9-.7v5.5h9.5v-5.3h.5c.6 0 .6 0 .6.6v4.7h28.7c1.8 0 3.7-.5 4.8-1.3v1.3h9.1c1.9 0 3.7-.3 5.2-.9v-3.9zm-14.1-5.9c.7.7 1.1 1.6 1.1 3.1 0 3.2-2 4.6-5.5 4.6h-6.9V81h6.8c.7 0 1.1-.1 1.4-.4.2-.2.4-.5.4-1 0-.4-.2-.8-.4-1-.3-.2-.6-.3-1.3-.3-3.3-.1-7.4.1-7.4-4.6 0-2.1 1.3-4.4 5-4.4h7v3.1h-6.4c-.7 0-1.1 0-1.4.3-.4.2-.5.6-.5 1.1 0 .6.3.9.8 1.1.4.1.8.2 1.4.2h1.9c2 .2 3.2.5 4 1.3zm-31.7-.9c-.5.3-1.1.3-1.7.3h-4.3v-3.3H93c.6 0 1.3 0 1.7.3.5.2.7.7.7 1.3.1.6-.2 1.1-.7 1.4zm2.2 1.8c.8.3 1.4.8 1.7 1.2.5.7.6 1.4.6 2.7v2.9h-3.5v-1.8c0-.9.1-2.2-.6-2.9-.5-.5-1.3-.7-2.6-.7h-3.8v5.4h-3.5V69.5h8.1c1.8 0 3.1.1 4.2.7 1.1.7 1.8 1.6 1.8 3.3 0 2.3-1.5 3.5-2.4 3.8zm4.4-7.8H113v3.1h-8.2v2.7h8v3h-8v2.9h8.2v3.1h-11.7V69.5zm-23.7 6.8H73v-3.7h4.6c1.3 0 2.1.5 2.1 1.8.1 1.2-.8 1.9-2.1 1.9zm-8.1 6.6l-5.4-6 5.4-5.8v11.8zm-13.9-1.8H47v-2.9h7.7v-3H47v-2.7h8.8l3.8 4.3-4 4.3zm27.9-6.8c0 4.1-3 4.9-6.1 4.9H73v4.9h-6.8l-4.3-4.9-4.5 4.9H43.5V69.5h14.1l4.3 4.8 4.5-4.8h11.2c2.8 0 5.9.8 5.9 4.8z"
            id="prefix__c"
          />
        </defs>
        <clipPath id="prefix__d">
          <use xlinkHref="#prefix__c" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#prefix__d)"
          fill="#fffffe"
          d="M35.3 61.6h110.2v30.5H35.3z"
        />
      </g>
      <g>
        <defs>
          <path
            d="M26.7 52.5l-2.4-5.9-2.4 5.9h4.8zm53-2.3c-.5.3-1.1.3-1.7.3h-4.3v-3.3H78c.6 0 1.3 0 1.7.3.5.2.7.7.7 1.3 0 .7-.3 1.2-.7 1.4zm30.5 2.3l-2.4-5.9-2.4 5.9h4.8zm-56.8 6.4h-3.6V47.4l-5.1 11.5h-3.1l-5.1-11.5v11.5h-7.1L28 55.6h-7.3l-1.4 3.3h-3.8l6.3-14.7H27l6 13.9V44.2h5.7l4.6 10 4.2-10h5.8v14.7zm14.3 0H56V44.2h11.7v3.1h-8.2v2.6h8v3h-8v2.9h8.2v3.1zm16.6-10.8c0 2.3-1.6 3.6-2.5 3.9.8.3 1.4.8 1.7 1.2.5.7.6 1.4.6 2.7v2.9h-3.5v-1.9c0-.9.1-2.2-.6-2.9-.5-.5-1.3-.6-2.6-.6h-3.8v5.3h-3.5V44.2h8.1c1.8 0 3.1.1 4.3.7 1.1.6 1.8 1.6 1.8 3.2zm2 10.8h3.6V44.2h-3.6v14.7zm45.2 0h-5l-6.7-11v11h-7.2l-1.4-3.3H104l-1.3 3.3h-4.1c-1.7 0-3.9-.4-5.1-1.6-1.2-1.3-1.9-2.9-1.9-5.6 0-2.2.4-4.2 1.9-5.7 1.1-1.2 2.9-1.7 5.3-1.7h3.4v3.1h-3.3c-1.3 0-2 .2-2.7.9-.6.6-1 1.8-1 3.3 0 1.6.3 2.7 1 3.5.5.6 1.5.8 2.4.8h1.6l5-11.6h5.3l5.9 13.9v-14h5.4l6.2 10.2V44.2h3.6v14.7zM15.4 61.8h6l1.4-3.3h3l1.3 3.3H39v-2.5l1.1 2.5h6.1l1-2.5v2.5h29.4v-5.4h.6c.4 0 .5.1.5.7v4.7h15.2v-1.3c1.2.7 3.1 1.3 5.6 1.3h6.4l1.4-3.3h3l1.3 3.3h12.3v-3.1l1.9 3.1h9.9V41.3h-9.8v2.4l-1.4-2.4h-10v2.4l-1.3-2.4H98.7c-2.3 0-4.3.3-5.9 1.2v-1.2h-9.3v1.2c-1-.9-2.4-1.2-4-1.2H45.4l-2.3 5.3-2.3-5.3H30v2.4l-1.2-2.4h-9.2L15.4 51v10.8z"
            id="prefix__e"
          />
        </defs>
        <clipPath id="prefix__f">
          <use xlinkHref="#prefix__e" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#prefix__f)"
          fill="#fffffe"
          d="M10.4 36.3h129.2v30.5H10.4z"
        />
        <path fill="#246ea9" d="M86.3 44.2h3.6v14.7h-3.6z" />
      </g>
    </svg>
  );
}
