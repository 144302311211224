/* eslint-disable max-len */
import * as React from 'react';

function Sale15(props) {
  return (
    <svg width={54} height={54} viewBox="0 0 54 54" fill="none" {...props}>
      <path
        d="M44.94 18.758c-.784-.307-1.77-.69-1.975-1.056a5.121 5.121 0
        01.055-2.338c.222-1.478.45-3.008-.517-3.971-.967-.963-2.521-.745-4.01-.51a5.097
        5.097 0 01-2.297.063 5.095 5.095 0 01-1.095-2.03c-.55-1.403-1.12-2.855-2.446-3.217-1.327-.361-2.543.593-3.71
        1.531a5.123 5.123 0 01-1.999 1.217c-.428
        0-1.247-.656-1.901-1.182-1.142-.906-2.43-1.936-3.794-1.57-1.364.365-1.964
        1.9-2.495 3.255-.308.786-.688 1.769-1.055 1.976a5.186
        5.186 0 01-2.34-.054c-1.487-.23-3.017-.447-3.975.516-.958.964-.746
        2.521-.519 4.021a5.12 5.12 0 01.065 2.298 5.11 5.11 0 01-2.017
        1.083c-1.405.551-2.859 1.12-3.218 2.447-.36 1.327.593 2.54 1.532 3.71a5.12 5.12 0
        011.219 2.004c0 .428-.656 1.25-1.182 1.901-.908 1.135-1.937 2.424-1.572 3.788.366
        1.364 1.902 1.967 3.257 2.495.784.307 1.77.688 1.975 1.055a5.123 5.123 0 01-.055
        2.338c-.222 1.478-.45 3.008.517 3.971.967.963 2.52.745 4.009.51a5.096 5.096 0 012.298-.063c.516.584.89
        1.279 1.095 2.03.55 1.404 1.12 2.855 2.446 3.217 1.327.361 2.53-.606 3.698-1.538.57-.554 1.26-.97
        2.016-1.213.428 0 1.247.656 1.901 1.182 1.137.907 2.425 1.937 3.788 1.572 1.364-.366 1.965-1.901
        2.495-3.256.309-.785.689-1.769 1.055-1.976a5.078 5.078 0 012.34.055c1.477.22 3.01.445
        3.97-.515.96-.96.746-2.522.52-4.014a5.12 5.12 0 01-.064-2.298 5.111 5.111 0 012.02-1.09c1.406-.55
        2.86-1.12 3.22-2.447.359-1.326-.608-2.527-1.538-3.698a5.12 5.12 0 01-1.214-2.016c0-.428.656-1.249
        1.182-1.901.908-1.136 1.939-2.427 1.574-3.789s-1.904-1.965-3.26-2.494z"
        fill="#49DF88"
      />
      <path
        d="M18.888 26.569c.681 0 1.022.29 1.022.868 0 .588-.34.882-1.022.882H14.1c-.69 0-1.036-.294-1.036-.882
        0-.579.345-.868 1.036-.868h1.302v-5.726l-1.232.742a.81.81 0 01-.42.126.768.768 0 01-.602-.294 1.02 1.02
        0 01-.238-.658c0-.327.145-.574.434-.742l2.212-1.33c.327-.196.653-.294.98-.294.308
        0 .56.098.756.294.196.187.294.443.294.77v7.112h1.302zm6.113-4.634c.606 0 1.148.135
        1.624.406.476.261.844.63 1.106 1.106.26.476.392 1.027.392 1.652 0 .653-.154 1.232-.462 1.736a3.124
        3.124 0 01-1.302 1.176c-.551.28-1.19.42-1.918.42a5.49 5.49 0 01-1.694-.266 4.5 4.5 0 01-1.428-.742.933.933
        0 01-.294-.322 1.029 1.029 0 01-.084-.434c0-.243.065-.448.196-.616.14-.177.308-.266.504-.266.112
        0 .214.019.308.056.093.028.214.084.364.168a8.37 8.37 0 001.022.532c.308.121.644.182 1.008.182.569
        0 .998-.13 1.288-.392.298-.261.448-.649.448-1.162 0-.485-.154-.868-.462-1.148-.3-.28-.705-.42-1.218-.42-.318
        0-.607.042-.868.126a3.576 3.576 0 00-.854.392 1.176 1.176 0 01-.588.168.873.873 0 01-.574-.196.714.714
        0 01-.224-.546v-4.074c0-.327.088-.579.266-.756.177-.177.429-.266.756-.266h4.508c.69 0 1.036.275 1.036.826
        0 .56-.346.84-1.036.84h-3.402v2.198c.448-.252.975-.378 1.582-.378zm8.478
        6.118c-.215.355-.48.532-.798.532a.71.71 0 01-.518-.224.72.72 0
        01-.21-.532c0-.177.056-.364.168-.56l4.956-8.554c.196-.355.457-.532.784-.532.205
        0 .378.075.518.224.15.14.224.317.224.532 0 .177-.056.364-.168.56l-4.956 8.554zm-1.82-3.724c-.803
        0-1.433-.257-1.89-.77-.448-.523-.672-1.279-.672-2.268 0-.961.229-1.699.686-2.212.457-.523
        1.083-.784 1.876-.784.803 0 1.428.261 1.876.784.457.513.686 1.255.686 2.226 0 .99-.229
        1.74-.686 2.254-.448.513-1.073.77-1.876.77zm0-1.316c.29 0 .5-.13.63-.392.13-.261.196-.7.196-1.316
        0-.588-.07-1.013-.21-1.274-.13-.27-.336-.406-.616-.406-.28 0-.485.13-.616.392-.13.261-.196.69-.196
        1.288 0 .607.065 1.045.196 1.316.13.261.336.392.616.392zm7.224 5.404c-.803
        0-1.433-.257-1.89-.77-.448-.523-.672-1.279-.672-2.268 0-.97.224-1.713.672-2.226.457-.513 1.087-.77
        1.89-.77.793 0 1.419.261 1.876.784.457.513.686 1.255.686 2.226 0 .98-.229
        1.731-.686 2.254-.448.513-1.073.77-1.876.77zm0-1.316c.29 0 .5-.13.63-.392.13-.261.196-.7.196-1.316
        0-.597-.07-1.027-.21-1.288-.13-.27-.336-.406-.616-.406-.28 0-.49.135-.63.406-.13.261-.196.69-.196
        1.288 0 .616.065 1.055.196 1.316.13.261.34.392.63.392zM27.295 37.606c-.648 0-1.212-.132-1.692-.396a2.776
        2.776 0 01-1.098-1.134c-.258-.492-.387-1.068-.387-1.728 0-.66.129-1.233.387-1.719.258-.492.624-.87
        1.098-1.134.48-.264 1.044-.396 1.692-.396.648 0 1.209.132 1.683.396.48.264.846.642 1.098 1.134.258.486.387
        1.06.387 1.72 0 .66-.13 1.235-.387 1.727a2.76 2.76 0 01-1.107 1.134c-.474.264-1.032.396-1.674.396zm0-1.269c.48
        0 .855-.168 1.125-.504.27-.342.405-.837.405-1.485 0-.648-.138-1.14-.414-1.476-.27-.336-.642-.504-1.116-.504-.48
        0-.855.168-1.125.504-.27.33-.405.822-.405 1.476s.135 1.15.405 1.485c.27.336.645.504 1.125.504zm4.967
        1.242c-.246 0-.444-.075-.594-.225-.15-.15-.225-.348-.225-.594v-4.815c0-.246.066-.435.198-.567.132-.132.32-.198.567-.198h3.024c.27
        0 .468.054.594.162.126.102.189.267.189.495 0 .222-.066.384-.198.486-.126.102-.321.153-.585.153h-2.16v1.17h1.98c.264
        0 .459.054.585.162.132.108.198.273.198.495 0 .222-.066.384-.198.486-.126.102-.321.153-.585.153h-1.98v1.818c0 .252-.075.453-.225.603-.144.144-.34.216-.585.216zm5.203 0c-.246 0-.444-.075-.594-.225-.15-.15-.225-.348-.225-.594v-4.815c0-.246.066-.435.198-.567.132-.132.321-.198.567-.198h3.024c.27 0 .468.054.594.162.126.102.189.267.189.495 0 .222-.066.384-.198.486-.126.102-.321.153-.585.153h-2.16v1.17h1.98c.264 0 .459.054.585.162.132.108.198.273.198.495 0 .222-.066.384-.198.486-.126.102-.321.153-.585.153h-1.98v1.818c0 .252-.075.453-.225.603-.144.144-.339.216-.585.216z"
        fill="#fff"
      />
    </svg>
  );
}

export default Sale15;
