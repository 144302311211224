import * as React from 'react';

const PaintingOrientation = props => {
  return (
    <svg
      width="33"
      height="28"
      viewBox="0 0 33 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.4">
        <path
          d="M30.3337 28H2.42924C1.08759 28 0 26.9124 0 25.5708V2.42924C0 1.08759 1.08759 0 2.42924 0H30.3337C31.6753 0 32.7629 1.08759 32.7629 2.42924V25.5708C32.7629 26.9124 31.6753 28 30.3337 28Z"
          fill="#EFDCFB"
        />
        <path
          d="M28.0579 25.7178H4.70308C3.58022 25.7178 2.66992 24.8075 2.66992 23.6846V4.31612C2.66992 3.19326 3.58022 2.28296 4.70308 2.28296H28.0579C29.1808 2.28296 30.0911 3.19326 30.0911 4.31612V23.6846C30.0911 24.8075 29.1808 25.7178 28.0579 25.7178Z"
          fill="#2BB9F9"
        />
        <path
          d="M18.0102 25.7182H4.70715C3.58407 25.7182 2.67334 24.8082 2.67334 23.6852V20.5101L6.04858 16.4075C7.16248 15.0523 9.23586 15.0523 10.3505 16.4075L12.4521 18.9624L18.0102 25.7182Z"
          fill="#B0E995"
        />
        <path
          d="M30.095 18.5793V23.6848C30.095 24.8079 29.1842 25.7178 28.0612 25.7178H6.8938L12.452 18.9621L19.28 10.6618C20.3939 9.30741 22.4681 9.30741 23.582 10.6618L30.095 18.5793Z"
          fill="#87E4BB"
        />
        <path
          d="M9.77387 10.0765C11.3662 10.0765 12.6571 8.78568 12.6571 7.1933C12.6571 5.60093 11.3662 4.31006 9.77387 4.31006C8.1815 4.31006 6.89062 5.60093 6.89062 7.1933C6.89062 8.78568 8.1815 10.0765 9.77387 10.0765Z"
          fill="#FFCD3E"
        />
      </g>
      <g clipPath="url(#clip0_6522_3700)">
        <path
          d="M5.50386 13.503L9.43097 9.57605C9.67082 9.33583 10.0924 9.33583 10.3323 9.57605L10.6747 9.91827C10.9228 10.1668 10.9228 10.5867 10.6745 10.8352L8.44805 13.0771H24.8049L22.5674 10.8247C22.3189 10.5761 22.3189 10.1638 22.5674 9.91514L22.9095 9.56943C23.1499 9.32903 23.5713 9.32719 23.8111 9.56741L27.7399 13.4947C27.8627 13.6177 27.9283 13.7814 27.9252 13.9503C27.9285 14.1258 27.8627 14.2902 27.7399 14.4129L23.813 18.3399C23.5728 18.5803 23.1515 18.5801 22.9115 18.3399L22.5694 17.9976C22.3206 17.7488 22.3206 17.3643 22.5694 17.1158L24.8602 14.8443H8.37434L10.6762 17.1265C10.7962 17.2461 10.8624 17.3968 10.8624 17.5672C10.8624 17.7376 10.7964 17.8929 10.6762 18.0129L10.3341 18.3527C10.2139 18.4727 10.0538 18.5374 9.88329 18.5374C9.71254 18.5374 9.55264 18.4709 9.43244 18.3509L5.50386 14.4219C5.38145 14.2993 5.31566 14.1349 5.31878 13.9659C5.31529 13.7902 5.38127 13.6257 5.50386 13.503Z"
          fill="#806BFF"
        />
      </g>
      <clipPath id="clip0_6522_3700">
        <rect
          width="22.6066"
          height="22.6066"
          fill="white"
          transform="matrix(-1 0 0 1 27.9258 2.65967)"
        />
      </clipPath>
    </svg>
  );
};

export default PaintingOrientation;
