/* eslint-disable max-len */
import * as React from 'react';

function Zoom(props) {
  return (
    <svg width={50} height={50} viewBox="0 0 50 50" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.954 34.008l12.435 12.435a2.081 2.081 0 010 2.946 2.081 2.081 0 01-2.946 0L34.008 36.954a20.728 20.728 0 01-13.175 4.712C9.346 41.666 0 32.321 0 20.833 0 9.346 9.346 0 20.833 0c11.488 0 20.833 9.346 20.833 20.833 0 4.996-1.77 9.583-4.712 13.175zM20.834 4.167c-9.19 0-16.667 7.477-16.667 16.666 0 9.19 7.477 16.667 16.666 16.667 9.19 0 16.667-7.477 16.667-16.667 0-9.19-7.477-16.666-16.667-16.666zm8.332 18.75a2.084 2.084 0 000-4.167h-6.25V12.5a2.084 2.084 0 00-4.166 0v6.25H12.5a2.084 2.084 0 000 4.166h6.25v6.25a2.084 2.084 0 004.166 0v-6.25h6.25z"
        fill="#fff"
      />
    </svg>
  );
}

export default Zoom;
