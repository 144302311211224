import * as React from "react"

function DashboardSettings(props) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.46 10.973l-1.655-1.294c.02-.2.039-.433.039-.68 0-.247-.018-.48-.04-.68l1.657-1.294a.811.811 0 00.195-1.033l-1.72-2.977a.798.798 0 00-.995-.358l-1.953.784a6.694 6.694 0 00-1.172-.68L11.52.69a.796.796 0 00-.795-.69h-3.45a.794.794 0 00-.793.684l-.298 2.078a6.9 6.9 0 00-1.17.68l-1.958-.785a.813.813 0 00-.987.351L.345 5.988a.806.806 0 00.195 1.04L2.194 8.32A6.528 6.528 0 002.155 9c0 .206.013.426.04.68L.537 10.974a.811.811 0 00-.195 1.034l1.72 2.976c.185.335.584.51.996.358l1.952-.784c.374.27.766.498 1.172.68l.297 2.07c.049.395.39.692.795.692h3.45a.795.795 0 00.794-.684l.298-2.077a6.93 6.93 0 001.17-.682l1.957.787a.792.792 0 00.988-.351L17.66 12a.81.81 0 00-.2-1.027zM9 12c-1.655 0-3-1.345-3-3 0-1.654 1.345-3 3-3 1.654 0 3 1.346 3 3 0 1.655-1.346 3-3 3z"
        fill="#3F4041"
      />
    </svg>
  )
}

export default DashboardSettings
