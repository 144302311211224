/* eslint-disable max-len */
import React from 'react';

function ZoomMinus(props) {
  const { width = 64 } = props;

  return (
    <svg width={width} viewBox="0 0 64 64" {...props}>
      <g fill="white">
        <path d="M26.396 52.15c6.404 0 12.26-2.354 16.783-6.225l17.439 17.438a2.015 2.015 0 102.85-2.85L46.029 43.076c3.872-4.522 6.226-10.38 6.226-16.786C52.255 12.029 40.656.429 26.397.429 12.136.429.536 12.03.536 26.29s11.6 25.86 25.86 25.86zm0-47.691c12.036 0 21.828 9.793 21.828 21.831s-9.792 21.831-21.828 21.831c-12.039 0-21.831-9.793-21.831-21.831S14.357 4.459 26.396 4.459z" />
        <path d="M19.23 28.305h14.16a2.015 2.015 0 100-4.03H19.23a2.015 2.015 0 100 4.03z" />
      </g>
    </svg>
  );
}

export default ZoomMinus;
