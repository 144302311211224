import * as React from 'react';

function Track(props) {
  return (
    <svg width={35} height={35} viewBox="0 0 35 35" fill="none" {...props}>
      <g fill="#000">
        <path
          d="M26.431 20.81a4.417 4.417 0 00-4.412 4.41 4.417 4.417 0 004.412 4.413
        4.416 4.416 0 004.412-4.412 4.417 4.417 0 00-4.412-4.412zm0 6.617a2.208
        2.208 0 01-2.206-2.206c0-1.217.99-2.206 2.206-2.206 1.217 0 2.206.99 2.206
        2.206s-.99 2.206-2.206 2.206zM11.357 20.81a4.417 4.417 0 00-4.412 4.41 4.417
        4.417 0 004.412 4.413 4.417 4.417 0 004.412-4.412 4.417 4.417 0 00-4.412-4.412zm0
        6.617a2.208 2.208 0 01-2.206-2.206c0-1.217.99-2.206 2.206-2.206s2.206.99 2.206
        2.206-.99 2.206-2.206 2.206zM29.404 8.18a1.104 1.104 0 00-.986-.607H22.61V9.78h5.129l3.003
        5.974 1.972-.991-3.31-6.581z"
        />
        <path
          d="M23.123 24.154h-8.346v2.206h8.346v-2.206zM8.051 24.154H4.228a1.103 1.103 0
        100 2.206H8.05a1.103 1.103 0 100-2.206zM34.768 17.412l-2.17-2.794a1.101 1.101 0
        00-.87-.427h-8.015v-7.72c0-.61-.494-1.103-1.103-1.103H4.228a1.103 1.103 0 100
        2.205h17.28v7.721c0 .61.493 1.103 1.102 1.103h8.578l1.606 2.07v5.687h-3.051a1.103
        1.103 0 100 2.206h4.154c.61 0 1.103-.494 1.103-1.103v-7.169c0-.245-.082-.483-.232-.676zM7.981
        18.566H2.908a1.103 1.103 0 100 2.206H7.98a1.103 1.103 0 100-2.206zM10.515 14.228H1.103a1.103
        1.103 0 000 2.206h9.412a1.103 1.103 0 100-2.206z"
        />
        <path d="M12.32 9.89H2.907a1.103 1.103 0 100 2.205h9.411a1.103 1.103 0 100-2.205z" />
      </g>
    </svg>
  );
}

export default Track;
