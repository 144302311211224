import React from 'react';

function Icon(props) {
  const { className, width } = props;

  return (
    <svg
      className={className}
      width={width}
      height={width}
      fill="none"
      viewBox="0 0 256 256"
    >
      <path
        d="M180.796 100.943L180.8 98.652C180.802 97.0466 179.373 95.6195 177.767 95.6183L162.785 95.6005L198.954 59.4312C200.129 58.2557 200.167 56.3784 198.992 55.2029L197.374 53.5846C196.198 52.4085 194.218 52.3458 193.043 53.5206L156.688 89.8757L156.72 74.5828C156.721 72.9743 155.329 71.5824 153.722 71.5848L151.441 71.5916C149.778 71.5916 148.429 72.9485 148.429 74.6105L148.406 100.906C148.407 101.704 148.7 102.486 149.29 103.076C149.293 103.08 149.327 103.113 149.331 103.117C149.903 103.689 150.682 103.979 151.481 103.98L177.772 103.967C178.575 103.967 179.338 103.647 179.908 103.076C180.479 102.506 180.797 101.745 180.796 100.943Z"
        fill="white"
      />
      <path
        d="M74.7234 154.44L74.719 156.732C74.7178 158.337 76.1461 159.764 77.7528 159.765L92.7349 159.783L56.5657 195.952C55.3902 197.128 55.3521 199.005 56.5275 200.181L58.1459 201.799C59.322 202.975 61.3014 203.038 62.4762 201.863L98.8313 165.508L98.7993 180.801C98.7987 182.409 100.191 183.801 101.797 183.799L104.079 183.792C105.742 183.792 107.09 182.435 107.09 180.773L107.113 154.478C107.112 153.68 106.82 152.898 106.23 152.308C106.226 152.304 106.193 152.271 106.189 152.266C105.617 151.694 104.838 151.404 104.039 151.403L77.7472 151.416C76.9445 151.417 76.1818 151.737 75.6116 152.307C75.0401 152.877 74.7221 153.638 74.7234 154.44Z"
        fill="white"
      />
      <path
        d="M101.942 71.5908L99.651 71.5865C98.0456 71.5852 96.6186 73.0135 96.6173 74.6202L96.5995 89.6024L60.4302 53.4331C59.2547 52.2576 57.3774 52.2195 56.202 53.395L54.5836 55.0133C53.4075 56.1894 53.3448 58.1688 54.5196 59.3437L90.8747 95.6987L75.5819 95.6667C73.9734 95.6661 72.5814 97.0581 72.5838 98.6648L72.5906 100.946C72.5906 102.609 73.9475 103.958 75.6095 103.958L101.905 103.981C102.703 103.98 103.485 103.687 104.075 103.097C104.079 103.094 104.112 103.06 104.116 103.056C104.688 102.484 104.978 101.705 104.979 100.906L104.966 74.6147C104.966 73.8119 104.646 73.0492 104.076 72.479C103.505 71.9076 102.744 71.5896 101.942 71.5908Z"
        fill="white"
      />
      <path
        d="M182.929 154.44L182.933 156.731C182.935 158.337 181.506 159.764 179.9 159.765L164.917 159.783L201.087 195.952C202.262 197.128 202.3 199.005 201.125 200.18L199.506 201.799C198.33 202.975 196.351 203.038 195.176 201.863L158.821 165.508L158.853 180.8C158.854 182.409 157.462 183.801 155.855 183.798L153.574 183.792C151.91 183.792 150.562 182.435 150.562 180.773L150.539 154.478C150.54 153.68 150.833 152.897 151.423 152.307C151.426 152.304 151.459 152.271 151.464 152.266C152.036 151.694 152.815 151.404 153.614 151.403L179.905 151.416C180.708 151.417 181.471 151.737 182.041 152.307C182.612 152.877 182.93 153.638 182.929 154.44Z"
        fill="white"
      />
    </svg>
  );
}

export default Icon;
