export const SET_ARTIST_LOADING_ON = 'SET_ARTIST_LOADING_ON';

export const SET_ARTIST_LOADING_OFF = 'SET_ARTIST_LOADING_OFF';

export const GET_ARTIST_ACCOUNT_SUCCESS = 'GET_ARTIST_ACCOUNT_SUCCESS';

export const GET_ARTIST_ACCOUNT_ERROR = 'GET_ARTIST_ACCOUNT_ERROR';

export const GET_ARTIST_ARTWORKS_SUCCESS = 'GET_ARTIST_ARTWORKS_SUCCESS';

export const GET_ARTIST_ARTWORKS_PAGINATION = 'GET_ARTIST_ARTWORKS_PAGINATION';

export const GET_ARTIST_SERIES_SUCCESS = 'GET_ARTIST_SERIES_SUCCESS';

export const SET_ARTIST_ARTWORK_LOADING = 'SET_ARTIST_ARTWORK_LOADING';

export const SET_ARTIST_POSTS_LOADING = 'SET_ARTIST_POSTS_LOADING';

export const SET_CURRENT_ARTIST_BLOG_POSTS = 'SET_CURRENT_ARTIST_BLOG_POSTS';

export const SET_CURRENT_ARTIST_BLOG_POST = 'SET_CURRENT_ARTIST_BLOG_POST';

export const SET_CURRENT_ARTIST_BLOG_POST_ERROR =
  'SET_CURRENT_ARTIST_BLOG_POST_ERROR';

export const DELETE_CURRENT_SINGLE_BLOG_POST =
  'DELETE_CURRENT_SINGLE_BLOG_POST';

export const OPEN_CURRENT_BLOG_EDITING_MODAL =
  'OPEN_CURRENT_BLOG_EDITING_MODAL';

export const UPDATE_CURRENT_SINGLE_BLOG_POST =
  'UPDATE_CURRENT_SINGLE_BLOG_POST';

export const SET_INITIAL_VALUE_OF_CURRENT_ARTIST_POSTS =
  'SET_INITIAL_VALUE_OF_CURRENT_ARTIST_POSTS';

export const ADD_CURRENT_ARTIST_BLOG_POSTS = 'ADD_CURRENT_ARTIST_BLOG_POSTS';

export const SET_ARTIST_AVATAR = 'SET_ARTIST_AVATAR';

export const RESET_CURRENT_ARTIST_BLOG_POST = 'RESET_CURRENT_ARTIST_BLOG_POST';
