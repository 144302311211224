export const SET_ARTWORKS_LOADING = 'SET_ARTWORKS_LOADING';

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const SET_ARTWORKS_STATE_FROM_COOKIES = 'SET_ARTWORKS_STATE_FROM_COOKIES';

export const GET_ARTWORKS_SUCCESS = 'GET_ARTWORKS_SUCCESS';

export const GET_ARTWORKS_ERROR = 'GET_ARTWORKS_ERROR';

export const SET_ARTWORKS_PAGE = 'SET_ARTWORKS_PAGE';

export const SAVE_ARTWORKS_PAGE_STATE = 'SAVE_ARTWORKS_PAGE_STATE';

export const SET_ARTWORKS_FILTER = 'SET_ARTWORKS_FILTER';

export const SET_ARTWORKS_DATE_FILTER = 'SET_ARTWORKS_DATE_FILTER';

export const UPDATE_ARTWORK_STATUS_SUCCESS = 'UPDATE_ARTWORK_STATUS_SUCCESS';

export const UPDATE_ARTWORK_STATUS_ERROR = 'UPDATE_ARTWORK_STATUS_ERROR';
