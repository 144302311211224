export const SET_LEGAL_DATA = 'SET_LEGAL_DATA';

export const REMOVE_LEGAL_ITEM = 'REMOVE_LEGAL_ITEM';

export const ADD_LEGAL_ITEM = 'ADD_LEGAL_ITEM';

export const UPDATE_LEGAL_ITEM = 'UPDATE_LEGAL_ITEM';

export const SET_SELECTED_LEGAL_ITEM = 'SET_SELECTED_LEGAL_ITEM';

export const SET_LEGAL_IMPRINT = 'SET_LEGAL_IMPRINT';

export const SET_LEGAL_LOADING = 'SET_LEGAL_LOADING';
