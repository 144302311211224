import * as React from "react"

function Certificate(props) {
  return (
    <svg
      width={26}
      height={38}
      viewBox="0 0 26 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.692 0H1.307C.702 0 .211.49.211 1.096v31.058c0 .605.49 1.096 1.096 1.096h12.058v3.654a1.096 1.096 0 001.704.912l1.584-1.056 1.585 1.056a1.095 1.095 0 001.704-.912V33.25h4.75c.605 0 1.096-.49 1.096-1.096V1.096c0-.605-.49-1.096-1.096-1.096zm-6.553 28.022a2.448 2.448 0 01-.556.302 2.77 2.77 0 01-.293.096l-.07.016a2.34 2.34 0 01-.114.023l-.056.01a2.518 2.518 0 01-.168.02l-.046.004a2.573 2.573 0 01-.365 0l-.047-.004a2.586 2.586 0 01-.168-.02l-.055-.01a2.438 2.438 0 01-.114-.023l-.07-.016a2.502 2.502 0 01-.58-.228 2.532 2.532 0 01-.27-.17 2.555 2.555 0 011.486-4.637 2.556 2.556 0 011.486 4.637zm-.39 6.834l-.488-.326a1.094 1.094 0 00-1.216 0l-.488.326v-4.29c.033.008.066.013.1.02a4.813 4.813 0 00.373.064l.096.012c.08.01.16.016.241.021h.01a4.853 4.853 0 00.552 0h.01a4.8 4.8 0 00.241-.02l.096-.013a4.56 4.56 0 00.374-.064c.033-.007.067-.012.1-.02v4.29zm5.847-3.798h-3.654v-1.693a4.735 4.735 0 001.461-3.423c0-2.619-2.13-4.75-4.75-4.75-2.619 0-4.75 2.131-4.75 4.75 0 1.344.562 2.558 1.462 3.423v1.693H2.403V2.192h21.193v28.866z"
        fill="#3F4041"
      />
      <path
        d="M5.69 6.942h4.385a1.096 1.096 0 000-2.192H5.69a1.096 1.096 0 000 2.192zM5.69 10.596h14.615a1.096 1.096 0 000-2.192H5.69a1.096 1.096 0 000 2.192zM5.69 14.25h14.615a1.096 1.096 0 000-2.192H5.69a1.096 1.096 0 000 2.192zM20.309 15.711h-4.385a1.096 1.096 0 000 2.193h4.385a1.096 1.096 0 000-2.193z"
        fill="#3F4041"
      />
    </svg>
  )
}

export default Certificate