import * as React from 'react';

const PaintingExtraLarge = props => {
  return (
    <svg
      width="33"
      height="30"
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.3">
        <path
          d="M30.3337 29.0042H2.42924C1.08759 29.0042 0 27.9166 0 26.5749V3.43339C0 2.09174 1.08759 1.00415 2.42924 1.00415H30.3337C31.6753 1.00415 32.7629 2.09174 32.7629 3.43339V26.5749C32.7629 27.9166 31.6753 29.0042 30.3337 29.0042Z"
          fill="#EFDCFB"
        />
        <path
          d="M28.0579 26.7219H4.70308C3.58022 26.7219 2.66992 25.8116 2.66992 24.6887V5.32027C2.66992 4.19741 3.58022 3.28711 4.70308 3.28711H28.0579C29.1808 3.28711 30.0911 4.19741 30.0911 5.32027V24.6887C30.0911 25.8116 29.1808 26.7219 28.0579 26.7219Z"
          fill="#2BB9F9"
        />
        <path
          d="M18.0102 26.7223H4.70715C3.58407 26.7223 2.67334 25.8123 2.67334 24.6893V21.5142L6.04858 17.4116C7.16248 16.0565 9.23586 16.0565 10.3505 17.4116L12.4521 19.9666L18.0102 26.7223Z"
          fill="#CDEC7A"
        />
        <path
          d="M30.095 19.5835V24.6889C30.095 25.812 29.1842 26.7219 28.0612 26.7219H6.8938L12.452 19.9662L19.28 11.6659C20.3939 10.3116 22.4681 10.3116 23.582 11.6659L30.095 19.5835Z"
          fill="#B0E995"
        />
        <path
          d="M9.77387 11.0807C11.3662 11.0807 12.6571 9.78983 12.6571 8.19745C12.6571 6.60508 11.3662 5.31421 9.77387 5.31421C8.1815 5.31421 6.89062 6.60508 6.89062 8.19745C6.89062 9.78983 8.1815 11.0807 9.77387 11.0807Z"
          fill="#FFCD3E"
        />
      </g>
      <path
        d="M30.5532 29.0005H2.44682C1.09546 29.0005 0 27.905 0 26.5536V3.24467C0 1.89331 1.09546 0.797852 2.44682 0.797852H30.5532C31.9045 0.797852 33 1.89331 33 3.24467V26.5536C33 27.905 31.9045 29.0005 30.5532 29.0005Z"
        fill="#EFDCFB"
      />
      <path
        d="M28.2595 26.7006H4.73562C3.60463 26.7006 2.68774 25.7837 2.68774 24.6527V5.14407C2.68774 4.01308 3.60463 3.09619 4.73562 3.09619H28.2595C29.3905 3.09619 30.3074 4.01308 30.3074 5.14407V24.6527C30.3074 25.7837 29.3905 26.7006 28.2595 26.7006Z"
        fill="#2BB9F9"
      />
      <path
        d="M18.1417 26.7038H4.74237C3.61117 26.7038 2.69385 25.7872 2.69385 24.6561V21.458L6.09352 17.3257C7.21547 15.9608 9.30386 15.9608 10.4266 17.3257L12.5434 19.8992L18.1417 26.7038Z"
        fill="#CDEC7A"
      />
      <path
        d="M30.3146 19.5131V24.6555C30.3146 25.7867 29.3973 26.7032 28.2661 26.7032H6.94556L12.5439 19.8986L19.4214 11.5382C20.5434 10.1741 22.6325 10.1741 23.7545 11.5382L30.3146 19.5131Z"
        fill="#B0E995"
      />
      <path
        d="M9.84576 10.9466C11.4497 10.9466 12.7499 9.64643 12.7499 8.04254C12.7499 6.43864 11.4497 5.13843 9.84576 5.13843C8.24186 5.13843 6.94165 6.43864 6.94165 8.04254C6.94165 9.64643 8.24186 10.9466 9.84576 10.9466Z"
        fill="#FFCD3E"
      />
    </svg>
  );
};

export default PaintingExtraLarge;
