export const ATELIER_SET_LOADING = 'ATELIER_SET_LOADING';
export const ATELIER_GET_DATA_SUCCESS = 'ATELIER_GET_DATA_SUCCESS';
export const ATELIER_GET_DATA_ERROR = 'ATELIER_GET_DATA_ERROR';
export const ATELIER_UNSET = 'ATELIER_UNSET';
export const ATELIER_SET_EDIT_MODE = 'ATELIER_SET_EDIT_MODE';
export const CREATE_ATELIER_LOADING = 'CREATE_ATELIER_LOADING';
export const CREATE_ATELIER_SUCCESS = 'CREATE_ATELIER_SUCCESS';
export const CREATE_ATELIER_ERROR = 'CREATE_ATELIER_ERROR';
export const ATELIER_SET_EDIT_TYPE = 'ATELIER_SET_EDIT_TYPE';
export const UPDATE_ATELIER_LOADING = 'UPDATE_ATELIER_LOADING';
export const UPDATE_ATELIER_SUCCESS = 'UPDATE_ATELIER_SUCCESS';
export const UPDATE_ATELIER_ERROR = 'UPDATE_ATELIER_ERROR';
export const INITIALIZE_ATELIER_LOADING = 'INITIALIZE_ATELIER_LOADING';
export const INITIALIZE_ATELIER_ERROR = 'INITIALIZE_ATELIER_ERROR';
export const INITIALIZE_ATELIER_SUCCESS = 'INITIALIZE_ATELIER_SUCCESS';
export const DELETE_POST_BY_MASTER_LOADING = 'DELETE_POST_BY_MASTER_LOADING';
export const DELETE_POST_BY_MASTER_SUCCESS = 'DELETE_POST_BY_MASTER_SUCCESS';
export const DELETE_POST_BY_MASTER_ERROR = 'DELETE_POST_BY_MASTER_ERROR';
export const SET_IS_OPEN_MODAL = 'SET_IS_OPEN_MODAL';
