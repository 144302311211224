import * as React from 'react';

function Share(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19.015 15.419c-1.38 0-2.61.657-3.398
          1.674L9.024 13.41c.154-.443.239-.922.239-1.415a4.21
          4.21 0 00-.244-1.42l6.588-3.678a4.284 4.284 0 003.403
          1.684 4.297 4.297 0 004.291-4.29A4.294 4.294 0 0019.011 0a4.294
          4.294 0 00-4.291 4.29c0 .499.085.977.244 1.421L8.38 9.389a4.27 4.27 0
          00-3.404-1.685 4.297 4.297 0 00-4.29 4.291 4.291 4.291 0 007.704 2.601l6.588
          3.683a4.27 4.27 0 00-.25 1.43A4.297 4.297 0 0019.02 24a4.294 4.294 0
          004.291-4.29 4.298 4.298 0 00-4.296-4.291zm0-14.069a2.948 2.948 0
          012.946 2.946 2.948 2.948 0 01-2.946 2.945 2.948 2.948 0 01-2.945-2.945
          2.951 2.951 0 012.945-2.946zM4.982 14.94a2.948 2.948 0 01-2.945-2.945A2.948
          2.948 0 014.982 9.05a2.948 2.948 0 012.945 2.945 2.951 2.951 0 01-2.945
          2.945zm14.033 7.71a2.948 2.948 0 01-2.945-2.946 2.948 2.948 0 012.945-2.945
          2.948 2.948 0 012.946 2.945 2.948 2.948 0 01-2.946 2.945z"
        fill="#3F4041"
      />
    </svg>
  );
}

export default Share;
