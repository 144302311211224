import React from 'react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="#3F4041"
        d="M8.398 19.219c0 .431-.35.781-.78.781H4.18a3.128 3.128 0 01-3.125-3.125V3.125A3.128 3.128 0 014.18 0h9.605a3.129 3.129 0 013.125 3.125v7.46a.781.781 0 01-1.563 0v-7.46c0-.862-.7-1.563-1.562-1.563H4.18c-.862 0-1.563.701-1.563 1.563v13.75c0 .861.701 1.563 1.563 1.563h3.437c.432 0 .781.35.781.78zm4.606-14.532H4.957a.781.781 0 100 1.563h8.046a.781.781 0 000-1.563zm.78 3.907a.781.781 0 00-.78-.781H4.957a.781.781 0 100 1.562h8.046c.432 0 .782-.35.782-.781zm-8.827 2.344a.781.781 0 100 1.562h4.106a.781.781 0 100-1.563H4.957zm13.885 5.722a6.69 6.69 0 01-.18.246c-.261.33-.874 1.1-1.726 1.78-1.093.872-2.217 1.314-3.342 1.314s-2.25-.442-3.342-1.314c-.853-.68-1.465-1.45-1.727-1.78a6.56 6.56 0 01-.18-.246.781.781 0 010-.898c.028-.04.122-.173.18-.246.262-.33.874-1.1 1.727-1.78 1.092-.872 2.217-1.314 3.342-1.314s2.25.442 3.342 1.314c.852.68 1.465 1.45 1.726 1.78.059.073.153.207.18.246a.78.78 0 010 .898zm-1.63-.45c-1.242-1.477-2.458-2.226-3.618-2.226-1.16 0-2.377.749-3.62 2.227 1.243 1.478 2.46 2.227 3.62 2.227 1.16 0 2.377-.75 3.619-2.227zm-3.58-1.483a1.484 1.484 0 100 2.968 1.484 1.484 0 000-2.968z"
      ></path>
    </svg>
  );
}

export default Icon;
