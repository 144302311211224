
export const SET_SOCIAL_MEDIA_LOADING = 'SET_SOCIAL_MEDIA_LOADING';

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const SET_SOCIAL_MEDIA_STATE_FROM_COOKIES = 'SET_SOCIAL_MEDIA_STATE_FROM_COOKIES';

export const GET_SOCIAL_MEDIA_SUCCESS = 'GET_SOCIAL_MEDIA_SUCCESS';

export const GET_SOCIAL_MEDIA_ERROR = 'GET_SOCIAL_MEDIA_ERROR';

export const SET_SOCIAL_MEDIA_FILTER = 'SET_SOCIAL_MEDIA_FILTER';

export const SET_SOCIAL_MEDIA_PAGE = 'SET_SOCIAL_MEDIA_PAGE';

export const VERIFY_SOCIAL_MEDIA_SUCCESS = 'VERIFY_SOCIAL_MEDIA_SUCCESS';

export const VERIFY_SOCIAL_MEDIA_ERROR = 'VERIFY_SOCIAL_MEDIA_ERROR';

export const DOWNLOAD_SOCIAL_MEDIA_SUCCESS = 'DOWNLOAD_SOCIAL_MEDIA_SUCCESS';

export const DOWNLOAD_SOCIAL_MEDIA_ERROR = 'DOWNLOAD_SOCIAL_MEDIA_ERROR';
