// Add to Catalog

export const SET_ARTWORKS_FB_LOADING = 'SET_ARTWORKS_FB_LOADING';

export const GET_ARTWORKS_FB_SUCCESS = 'GET_ARTWORKS_FB_SUCCESS';

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const SET_ARTWORKS_FB_PAGE = 'SET_ARTWORKS_FB_PAGE';

export const ADD_TO_FB_CATALOG_SUCCESS = 'ADD_TO_FB_CATALOG_SUCCESS';


// Catalog List

export const GET_FB_CATALOG_SUCCESS = 'GET_FB_CATALOG_SUCCESS';

export const GET_FB_CATALOG_ITEM_SUCCESS = 'GET_FB_CATALOG_ITEM_SUCCESS';

export const SET_EDIT_MODE = 'SET_EDIT_MODE';

export const CHANGE_FB_CATALOG_ITEM = 'CHANGE_FB_CATALOG_ITEM';

export const UPDATE_FB_CATALOG_ITEM_SUCCESS = 'UPDATE_FB_CATALOG_ITEM_SUCCESS';

export const CREATE_CSV_SUCCESS = 'CREATE_CSV_SUCCESS';

export const RESET_CSV = 'RESET_CSV';

export const DELETE_ARTWORK_FB_SUCCESS = 'DELETE_ARTWORK_FB_SUCCESS';

