/* eslint-disable max-len */
import * as React from 'react';

function Shipping(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M46.594 2.906H12.75c-.777 0-1.406.63-1.406 1.406v18.374a8.443 8.443 0 00-4.889 2.72H5.72V24c0-.777-.63-1.406-1.407-1.406H1.406a1.406 1.406 0 000 2.812h1.5v16.875h-1.5a1.406 1.406 0 000 2.813h2.907c.776 0 1.406-.63 1.406-1.407v-1.406h1.187c8.968 2.839 8.63 2.813 9.032 2.813H35.25c2.91 0 4.947-2.89 3.977-5.625h7.367c.776 0 1.406-.63 1.406-1.407V4.313c0-.776-.63-1.406-1.406-1.406zM26.812 5.72h5.625v7.031h-5.624V5.719zm9.844 35.156c0 .775-.63 1.406-1.406 1.406H16.156c-8.968-2.838-8.629-2.812-9.031-2.812H5.719v-11.25h1.406c.447 0 .867-.212 1.132-.572 2.227-3.02 6.757-3.022 8.986 0 .265.36.685.572 1.132.572h7.031c.776 0 1.407.63 1.407 1.406 0 .775-.631 1.406-1.407 1.406H16.97c-.777 0-1.407.63-1.407 1.407a7.04 7.04 0 007.032 7.03H35.25c.775 0 1.406.632 1.406 1.407zm8.532-4.219H22.593a4.226 4.226 0 01-3.978-2.812h6.79a4.224 4.224 0 004.219-4.219 4.224 4.224 0 00-4.219-4.219h-6.361a8.443 8.443 0 00-4.889-2.72V5.719H24v8.437c0 .777.63 1.406 1.406 1.406h8.438c.776 0 1.406-.63 1.406-1.406V5.72h9.938v30.937z"
        fill="#3F4041"
      />
    </svg>
  );
}

export default Shipping;
