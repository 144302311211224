export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';

export const GET_OFFERS_ERROR = 'GET_OFFERS_ERROR';

export const SET_OFFERS_LOADING = 'SET_OFFERS_LOADING';

export const SET_OFFER_EXPANDED = 'SET_OFFER_EXPANDED';

export const SET_OFFERS_FILTER = 'SET_OFFERS_FILTER';

export const RESET_OFFERS_FILTER = 'RESET_OFFERS_FILTER';
