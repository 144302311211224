import * as React from 'react';

function Map(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21.574 11.052c-.72.809-1.427 1.68-1.92 2.883a1.467 1.467 0 01-2.722-.004c-.57-1.397-1.435-2.35-2.274-3.27-.21-.231-.419-.463-.625-.702l-.03.011c-.816.247-1.445 1.328-1.066 2.207.258.604 2.064.49 1.91 1.788-.13 1.063 1.26 1.343 1.754 1.291.493-.059 1.25.81.868 1.192-.379.383-1.096.397-.986 1.383.03.287.898.25.898.684 0 .438-.45.935-.103 1.439.073.11.15.195.231.26a9.298 9.298 0 01-6.312 2.469c-5.139 0-9.318-4.179-9.318-9.318 0-1.56.387-3.03 1.067-4.326-.004 1.674.89 3.065 1.31 3.484 1.368 1.365 3.35.934 3.605 2.078.257 1.14-1.361 1.144-1.255 2.016.11.876 2.218 1.107 1.748 1.913-.622 1.07.419 1.148.117 2.468-.162.721.88.861 1.266.313.294-.42.235-.934.827-1.564.725-.772 2.406-.897 2.219-2.174-.32-2.118-2.38-2.45-3.278-2.946-1.019-.56-.769-1.902-.99-2.542-.309-.908-1.357.254-1.916-.276-.92-.868.165-2.096.938-2.07 1.63.05 2.13 1.636 2.73 1.581.584-.051.963-.868 1.066-1.302.217-.945-.368-.522-.603-1.015-.316-.659 1.11-1.288 1.662-1.773a.742.742 0 00.18-.21c.166-.324-.05-.57-.419-.795-.735-.445-2.078-.798-2.14-1.493-.037-.427.78-.633 1.71-.67.154.008.305.022.46.037.077-.438.202-.868.371-1.28a10.343 10.343 0 00-1.357-.088C5.333 2.73.563 7.5.563 13.365.563 19.23 5.332 24 11.197 24c5.864 0 10.635-4.771 10.635-10.635 0-.794-.089-1.57-.258-2.313zM17.796.024c-3.002.283-5.058 3.192-4.534 6.162.532 3.016 3.313 3.945 4.693 7.331a.366.366 0 00.679.001c1.53-3.755 4.783-4.488 4.783-8.396a5.123 5.123 0 00-5.62-5.098zm.499 7.803a2.705 2.705 0 110-5.41 2.705 2.705 0 010 5.41z"
        fill="#806BFF"
      />
    </svg>
  );
}

export default Map;
