export const START_CREATING_COLLECTOR_ACCOUNT = 'CREATE_COLLECTOR_ACCOUNT';

export const NEXT_STEP = 'NEXT_STEP';

export const STEP_BACK = 'STEP_BACK';

export const INITIAL_STEP = 'INITIAL_STEP';

export const CHANGE_PROCEED_VALUE = 'CHANGE_PROCEED_VALUE';

export const FETCHING = 'FETCHING';

export const FETCHED = 'FETCHED';

export const IS_CHANGED_INFO_FORM = 'IS_CHANGED_INFO_FORM';

export const IS_CHANGED_ADDRESS_FORM = 'IS_CHANGED_ADDRESS_FORM';

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

export const SET_CHECKOUT_DATA = 'SET_CHECKOUT_DATA';

export const SET_ORDERS = 'SET_ORDERS';

export const SET_SHIPPING_COST = 'SET_SHIPPING_COST';

export const SET_STRIPE_TOKEN = 'SET_STRIPE_TOKEN';

export const SET_CART_ITEMS = 'SET_CART_ITEMS';

export const SET_SHIPMENT_RATE = 'SET_SHIPMENT_RATE';

export const GET_SHIPMENT_RATE = 'GET_SHIPMENT_RATE';

// Checkout payment system actions

export const SET_PAYMENT_SYSTEM_ACCESS = 'SET_PAYMENT_SYSTEM_ACCESS';

export const SET_PAYMENT_SYSTEM_IS_FROM_VALID =
  'SET_PAYMENT_SYSTEM_IS_FROM_VALID';
