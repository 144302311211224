export const SET_PUBLIC_ARTISTS_LOADING = 'SET_PUBLIC_ARTISTS_LOADING';

export const SET_NEW_PUBLIC_ARTISTS_LOADING = 'SET_NEW_PUBLIC_ARTISTS_LOADING';

export const SET_PUBLIC_ARTISTS_CHECKED = 'SET_PUBLIC_ARTISTS_CHECKED';

export const SET_PUBLIC_ARTISTS_PAGE = 'SET_PUBLIC_ARTISTS_PAGE';

export const SET_PUBLIC_ARTISTS_SEARCH = 'SET_PUBLIC_ARTISTS_SEARCH';

export const GET_PUBLIC_ARTISTS_SUCCESS = 'GET_PUBLIC_ARTISTS_SUCCESS';

export const GET_NEW_PUBLIC_ARTISTS_SUCCESS = 'GET_NEW_PUBLIC_ARTISTS_SUCCESS';

export const GET_PUBLIC_ARTISTS_STATE_FROM_COOKIES = 'GET_PUBLIC_ARTISTS_STATE_FROM_COOKIES';

export const GET_FEATURES_CARDS = 'GET_FEATURES_CARDS';
