import React from 'react';

const KlarnaIconDisabled = function(properties) {
  const { param: parameter } = properties;
  return (
    <svg fill="none" height={parameter} viewBox="0 0 32 32" width={parameter}>
      <path
        d="M32 16c0 2.448-.55 4.767-1.533 6.841a16.03 16.03 0 01-3.676 4.972A15.94 15.94 0 0116 32a15.937 15.937 0 01-10.506-3.932 16.066 16.066 0 01-2.124-2.245A15.93 15.93 0 010 16c0-3.7 1.256-7.109 3.367-9.82a16.03 16.03 0 011.696-1.857A15.94 15.94 0 0116 0c8.837 0 16 7.163 16 16z"
        fill="#737373"
      />
      <path
        d="M22.445 4.324c-.008.875-.06 1.748-.178 2.615a18.879 18.879 0 01-1.552 5.356 18.61 18.61 0 01-3.744 5.397c-.097.098-.07.15-.006.238 2.444 3.329 4.886 6.659 7.328 9.989.03.04.059.082.09.127-.046.04-.094.023-.136.023-2.148 0-4.295 0-6.443.003-.101 0-.164-.027-.227-.112A8175.21 8175.21 0 009.58 17.055c-.087-.119-.084-.168.037-.26.965-.734 1.98-1.407 2.885-2.219 2.348-2.104 3.818-4.707 4.383-7.815.147-.805.228-1.617.204-2.437h5.357zM8.84 27.867c0 .163-.031.208-.202.206-1.047-.004-2.096-.004-3.143-.004a16.072 16.072 0 01-2.125-2.245c0-6.547 0-13.095-.002-19.642V6.18 4.479c0-.13.028-.16.158-.159l1.537.004H8.83c.002.063.005.126.005.189 0 7.785 0 15.57.005 23.354zM30.468 22.842a16.03 16.03 0 01-3.676 4.97 3.1 3.1 0 01-1.269-2.49c-.005-1.697 1.393-3.094 3.097-3.093.694 0 1.334.227 1.848.613z"
        fill="#F0EFEB"
      />
    </svg>
  );
};

export default KlarnaIconDisabled;
