import * as React from 'react';

function AddressInfo(props) {
  return (
    <svg width={34} height={34} viewBox="0 0 34 34" fill="none" {...props}>
      <path
        d="M16.999 0C9.773 0 3.895 5.878 3.895 13.104c0 4.064 2.102 8.784 6.25 14.029 3.034 3.838 6.025 6.49
        6.15 6.601a1.063 1.063 0 001.407 0c.125-.11 3.116-2.763 6.15-6.601 4.148-5.245 6.25-9.965
        6.25-14.029C30.103 5.878 24.225 0 17 0zm0 31.488c-2.525-2.407-10.98-11.015-10.98-18.384C6.02
        7.05 10.946 2.125 17 2.125s10.979 4.925 10.979 10.98c0 7.368-8.454 15.977-10.98 18.383z"
        fill={props.fill || '#3F4041'}
      />
      <path
        d="M25.129 12.475L17.69 6.1a1.062 1.062 0 00-1.383 0l-7.437 6.375a1.063 1.063 0 001.383
        1.613l.902-.773v5.279c0 .586.476 1.062 1.063 1.062h9.562c.587 0 1.063-.476 1.063-1.062v-5.28l.902.774a1.062
        1.062 0 101.383-1.613zm-4.41 5.056H13.28v-6.038L17 8.306l3.719 3.187v6.038z"
        fill={props.fill || '#3F4041'}
      />
    </svg>
  );
}

export default AddressInfo;
