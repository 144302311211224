export const SET_LOADING = 'BLOG_SET_LOADING';
export const GET_POSTS_SUCCESS = 'BLOG_GET_POSTS_SUCCESS';
export const GET_POSTS_ERROR = 'BLOG_GET_POSTS_ERROR';
export const GET_CATEGORIES_SUCCESS = 'BLOG_GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'BLOG_GET_CATEGORIES_ERROR';
export const GET_CATEGORY_POSTS_SUCCESS = 'BLOG_GET_CATEGORY_POSTS_SUCCESS';
export const GET_CATEGORY_POSTS_ERROR = 'BLOG_GET_CATEGORY_POSTS_ERROR';
export const GET_FOOTER_POSTS_SUCCESS = 'GET_FOOTER_POSTS_SUCCESS';
export const GET_FOOTER_POSTS_ERROR = 'GET_FOOTER_POSTS_ERROR';
export const GET_POPULAR_POSTS_SUCCESS = 'BLOG_GET_POPULAR_POSTS_SUCCESS';
export const GET_POPULAR_POSTS_ERROR = 'BLOG_GET_POPULAR_POSTS_ERROR';
