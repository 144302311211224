export const GET_CART_ITEMS = 'GET_CART_ITEMS';

export const LOAD_CART_ITEMS = 'LOAD_CART_ITEMS';

export const SET_CART_ITEMS = 'SET_CART_ITEMS';

export const SET_CART_DATA = 'SET_CART_DATA';

export const SET_CART_TOTAL = 'SET_CART_TOTAL';

export const CALCULATING = 'CALCULATING';
