import React from 'react';

export default function RotateRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="30"
      fill="none"
      viewBox="0 0 27 30"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M3.75 16.875c0 5.17 4.206 9.375 9.375 9.375 1.574 0 3.13-.396 4.497-1.147l1.804 3.288A13.147 13.147 0 0113.125 30C5.888 30 0 24.111 0 16.875 0 10.277 4.9 4.815 11.25 3.9V0l5.625 5.625-5.625 5.625V7.69c-4.274.87-7.5 4.658-7.5 9.185zm22.16-2.982a13.142 13.142 0 00-3.003-5.77l-2.795 2.5a9.371 9.371 0 012.145 4.12l3.652-.85zm-3.392 4.309a9.315 9.315 0 01-2.107 4.695l2.887 2.41a13.05 13.05 0 002.952-6.58l-3.732-.525z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
