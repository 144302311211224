import React from 'react';

function Icon(props) {
  const { width = 195, className, onClick } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      fill="none"
      onClick={onClick}
      viewBox="0 0 195 379"
    >
      <path
        fill="url(#paint0_linear)"
        d="M113.01 378.357c-29.404-.55-55.315-11.002-78.696-27.864-8.972-6.855-8.802-5.811-20.827-16.538-1.527-1.363-9.358-10.339-10.776-13.324a24.758 24.758 0 01-2.367-9.527c0-2.534.765-6.031 2.31-6.904 2.266-1.281 4.396 1.468 6.327 2.75a202.109 202.109 0 0044.01 33.256 134.783 134.783 0 0080.649 14.771 180.883 180.883 0 0051.767-14.689c2.751-1.155 5.281-2.75 7.482 1.045a5.79 5.79 0 011.17 4.627 5.769 5.769 0 01-2.71 3.928c-16.196 13.652-34.108 22.389-56.691 26.076-9.05 1.478-11.8 2.393-21.648 2.393z"
      ></path>
      <path
        fill="url(#paint1_linear)"
        fillRule="evenodd"
        d="M83.412 161.304c-8.885 21.51-15.184 43.461-15.651 66.869 0 1.375.027 2.75.247 4.346-.165 4.648 1.046 8.169 6.134 9.764 1.836.627 3.288 1.391 4.894 2.237 1.43.753 2.982 1.57 5.036 2.412 4.402 1.513 8.22 1.23 10.559-2.374a401.88 401.88 0 013.547-5.337c4.829-7.185 10.201-15.177 13.511-23.701 7.507-19.483 13.62-39.437 19.719-59.349a5433.99 5433.99 0 012.891-9.418c8.599-27.929 16.906-55.962 25.212-83.991l3.697-12.474c3.274-10.672 6.492-21.207 8.775-32.21a16.252 16.252 0 00-2.751-11.855c-6.986-9.05-18.099-7.73-23.243 2.558-3.078 6.177-5.591 12.537-8.12 18.938-.726 1.836-1.453 3.676-2.195 5.515a22155.24 22155.24 0 00-19.156 47.074c-10.981 27.043-21.945 54.042-33.106 80.996zm-11.464 64.573c-.003-.491-.006-.85-.006-1.17 0-1.018.028-2.393.028-2.393 0-3.55 4.073-1.541 6.7-.245.425.21.813.401 1.139.547 2.475 1.155 4.937 2.338 7.398 3.521a717.137 717.137 0 007.4 3.521c2.448 1.128 2.943 2.448 1.348 4.924-3.224 5.002-4.854 7.636-7.094 8.349-2.49.794-5.737-.783-12.765-4.113a7.044 7.044 0 00-.204-.156c-.95-.711-3.922-2.936-3.922-6.721 0-3.297-.013-4.995-.022-6.064zm17.923 60.499c1.547-2.455 3.03-4.805 2.758-9.05-.99-15.459-12.818-28.002-27.452-27.892-8.692 0-14.77 4.539-18.869 12.241-3.768 7.069.22 11.442 4.704 15.898a18.816 18.816 0 015.226 19.255c-.373 1.417-.77 2.832-1.169 4.247-.923 3.282-1.847 6.566-2.462 9.891a7.238 7.238 0 001.293 5.639c.963.963 3.576.633 5.364.358a9.477 9.477 0 003.603-1.898 91.466 91.466 0 0025.416-25.966c.488-.979 1.042-1.858 1.588-2.723z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="173.025"
          x2="131.325"
          y1="340.831"
          y2="400.145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0429E9"></stop>
          <stop offset="0.479" stopColor="#7E10BF"></stop>
          <stop offset="1" stopColor="#DB1A8A"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="159.271"
          x2="56.122"
          y1="10.753"
          y2="316.075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0429E9"></stop>
          <stop offset="0.479" stopColor="#7E10BF"></stop>
          <stop offset="1" stopColor="#DB1A8A"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;
