/* eslint-disable max-len */
import React from 'react';

import styles from './icons.module.scss';

export const HeartBlack = ({ size = 'md', className = '' }) => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${styles.svg} ${styles[size]} ${className}`}
  >
    <path
      d="M16.5573 1.56581C15.6264 0.556101 14.349 0 12.9602 0C11.9221 0 10.9714 0.328199 10.1344 0.975406C9.71207 1.3021 9.3294 1.70178 8.99201 2.16828C8.65475 1.70192 8.27194 1.3021 7.84948 0.975406C7.01266 0.328199 6.06195 0 5.02384 0C3.63503 0 2.35749 0.556101 1.42654 1.56581C0.506706 2.56372 0 3.92701 0 5.40473C0 6.92567 0.566803 8.31791 1.78369 9.7863C2.87229 11.0998 4.43687 12.4332 6.24869 13.9772C6.86736 14.5044 7.56862 15.1021 8.29678 15.7388C8.48914 15.9072 8.73598 16 8.99201 16C9.2479 16 9.49487 15.9072 9.68696 15.739C10.4151 15.1023 11.1168 14.5043 11.7357 13.9767C13.5473 12.433 15.1119 11.0998 16.2005 9.78616C17.4174 8.31791 17.984 6.92567 17.984 5.40459C17.984 3.92701 17.4773 2.56372 16.5573 1.56581Z"
      fill="#3F4041"
    />
  </svg>
);
