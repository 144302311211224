export const INITIAL_STATE = {
  id: null,
  email: null,
  token: null,
  location: null,
  loading: false,
  error: false,
  message: null,
  cartCounter: null,
  is_activated: false,
  canUploadArtwork: undefined,
  artworksCount: 0,
  isRoleChanged: false,
  roleChangedTo: null,
  isArtist: false,
};

export const SET_AC_MODAL = 'SET_AC_MODAL';
export const SET_USER_DATA_FETCHING = 'SET_USER_DATA_FETCHING';
export const SET_USER_DATA_SUCCESS = 'SET_USER_DATA_SUCCESS';
export const SET_USER_DATA_FAILURE = 'SET_USER_DATA_FAILURE';

export const SET_USER_SIGN_UP_TOKEN_FETCHING =
  'SET_USER_SIGN_UP_TOKEN_FETCHING';
export const SET_USER_SIGN_UP_TOKEN_SUCCESS = 'SET_USER_SIGN_UP_TOKEN_SUCCESS';
export const SET_USER_SIGN_UP_TOKEN_FAILURE = 'SET_USER_SIGN_UP_TOKEN_FAILURE';

export const SET_USER_FLAG_FETCHING = 'SET_USER_FLAG_FETCHING';
export const SET_USER_FLAG_SUCCESS = 'SET_USER_FLAG_SUCCESS';
export const SET_USER_FLAG_FAILURE = 'SET_USER_FLAG_FAILURE';

export const SET_LOCATION_DATA_FETCHING = 'SET_LOCATION_DATA_FETCHING';
export const SET_LOCATION_DATA_SUCCESS = 'SET_LOCATION_DATA_SUCCESS';
export const SET_LOCATION_DATA_FAILURE = 'SET_LOCATION_DATA_FAILURE';

export const GET_USER_DATA_FETCHING = 'GET_USER_DATA_FETCHING';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

export const UPDATE_LOCATION_DATA_START = 'UPDATE_LOCATION_DATA_START';
export const UPDATE_LOCATION_DATA_SUCCESS = 'UPDATE_LOCATION_DATA_SUCCESS';
export const UPDATE_LOCATION_DATA_FAILURE = 'UPDATE_LOCATION_DATA_FAILURE';

export const UPDATE_USER_DATA_FETCHING = 'UPDATE_USER_DATA_FETCHING';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const UPDATE_USER_SUBSCRIPTION_DATA_SUCCESS =
  'UPDATE_USER_SUBSCRIPTION_DATA_SUCCESS';
export const UPDATE_USER_DATA_FAILURE = 'UPDATE_USER_DATA_FAILURE';

export const DELETE_USER_DATA_FETCHING = 'DELETE_USER_DATA_FETCHING';
export const DELETE_USER_DATA_SUCCESS = 'DELETE_USER_DATA_SUCCESS';
export const DELETE_USER_DATA_FAILURE = 'DELETE_USER_DATA_FAILURE';

export const DELETE_SING_UP_TOKEN_FETCHING = 'DELETE_SING_UP_TOKEN_FETCHING';
export const DELETE_SING_UP_TOKEN_SUCCESS = 'DELETE_SING_UP_TOKEN_SUCCESS';
export const DELETE_SING_UP_TOKEN_FAILURE = 'DELETE_SING_UP_TOKEN_FAILURE';

export const SET_PREDICTED_ADDRESS = 'SET_PREDICTED_ADDRESS';

export const QUERY_SUCCESS = 'QUERY_SUCCESS';

export const QUERY_FETCHING = 'QUERY_FETCHING';

export const QUERY_ERROR = 'QUERY_ERROR';

export const QUERY_INITIAL_STATE = 'QUERY_INITIAL_STATE';

export const SET_LOADING_STATE = 'SET_LOADING_STATE';

export const SET_ARTWORK_UPLOADING_STATE = 'SET_ARTWORK_UPLOADING_STATE';

export const SET_UPLOADED_COUNT_STATE = 'SET_UPLOADED_COUNT_STATE';

export const SET_USER_PROF_STATUS = 'SET_USER_PROF_STATUS';

export const SET_USER_ACCOUNT = 'SET_USER_ACCOUNT';

export const SET_USER_ACCOUNT_IS_ACTIVE = 'SET_USER_ACCOUNT_IS_ACTIVE';

export const SET_USER_ACCOUNT_THEME = 'SET_USER_ACCOUNT_THEME';

export const SET_LOADING_USER_DATA = 'SET_LOADING_USER_DATA';
