import * as React from "react"

function Reset(props) {
  return (
    <svg
      width={18}
      height={20}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.957 19.944c4.012-.45 7.227-3.657 7.685-7.669.6-5.245-3.477-9.71-8.588-9.768V.154c0-.131-.163-.2-.273-.119L3.935 3.594a.15.15 0 000 .24l4.846 3.56c.11.08.273.007.273-.12V4.927c3.591.057 6.468 3.113 6.223 6.765-.209 3.142-2.77 5.691-5.912 5.891-3.33.213-6.161-2.165-6.668-5.31a1.207 1.207 0 00-1.193-1.01c-.731 0-1.303.65-1.189 1.373.711 4.482 4.85 7.844 9.642 7.309z"
        fill="#806BFF"
      />
    </svg>
  )
}

export default Reset;