export const OVERDUE_DAYS = 25;

export const OVERDUE_DAYS_MANUAL = 30;

export const FRIDAY = 5;

export const SATURDAY = 6;

export const SUNDAY = 0;

export const STANDARD_FORMAT = 'MM DD YYYY';

export const MILLISECONDS = 'milliseconds';
