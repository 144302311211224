export const SET_TRANSACTIONS_LOADING = 'SET_TRANSACTIONS_LOADING';

export const SET_CURRENT_CHARGES_SUCCESS = 'SET_CURRENT_CHARGES_SUCCESS';

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const GET_CHARGES_SUCCESS = 'GET_CHARGES_SUCCESS';

export const GET_CHARGES_ERROR = 'GET_CHARGES_ERROR';

export const SET_TRANSACTIONS_MONTH = 'SET_TRANSACTIONS_MONTH';

export const SET_TRANSACTIONS_YEAR = 'SET_TRANSACTIONS_YEAR';
