/* eslint-disable max-len */
import * as React from 'react';

function Palette(props) {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
      <g clipPath="url(#prefix__clip5)" fill={props.fill || '#3F4041'}>
        <path d="M9.671 7.142a2.64 2.64 0 00-2.636 2.636 2.64 2.64 0 002.636 2.636 2.64 2.64
        0 002.637-2.636A2.64 2.64 0 009.67 7.142zm0 3.515a.88.88 0 010-1.758.88.88 0 010
        1.758zM6.156 12.414A2.64 2.64 0 003.52 15.05a2.64 2.64 0 002.636 2.636 2.64 2.64
        0 002.636-2.636 2.64 2.64 0 00-2.636-2.636zm0 3.515a.88.88 0 010-1.757.88.88 0 010
        1.757zM7.914 19.444a2.64 2.64 0 00-2.637 2.637 2.64 2.64 0 002.637 2.636 2.64 2.64 0
        002.636-2.636 2.64 2.64 0 00-2.636-2.637zm0 3.515a.88.88 0 010-1.757.88.88 0 010 1.757z"
        />
        <path d="M28.743 4.173c.882-1.293 1.778-2.892.877-3.793-.426-.426-1.066-.494-1.903-.203-.536.186-1.186.524-1.933
        1.005-1.538.99-3.534 2.626-5.375 4.578-3.375-2.2-7.558-2.678-11.25-1.496C3.058 6.215-1.05 12.449.242 19.348c.952
        5.088 4.987 9.243 10.04 10.337.968.21 1.939.315 2.903.315.658 0 1.313-.049 1.962-.147a3.745 3.745 0 003.002-2.559
        3.873 3.873 0 00-.934-3.971 1.756 1.756 0 010-2.485 1.756 1.756 0 012.485 0 3.866 3.866 0 003.964.935 3.744 3.744
        0 002.556-3.003c.466-3.086-.154-6.464-2.079-9.328 1.545-1.458 3.398-3.503 4.602-5.269zm-.713-2.232c-.428.898-1.297
        2.046-1.926 2.817l-.958-.958c.8-.627 1.95-1.45 2.884-1.859zm-3.548 16.567c-.114.755-.625 1.351-1.367
        1.595-.606.2-1.494.17-2.172-.508a3.519 3.519 0 00-4.97 0 3.512 3.512 0 000 4.971c.678.68.707 1.57.507 2.18-.245.743-.842
        1.256-1.595 1.37-1.389.209-2.812.159-4.231-.148-4.37-.947-7.86-4.541-8.685-8.944C.463 10.98 7.35 4.097 15.401 5.604a11.22 11.22
        0 013.818 1.482c-1.007 1.179-2.111 2.642-2.603 3.88a3.517 3.517 0 00-4.336 2.376c-.266.898-1.096 1.585-2.114 1.75a.879.879
        0 00-.481 1.488l.248.249a5.762 5.762 0 004.101 1.699c1.55 0 3.006-.604 4.101-1.7a3.519 3.519 0
        00.848-3.597c1.232-.51 2.675-1.605 3.837-2.599 1.574 2.458 2.049 5.312 1.662 7.876zm-7.59-2.922a4.016 4.016 0
        01-2.858 1.184 4.02 4.02 0 01-1.988-.52c.92-.543 1.619-1.396 1.919-2.408a1.76 1.76 0 012.928-.74c.68.68.689
        1.795 0 2.484zm2.957-4.868c-.769.529-1.285.79-1.611.92.13-.326.391-.842.92-1.61 1.2-1.743 3.027-3.679 4.632-5.098l1.165
        1.164c-1.469 1.624-3.324 3.397-5.106 4.624z"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip5">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Palette;
