import * as React from 'react';

const Money = props => {
  return (
    <svg
      width="36"
      height="19"
      viewBox="0 0 36 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.6147 18.385H2.61191C1.16937 18.385 0 17.6709 0 16.79V1.59506C0 0.714119 1.16937 0 2.61191 0H32.6147C34.0572 0 35.2266 0.714119 35.2266 1.59506V16.79C35.2266 17.6709 34.0572 18.385 32.6147 18.385Z"
        fill="#38BC73"
      />
      <path
        d="M31.1819 16.8414H4.09039C2.78788 16.8414 1.73193 16.247 1.73193 15.5137V2.86511C1.73193 2.13183 2.78788 1.53735 4.09039 1.53735H31.1819C32.4844 1.53735 33.5404 2.13183 33.5404 2.86511V15.5137C33.5404 16.247 32.4844 16.8414 31.1819 16.8414Z"
        fill="#5AC98B"
      />
      <circle cx="17.6374" cy="9.34003" r="4.20112" fill="#37B972" />
    </svg>
  );
};

export default Money;
