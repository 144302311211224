/* eslint-disable max-len */
import React from 'react';

function ZoomPlus(props) {
  const { width = 64 } = props;

  return (
    <svg width={width} viewBox="0 0 64 64" {...props}>
      <g fill="white">
        <path d="M25.896 52.205c6.375 0 12.204-2.344 16.706-6.196l17.357 17.356a2.005 2.005 0 102.836-2.836L45.437 43.172c3.854-4.5 6.198-10.331 6.198-16.707C51.635 12.27 40.089.724 25.896.724 11.702.724.154 12.27.154 26.465c-.001 14.193 11.547 25.74 25.742 25.74zm0-47.471c11.979 0 21.729 9.748 21.729 21.73 0 11.981-9.75 21.729-21.729 21.729-11.983 0-21.731-9.747-21.731-21.729-.001-11.982 9.747-21.73 21.731-21.73z" />
        <path d="M18.15 28.471h5.041v5.041a2.006 2.006 0 004.012 0v-5.041h5.043c1.108 0 2.004-.898 2.004-2.006s-.896-2.006-2.004-2.006h-5.043v-5.042a2.004 2.004 0 00-2.006-2.005 2.005 2.005 0 00-2.006 2.005v5.042H18.15a2.006 2.006 0 000 4.012z" />
      </g>
    </svg>
  );
}

export default ZoomPlus;
