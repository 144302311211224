import * as React from 'react';

const PaintingHorizontal = props => {
  return (
    <svg
      width="29"
      height="21"
      viewBox="0 0 29 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.8498 21H2.15023C0.962675 21 0 20.1843 0 19.1781V1.82193C0 0.815691 0.962675 0 2.15023 0H26.8498C28.0373 0 29 0.815691 29 1.82193V19.1781C29 20.1843 28.0373 21 26.8498 21Z"
        fill="#EFDCFB"
      />
      <path
        d="M25.1464 19H3.85364C2.82993 19 2 18.3397 2 17.5251V3.47489C2 2.66035 2.82993 2 3.85364 2H25.1464C26.1701 2 27 2.66035 27 3.47489V17.5251C27 18.3397 26.1701 19 25.1464 19Z"
        fill="#2BB9F9"
      />
      <path
        d="M15.9868 19.3401H3.86324C2.83975 19.3401 2.00977 18.6558 2.00977 17.8113V15.4236L5.08574 12.3385C6.10087 11.3195 7.99041 11.3195 9.00627 12.3385L10.9215 14.2598L15.9868 19.3401Z"
        fill="#CDEC7A"
      />
      <path
        d="M27.0002 13.9717V17.811C27.0002 18.6555 26.1702 19.3398 25.1467 19.3398H5.8562L10.9215 14.2595L17.1442 8.01775C18.1593 6.99929 20.0496 6.99929 21.0647 8.01775L27.0002 13.9717Z"
        fill="#B0E995"
      />
      <path
        d="M7.35031 7.57708C8.54776 7.57708 9.51848 6.60635 9.51848 5.4089C9.51848 4.21145 8.54776 3.24072 7.35031 3.24072C6.15286 3.24072 5.18213 4.21145 5.18213 5.4089C5.18213 6.60635 6.15286 7.57708 7.35031 7.57708Z"
        fill="#FFCD3E"
      />
    </svg>
  );
};

export default PaintingHorizontal;
