import * as React from 'react';

function HandUp(props) {
  return (
    <svg width={31} height={35} viewBox="0 0 31 35" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.457 1.025a1.025 1.025 0 012.05 0v2.12a1.025 1.025 0 01-2.05 0v-2.12zm11.52
        20.645a3.065 3.065 0 00.785-2.05 3.08 3.08 0 00-3.076-3.077h-5.971c.591-1.794.844-4.175.844-5.196v-1.025a4.107
        4.107 0 00-4.102-4.101h-1.025c-.47 0-.88.32-.995.776l-.559 2.235c-.782 3.13-3.267 6.582-5.839
        7.21a3.08 3.08 0 00-2.861-1.95H1.025c-.566 0-1.025.46-1.025 1.026v18.457C0 34.54.46 35 1.025
        35h6.153a3.08 3.08 0 002.776-1.753l3.524 1.174c1.152.384 2.352.579 3.567.579h8.59a3.08 3.08 0
        002.86-4.21 3.082 3.082 0 002.267-2.968c0-.787-.298-1.506-.786-2.05a3.065 3.065 0
        00.786-2.051c0-.787-.298-1.506-.786-2.051zM8.202 31.924c0 .565-.46 1.025-1.025
        1.025H2.05V16.543h5.127c.565 0 1.025.46 1.025 1.025v14.356zm17.432-9.229h2.05a1.027 1.027 0
        010 2.051h-2.05a1.026 1.026 0 000 2.05h2.05a1.027 1.027 0 010 2.052h-2.05a1.026 1.026 0 000
        2.05 1.027 1.027 0 010 2.05h-8.59a9.21 9.21 0 01-2.92-.473l-3.871-1.29V18.497c1.608-.306
        3.183-1.324 4.604-2.992 1.388-1.628 2.513-3.787 3.01-5.775l.364-1.459h.225c1.13 0 2.05.92
        2.05 2.051v1.025c0 1.377-.43 4.018-.98 5.196h-2.095a1.026 1.026 0 000 2.05h10.254a1.027
        1.027 0 010 2.052h-2.051a1.026 1.026 0 000 2.05zM5.127 30.898a1.025 1.025 0 100-2.05 1.025
        1.025 0 000 2.05zM15.13 4.522l-1.45-1.45a1.025 1.025 0 10-1.45 1.45l1.45 1.45a1.025 1.025 0
        001.45-1.45zm10.152-1.45a1.025 1.025 0 111.45 1.45l-1.45 1.45a1.025 1.025 0 11-1.45-1.45l1.45-1.45z"
        fill="#fff"
      />
    </svg>
  );
}

export default HandUp;
