export const initialOrientationList = [
  {
    id: '1',
    key: 'vertical',
    label: 'Vertical',
  },
  {
    id: '2',
    key: 'horizontal',
    label: 'Horizontal',
  },
  {
    id: '3',
    key: 'square',
    label: 'Square',
  },
];
