export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING';

export const GET_USER_ORDERS_SUCCESS = 'GET_USER_ORDERS_SUCCESS';

export const GET_USER_OFFERS_SUCCESS = 'GET_USER_OFFERS_SUCCESS';

export const PUT_ORDERS_INVOICE_SUCCESS = 'PUT_ORDERS_INVOICE_SUCCESS';

export const SET_ORDERS_LOADING_TRUE = 'SET_ORDERS_LOADING_TRUE';

export const SET_ORDERS_LOADING_FALSE = 'SET_ORDERS_LOADING_FALSE';

export const SET_AUCTION_MODAL = 'SET_AUCTION_MODAL';

export const SET_CURRENT_OFFER = 'SET_CURRENT_OFFER';

export const SET_OFFER_PAGINATION = 'SET_OFFER_PAGINATION';

// ========================================= //

export const FETCH_ORDERS = 'FETCH_ORDERS';

export const FETCH_OFFERS = 'FETCH_OFFERS';

export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';

export const FETCH_OUT_COMING_ORDERS_SUCCESS = 'FETCH_OUT_COMING_ORDERS_SUCCESS';

export const FETCH_IN_COMING_ORDERS_SUCCESS = 'FETCH_IN_COMING_ORDERS_SUCCESS';

export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';

export const FETCH_ORDERS_FAILED = 'FETCH_ORDERS_FAILED';

export const FETCH_OFFERS_FAILED = 'FETCH_OFFERS_FAILED';

export const GET_SHIPMENT_TRACK_SUCCESS = 'GET_SHIPMENT_TRACK_SUCCESS';

export const CLEAR_SHIPMENT_TRACK_SUCCESS = 'CLEAR_SHIPMENT_TRACK_SUCCESS';
