export const SET_MASTER_FEEDBACK_LOADING = 'SET_MASTER_FEEDBACK_LOADING';

export const GET_MASTER_FEEDBACK_SUCCESS = 'GET_MASTER_FEEDBACK_SUCCESS';

export const SET_MASTER_FEEDBACK_DATE_FILTER = 'SET_MASTER_FEEDBACK_DATE_FILTER';

export const SET_MASTER_FEEDBACK_FILTER = 'SET_MASTER_FEEDBACK_FILTER';

export const SET_MASTER_FEEDBACK_PAGE = 'SET_MASTER_FEEDBACK_PAGE';

export const SET_MASTER_FEEDBACK_OPTIONS_LOADING = 'SET_MASTER_FEEDBACK_OPTIONS_LOADING';

export const GET_MASTER_FEEDBACK_OPTIONS_SUCCESS = 'GET_MASTER_FEEDBACK_OPTIONS_SUCCESS';
