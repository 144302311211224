import * as React from 'react';

function Hashtag(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M12.05 19.091a.68.68 0 01-.658-.86l4.544-16.817a.678.678 0
        01.837-.48.68.68 0 01.48.835l-4.546 16.818a.682.682 0
        01-.658.504zM3.416 19.091a.68.68 0 01-.658-.86L7.304
        1.415a.681.681 0 111.316.355L4.075 18.587a.682.682 0 01-.659.504z"
      />
      <path
        d="M17.5 14.547H.682a.682.682 0 010-1.364H17.5a.682.682 0
        010 1.364zM19.297 6.818H2.479a.682.682 0
        010-1.364h16.818a.682.682 0 010 1.364z"
      />
    </svg>
  );
}

export default Hashtag;
