import PropTypes from 'prop-types';
import React from 'react';

function Sofort({ height, width }) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 2959.853 536.667"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#f37116">
        <path d="M818.21 8.019H520.04c-47.02 0-92.708 0-133.599 20.756-40.923 20.786-77.047 62.358-104.88 145.444-5.98 17.854-9.179 33.281-9.623 46.606-.414 13.443 1.926 24.842 6.988 34.732 6.278 12.051 16.404 23.451 29.107 33.015 12.673 9.593 27.892 17.381 44.237 22.385.622.148 9.238 2.635 19.66 5.596a2875.777 2875.777 0 0130.232 8.883c4.502 1.392 9.446 3.168 13.947 5.863 4.501 2.694 8.528 6.248 11.073 11.193 1.659 3.316 2.547 6.899 2.636 10.748.119 3.879-.533 8.055-2.072 12.465-3.79 11.282-9.594 18.566-20.609 23.007-10.985 4.441-27.21 5.864-51.788 6.04H48.531L0 528.027h328.433c30.912 0 87.408 0 141.742-23.066 54.305-23.066 106.36-69.198 128.418-161.433 8.291-34.673 5.744-62.95-7.845-85.128-13.65-22.179-38.287-38.256-74.173-48.56 0 0-8.024-2.34-17.5-5.035-9.386-2.724-20.252-5.833-25.82-7.432-9.179-2.605-16.256-7.936-20.431-14.685-4.145-6.752-5.388-14.984-3.02-23.363 2.457-8.705 8.41-15.664 17.027-20.461 8.645-4.767 19.985-7.403 33.221-7.403h190.895c1.362-1.924 2.605-4.025 3.938-6.01 34.851-49.3 77.075-89.244 123.326-117.432" />
        <path d="M818.373 7.439H520.201c-47.02 0-92.678 0-133.598 20.756-40.923 20.786-77.016 62.329-104.851 145.444-6.01 17.854-9.208 33.252-9.623 46.605-.413 13.413 1.924 24.813 6.988 34.703 6.248 12.081 16.375 23.481 29.078 33.045 12.702 9.564 27.892 17.38 44.266 22.356.622.176 9.239 2.635 19.662 5.624 10.362 2.992 22.532 6.545 30.232 8.855 4.47 1.42 9.445 3.197 13.945 5.861 4.472 2.696 8.499 6.277 11.044 11.223 1.659 3.286 2.547 6.87 2.636 10.749.119 3.879-.533 8.053-2.072 12.436-3.79 11.312-9.564 18.565-20.61 23.036-10.985 4.443-27.182 5.863-51.757 6.041H48.693L.193 527.447h328.432c30.914 0 87.408 0 141.712-23.067 54.306-23.065 106.359-69.197 128.419-161.463 8.321-34.642 5.744-62.92-7.845-85.098-13.651-22.207-38.287-38.286-74.144-48.56 0 0-8.054-2.339-17.499-5.063-9.388-2.695-20.284-5.804-25.85-7.403-9.18-2.605-16.226-7.936-20.431-14.716-4.115-6.75-5.388-14.953-3.02-23.333 2.488-8.704 8.409-15.663 17.026-20.46 8.646-4.767 19.986-7.403 33.222-7.403h190.924c1.362-1.953 2.576-4.056 3.938-6.01C729.928 75.54 772.152 35.627 818.373 7.439M2441.547 8.324c10.213 8.053 18.946 17.261 26.266 27.744 18.707 26.975 25.88 59.013 21.587 95.699h95.76L2440.84 528.45h144.2l144.4-396.684h185.28l44.973-123.443h-518.146" />
        <path d="M2294.4 174.197c-15.453 41.603-43.173 67.304-95.907 67.304h-.24l-27.266-.06 46.666-125.753h26.534c48.533 0 66.653 14.213 50.213 58.51m139.973-114.886c-23.746-34.14-72.013-50.9-147.64-50.988h-175.08L1922.32 528.332h160.813l68.88-183.019c3.307 7.522 43.934 183.019 43.934 183.019h169.106s-30.76-128.95-53.386-181.035c-5.8-13.413-11.88-28.425-19.987-40.95 47.76-17.736 116.987-55.874 145.627-133.067 17.64-47.464 16.6-85.81-2.934-113.968M958.265 265.619C913.703 387.61 881.34 418.316 832.66 418.316h-.09c-47.403-.059-64.045-30.053-19.689-151.455 33.163-90.634 76.067-148.581 128.032-148.581h.031c49.359.059 60.67 28.661 17.321 147.339M1111.17 56.72C1085.203 19.56 1040.7.67 978.844.61c-40.448 0-79.77 9.297-116.573 26.678-52.707 24.903-100.438 66.533-139.434 121.697-25.345 35.858-47.02 77.371-64.016 123.828-31.98 87.616-31.624 158.768 1.124 205.611 18.536 26.532 57.088 58.184 135.526 58.244h.296c142.866 0 249.522-93.656 317.061-278.304 17.827-48.885 41.365-140.055-1.659-201.644M1682.067 8.019h-303.88c-84.958 0-148.203 42.993-178.05 123.442L1055.79 528.027h158.087l74.173-203.864 139.751.118 39.747-109.201-139.29-.088 22.41-63.217c4.72-11.311 15.853-20.314 32.226-20.314 9.12 0 111.654 0 164.774.03 1.36-1.954 2.573-4.055 3.933-6.04 34.853-49.3 84.213-89.244 130.467-117.432M1820.28 265.619c-44.56 121.992-76.92 152.697-125.573 152.697h-.12c-47.44-.059-63.987-30.053-19.627-151.455 33.107-90.634 76.067-148.581 127.947-148.581h.08c49.36.059 60.68 28.661 17.293 147.339M1973.213 56.72C1947.227 19.56 1902.773.67 1840.827.61c-40.414 0-79.707 9.297-116.534 26.678-52.68 24.903-100.413 66.533-139.413 121.697-25.347 35.858-47.013 77.371-63.987 123.828-32.066 87.616-31.653 158.768 1.094 205.611 18.546 26.532 57.093 58.184 135.56 58.244h.266c142.84 0 249.52-93.656 317-278.304 17.894-48.885 41.4-140.055-1.6-201.644" />
        <path d="M2441.733 7.743c10.187 8.054 18.96 17.233 26.267 27.745 18.72 26.945 25.853 59.012 21.587 95.699h95.76l-144.32 396.682h144.2L2729.6 131.187h185.307l44.946-123.444h-518.12" />
        <path d="M2294.56 173.617c-15.427 41.572-43.173 67.274-95.907 67.274h-.213l-27.267-.059 46.64-125.724h26.56c48.52 0 66.654 14.184 50.187 58.51M2434.52 58.73c-23.72-34.14-71.973-50.899-147.6-50.988h-175.08L1922.48 527.75h160.84l68.84-183.018c3.347 7.52 43.947 183.018 43.947 183.018h169.133s-30.8-128.951-53.387-181.063c-5.813-13.385-11.88-28.396-19.986-40.95 47.76-17.737 116.96-55.846 145.613-133.038 17.627-47.465 16.587-85.81-2.96-113.97M958.43 265.037c-44.562 121.994-76.926 152.67-125.605 152.67h-.089c-47.404-.03-64.045-30.055-19.66-151.455 33.133-90.636 76.037-148.583 128.003-148.583h.03c49.39.06 60.7 28.692 17.322 147.368M1111.365 56.14C1085.368 18.98 1040.893.09 979.01 0c-40.477 0-79.769 9.327-116.573 26.679-52.707 24.93-100.437 66.533-139.433 121.726-25.316 35.828-47.02 77.37-63.988 123.799-32.008 87.645-31.652 158.798 1.096 205.64 18.565 26.53 57.117 58.154 135.525 58.242h.296c142.867 0 249.523-93.655 317.061-278.333 17.827-48.885 41.396-140.054-1.628-201.613M1682.253 7.439h-303.88c-84.986 0-148.233 42.992-178.08 123.442l-144.348 396.566h158.116l74.174-203.864 139.725.089 39.733-109.172-139.277-.09 22.437-63.217c4.68-11.34 15.84-20.341 32.187-20.341 9.12 0 111.693.03 164.813.03 1.334-1.925 2.547-4.057 3.907-6.011C1586.64 75.54 1636 35.627 1682.253 7.439M1820.453 265.037c-44.56 121.994-76.893 152.67-125.546 152.67h-.12c-47.467-.03-64.014-30.055-19.694-151.455 33.134-90.636 76.067-148.583 127.974-148.583h.093c49.36.06 60.667 28.692 17.293 147.368M1973.387 56.14c-26-37.16-70.44-56.05-132.36-56.14-40.44 0-79.734 9.327-116.547 26.679-52.707 24.93-100.427 66.533-139.427 121.726-25.346 35.828-46.986 77.37-63.986 123.799-32.04 87.645-31.654 158.798 1.12 205.64 18.506 26.53 57.066 58.154 135.56 58.242h.24c142.866 0 249.546-93.655 317-278.333 17.906-48.885 41.426-140.054-1.6-201.613" />
      </g>
    </svg>
  );
}

Sofort.defaultProps = {
  height: undefined,
  width: undefined,
};

Sofort.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Sofort;
