import * as React from 'react';

const PaintingMedium = props => {
  return (
    <svg
      width="33"
      height="28"
      viewBox="0 0 33 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.3">
        <path
          d="M30.3337 28H2.42924C1.08759 28 0 26.9124 0 25.5708V2.42924C0 1.08759 1.08759 0 2.42924 0H30.3337C31.6753 0 32.7629 1.08759 32.7629 2.42924V25.5708C32.7629 26.9124 31.6753 28 30.3337 28Z"
          fill="#EFDCFB"
        />
        <path
          d="M28.0579 25.7178H4.70308C3.58022 25.7178 2.66992 24.8075 2.66992 23.6846V4.31612C2.66992 3.19326 3.58022 2.28296 4.70308 2.28296H28.0579C29.1808 2.28296 30.0911 3.19326 30.0911 4.31612V23.6846C30.0911 24.8075 29.1808 25.7178 28.0579 25.7178Z"
          fill="#2BB9F9"
        />
        <path
          d="M18.0102 25.7182H4.70715C3.58407 25.7182 2.67334 24.8082 2.67334 23.6852V20.5101L6.04858 16.4075C7.16248 15.0523 9.23586 15.0523 10.3505 16.4075L12.4521 18.9624L18.0102 25.7182Z"
          fill="#CDEC7A"
        />
        <path
          d="M30.095 18.5793V23.6848C30.095 24.8079 29.1842 25.7178 28.0612 25.7178H6.8938L12.452 18.9621L19.28 10.6618C20.3939 9.30741 22.4681 9.30741 23.582 10.6618L30.095 18.5793Z"
          fill="#B0E995"
        />
        <path
          d="M9.77387 10.0765C11.3662 10.0765 12.6571 8.78568 12.6571 7.1933C12.6571 5.60093 11.3662 4.31006 9.77387 4.31006C8.1815 4.31006 6.89062 5.60093 6.89062 7.1933C6.89062 8.78568 8.1815 10.0765 9.77387 10.0765Z"
          fill="#FFCD3E"
        />
      </g>
      <path
        d="M18.8086 28H1.50627C0.674366 28 0 27.3257 0 26.4938V12.1447C0 11.3128 0.674366 10.6384 1.50627 10.6384H18.8086C19.6405 10.6384 20.3149 11.3128 20.3149 12.1447V26.4938C20.3149 27.3257 19.6405 28 18.8086 28Z"
        fill="#EFDCFB"
      />
      <path
        d="M17.3966 26.5846H2.91522C2.21898 26.5846 1.65454 26.0202 1.65454 25.3239V13.3144C1.65454 12.6181 2.21898 12.0537 2.91522 12.0537H17.3966C18.0928 12.0537 18.6572 12.6181 18.6572 13.3144V25.3239C18.6572 26.0202 18.0928 26.5846 17.3966 26.5846Z"
        fill="#2BB9F9"
      />
      <path
        d="M11.1677 26.585H2.91903C2.22266 26.585 1.65796 26.0207 1.65796 25.3244V23.3557L3.7508 20.8118C4.44148 19.9716 5.72709 19.9716 6.41826 20.8118L7.72134 22.3961L11.1677 26.585Z"
        fill="#CDEC7A"
      />
      <path
        d="M18.6622 22.1586V25.3242C18.6622 26.0206 18.0975 26.5848 17.4011 26.5848H4.27612L7.7225 22.3959L11.9563 17.2492C12.647 16.4094 13.9331 16.4094 14.6237 17.2492L18.6622 22.1586Z"
        fill="#B0E995"
      />
      <path
        d="M6.06024 16.8863C7.0476 16.8863 7.84801 16.0859 7.84801 15.0986C7.84801 14.1112 7.0476 13.3108 6.06024 13.3108C5.07288 13.3108 4.27246 14.1112 4.27246 15.0986C4.27246 16.0859 5.07288 16.8863 6.06024 16.8863Z"
        fill="#FFCD3E"
      />
    </svg>
  );
};

export default PaintingMedium;
