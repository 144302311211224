export const COUNTRY_PLACEHOLDER = 'All counties';

export const STATUS_FORM = {
  title: 'Your Professional Status',
};

export const STATUS_FORM_LIST = [
  {
    value: 'false',
    label: 'Hobby Artist',
  },
  {
    value: 'true',
    label: 'Registered Artist',
  },
];
