import { OUTLINE_COLOR } from 'constants/colors';
import React from 'react';

function Icon({ fill = OUTLINE_COLOR }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6078_2814)">
        <path
          d="M30.125 16.0825L25.3833 13.3826L20.2288 16.9348L12.219 11.4873L1.875 17.3501V18.8125H0V20.6875H32V18.8125H30.125V16.0825Z"
          fill={fill}
        />
        <path
          d="M19.75 9.4375C20.2673 9.4375 20.6875 9.01733 20.6875 8.5C20.6875 7.98267 20.2673 7.5625 19.75 7.5625C19.2327 7.5625 18.8125 7.98267 18.8125 8.5C18.8125 9.01733 19.2327 9.4375 19.75 9.4375Z"
          fill={fill}
        />
        <path
          d="M15.0625 0V1.875H1.875V15.1848L12.282 9.2627L20.2214 14.6624L25.3667 11.1174L30.125 13.8828V1.875H16.9375V0H15.0625ZM19.75 5.6875C21.3008 5.6875 22.5625 6.94922 22.5625 8.5C22.5625 10.0508 21.3008 11.3125 19.75 11.3125C18.1992 11.3125 16.9375 10.0508 16.9375 8.5C16.9375 6.94922 18.1992 5.6875 19.75 5.6875Z"
          fill={fill}
        />
        <path
          d="M11.0962 22.5625L7.88208 30.125H5.6875V32H11.3125V30.125H9.92139L11.5283 26.3125H15.0625V30.125H13.1875V32H18.8125V30.125H16.9375V26.3125H20.4717L22.0786 30.125H20.6875V32H26.3125V30.125H24.1179L20.9038 22.5625H18.8645L19.668 24.4375H16.9375V22.5625H15.0625V24.4375H12.332L13.1355 22.5625H11.0962Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_6078_2814">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
