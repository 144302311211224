export const SET_PUBLIC_ARTWORK_LOADING = 'SET_PUBLIC_ARTWORK_LOADING';

export const SET_PUBLIC_ARTWORK_SHIPPING_LOADING =
  'SET_PUBLIC_ARTWORK_SHIPPING_LOADING';

export const SET_PUBLIC_ARTWORK_SHIPPING_RATES =
  'SET_PUBLIC_ARTWORK_SHIPPING_RATES';

export const ADD_PUBLIC_ARTWORK_TO_CART = 'ADD_PUBLIC_ARTWORK_TO_CART';

export const CHANGE_PUBLIC_ARTWORK_STATUS = 'CHANGE_PUBLIC_ARTWORK_STATUS';

export const CHANGE_PUBLIC_ARTWORK_FOR_SALE_STATUS =
  'CHANGE_PUBLIC_ARTWORK_FOR_SALE_STATUS';

export const GET_PUBLIC_ARTWORK_STYLES = 'GET_PUBLIC_ARTWORK_STYLES';

export const GET_PUBLIC_ARTWORK_STYLES_PAGINATION =
  'GET_PUBLIC_ARTWORK_STYLES_PAGINATION';

export const GET_PUBLIC_ARTWORK_MEDIUM = 'GET_PUBLIC_ARTWORK_MEDIUM';

export const GET_PUBLIC_ARTWORK_SURFACE = 'GET_PUBLIC_ARTWORK_SURFACE';

export const GET_PUBLIC_ARTWORK_DATA = 'GET_PUBLIC_ARTWORK_DATA';

export const GET_PUBLIC_ARTWORK_DATA_ERROR = 'GET_PUBLIC_ARTWORK_DATA_ERROR';

export const SET_PUBLIC_ARTWORK_HASHTAG = 'SET_PUBLIC_ARTWORK_HASHTAG';

export const SET_PUBLIC_ARTWORK_GALLARY = 'SET_PUBLIC_ARTWORK_GALLARY';

export const UPDATE_PUBLIC_ARTWORK_HASHTAGS = 'UPDATE_PUBLIC_ARTWORK_HASHTAGS';

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

export const GET_PUBLIC_ARTWORK_RATES = 'GET_PUBLIC_ARTWORK_RATES';

export const SET_PUBLIC_ARTWORK_RATES = 'SET_PUBLIC_ARTWORK_RATES';

export const SET_REQUIRED_SALE_INFO_MODAL_OPEN =
  'SET_REQUIRED_SALE_INFO_MODAL_OPEN';

export const SET_REQUIRED_SALE_INFO_MODAL_FORMS =
  'SET_REQUIRED_SALE_INFO_MODAL_FORMS';

export const SET_ARTWORK_PAGE_UNLOGGED_MODAL =
  'SET_ARTWORK_PAGE_UNLOGGED_MODAL';

export const UPDATE_ARTWORKS_COUNT = 'UPDATE_ARTWORKS_COUNT';

export const SET_OFFER_MODAL = 'SET_OFFER_MODAL';

export const SET_DELETE_MODAL = 'SET_DELETE_MODAL';

export const SET_EDIT_MODE = 'SET_EDIT_MODE';

export const SET_GALLERY_MODAL = 'SET_GALLERY_MODAL';

export const SET_OFFER_CHECKOUT = 'SET_OFFER_CHECKOUT';

export const SET_SWITCH_ROLE_MODAL = 'SET_SWITCH_ROLE_MODAL';
