import * as React from 'react';

const PaintingLarge = props => {
  return (
    <svg
      width="33"
      height="28"
      viewBox="0 0 33 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.3">
        <path
          d="M30.3337 28H2.42924C1.08759 28 0 26.9124 0 25.5708V2.42924C0 1.08759 1.08759 0 2.42924 0H30.3337C31.6753 0 32.7629 1.08759 32.7629 2.42924V25.5708C32.7629 26.9124 31.6753 28 30.3337 28Z"
          fill="#EFDCFB"
        />
        <path
          d="M28.0579 25.7178H4.70308C3.58022 25.7178 2.66992 24.8075 2.66992 23.6846V4.31612C2.66992 3.19326 3.58022 2.28296 4.70308 2.28296H28.0579C29.1808 2.28296 30.0911 3.19326 30.0911 4.31612V23.6846C30.0911 24.8075 29.1808 25.7178 28.0579 25.7178Z"
          fill="#2BB9F9"
        />
        <path
          d="M18.0102 25.7182H4.70715C3.58407 25.7182 2.67334 24.8082 2.67334 23.6852V20.5101L6.04858 16.4075C7.16248 15.0523 9.23586 15.0523 10.3505 16.4075L12.4521 18.9624L18.0102 25.7182Z"
          fill="#CDEC7A"
        />
        <path
          d="M30.095 18.5793V23.6848C30.095 24.8079 29.1842 25.7178 28.0612 25.7178H6.8938L12.452 18.9621L19.28 10.6618C20.3939 9.30741 22.4681 9.30741 23.582 10.6618L30.095 18.5793Z"
          fill="#B0E995"
        />
        <path
          d="M9.77387 10.0765C11.3662 10.0765 12.6571 8.78568 12.6571 7.1933C12.6571 5.60093 11.3662 4.31006 9.77387 4.31006C8.1815 4.31006 6.89062 5.60093 6.89062 7.1933C6.89062 8.78568 8.1815 10.0765 9.77387 10.0765Z"
          fill="#FFCD3E"
        />
      </g>
      <path
        d="M23.1464 28.0004H1.85365C0.829892 28.0004 0 27.1705 0 26.1467V8.48842C0 7.46466 0.829892 6.63477 1.85365 6.63477H23.1464C24.1701 6.63477 25 7.46466 25 8.48842V26.1467C25 27.1705 24.1701 28.0004 23.1464 28.0004Z"
        fill="#EFDCFB"
      />
      <path
        d="M21.4084 26.2581H3.58731C2.7305 26.2581 2.03589 25.5635 2.03589 24.7067V9.9274C2.03589 9.07059 2.7305 8.37598 3.58731 8.37598H21.4084C22.2652 8.37598 22.9598 9.07059 22.9598 9.9274V24.7067C22.9598 25.5635 22.2652 26.2581 21.4084 26.2581Z"
        fill="#2BB9F9"
      />
      <path
        d="M13.7432 26.2603H3.59219C2.73522 26.2603 2.04028 25.5659 2.04028 24.709V22.2862L4.61579 19.1557C5.46576 18.1216 7.04786 18.1216 7.89844 19.1557L9.50204 21.1053L13.7432 26.2603Z"
        fill="#CDEC7A)"
      />
      <path
        d="M22.9648 20.8127V24.7085C22.9648 25.5655 22.2699 26.2598 21.4129 26.2598H5.26099L9.50218 21.1048L14.7124 14.7712C15.5624 13.7377 17.1451 13.7377 17.995 14.7712L22.9648 20.8127Z"
        fill="#B0E995"
      />
      <path
        d="M7.45912 14.3228C8.67419 14.3228 9.6592 13.3378 9.6592 12.1227C9.6592 10.9076 8.67419 9.92261 7.45912 9.92261C6.24404 9.92261 5.25903 10.9076 5.25903 12.1227C5.25903 13.3378 6.24404 14.3228 7.45912 14.3228Z"
        fill="#FFCD3E"
      />
    </svg>
  );
};

export default PaintingLarge;
