import React from 'react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="25"
      fill="none"
      viewBox="0 0 31 25"
      {...props}
    >
      <path
        fill="currentColor"
        d="M7.762 14.745c0 .32.26.581.576.581a.58.58 0 00.577-.581v-2.326c0-.966.77-1.744 1.729-1.744.958 0 1.73.778 1.73 1.744v1.163c0 .32.259.582.576.582a.58.58 0 00.576-.582V8.35H7.762v6.396z"
      ></path>
      <path
        fill="currentColor"
        d="M17.561 9.512h2.883V7.717c0-.327-.152-.633-.411-.829L10.989.114a.58.58 0 00-.691 0L1.254 6.888c-.259.196-.41.502-.41.829v10.262c0 .458.367.835.828.835h16.38a.54.54 0 00.525-.646L17 10.209a.549.549 0 01.123-.48.544.544 0 01.44-.217zm-2.882 4.07c0 .923-.706 1.679-1.607 1.737-1.016.072-1.852-.756-1.852-1.788v-1.098a.594.594 0 00-.54-.603.58.58 0 00-.613.582v2.282c0 1.032-.836 1.86-1.852 1.787a1.729 1.729 0 01-1.607-1.737V7.768c0-.32.26-.581.577-.581h6.917a.58.58 0 01.577.581v5.815zM23.328 23.837c0 .64.519 1.163 1.153 1.163s1.153-.523 1.153-1.163v-4.215h-2.306v4.215z"
      ></path>
      <path
        fill="currentColor"
        d="M18.399 11.329l1.398 7.05c.05.254.274.436.533.436h8.316c.259 0 .482-.182.533-.436l1.398-7.05a.548.548 0 00-.534-.654H18.925a.543.543 0 00-.526.654z"
      ></path>
    </svg>
  );
}

export default Icon;
