/* eslint-disable max-len */
import * as React from 'react';

function ProfileInfo(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M15.77 15.415c2.118 0 3.951-.76 5.45-2.258 1.498-1.499 2.258-3.332
        2.258-5.45 0-2.117-.76-3.95-2.259-5.45C19.721.76 17.887 0 15.77 0c-2.118 0-3.951.76-5.45
        2.258C8.823 3.756 8.063 5.59 8.063 7.707c0 2.118.76 3.951 2.259 5.45 1.498 1.498 3.332 2.258 5.449
        2.258zM11.647 3.584c1.15-1.15 2.498-1.709 4.123-1.709s2.974.56 4.123 1.709c1.15 1.15 1.71 2.499 1.71
        4.123 0 1.625-.56 2.974-1.71 4.124-1.15 1.15-2.498 1.708-4.123 1.708-1.624
        0-2.973-.559-4.123-1.708-1.15-1.15-1.71-2.499-1.71-4.124 0-1.624.56-2.973 1.71-4.123zM29.258
        24.606a19.024 19.024 0 00-.26-2.022 15.931 15.931 0 00-.497-2.033 10.037 10.037 0 00-.835-1.896
        7.148 7.148 0 00-1.26-1.642 5.556 5.556 0 00-1.81-1.138 6.257 6.257 0 00-2.312-.418c-.326
        0-.642.134-1.252.531-.376.245-.815.529-1.305.842-.42.267-.988.517-1.689.744a6.704 6.704 0 01-2.065.333c-.686
        0-1.38-.112-2.065-.333-.701-.227-1.27-.477-1.688-.744-.485-.31-.925-.593-1.306-.842-.61-.397-.925-.531-1.252-.531-.832
        0-1.61.14-2.31.419a5.553 5.553 0 00-1.811 1.137 7.147 7.147 0 00-1.26 1.642 10.06 10.06 0 00-.836
        1.896c-.2.626-.367 1.31-.497 2.033a18.911 18.911 0 00-.259 2.023 27.386 27.386 0 00-.064 1.89c0
        1.67.531 3.022 1.578 4.02C5.237 31.5 6.605 32 8.27 32h15.408c1.664 0 3.032-.5 4.066-1.483
        1.048-.997 1.579-2.35 1.579-4.02 0-.645-.022-1.281-.065-1.89zm-2.807
        4.552c-.683.65-1.59.966-2.773.966H8.27c-1.184 0-2.09-.316-2.774-.966-.67-.638-.996-1.509-.996-2.661
        0-.6.02-1.192.06-1.76.038-.558.117-1.17.234-1.821.115-.643.262-1.247.437-1.793a8.19 8.19 0
        01.68-1.542 5.3 5.3 0 01.926-1.213c.322-.308.727-.56 1.205-.749.441-.175.938-.27
        1.477-.285.065.035.182.102.372.225.385.252.83.538 1.32.852.554.353 1.268.672
        2.12.947.871.282 1.76.425 2.642.425.882 0 1.771-.143 2.642-.425.853-.276 1.566-.594
        2.12-.948.503-.32.935-.6 1.32-.85.19-.124.307-.19.373-.226.539.015 1.035.11
        1.477.285.477.19.883.441 1.204.749.344.329.655.737.926 1.214.284.499.513 1.018.68 1.541.176.547.323 1.15.438
        1.792.117.652.196 1.265.234 1.822.04.566.06 1.158.06 1.76 0 1.153-.325 2.023-.996 2.66z"
        fill={props.fill || '#3F4041'}
      />
    </svg>
  );
}

export default ProfileInfo;
