/* eslint-disable max-len */
import React from 'react';
import styles from './icons.module.scss';

export const Artwork = ({ className = '', fill = '#c7c7c7', ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="26"
    fill="none"
    viewBox="0 0 23 26"
    className={className ? `${styles.svg} ${className}` : styles.svg}
    {...props}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M.762 3.047A.761.761 0 000 3.809V13.8c.539 0 1.082.149 1.565.47a1.295 1.295 0 001.44 0 2.813 2.813 0 013.115-.007c.03-.603.149-1.18.363-1.714l-2.035-1.175a.762.762 0 11.762-1.32l2.049 1.183c.26-.328.553-.622.881-.881l-1.183-2.05a.762.762 0 111.32-.76L9.452 9.58c.383-.153.791-.25 1.212-.311V6.906a.761.761 0 111.524 0V9.27c.42.06.829.158 1.212.311l1.175-2.034a.762.762 0 111.32.762l-1.184 2.049c.329.259.623.553.882.881l2.049-1.183a.763.763 0 01.762 1.32l-2.035 1.174c.214.534.333 1.112.363 1.715a2.813 2.813 0 013.115.007 1.295 1.295 0 001.44 0 2.81 2.81 0 011.565-.463v-10a.761.761 0 00-.762-.761h-6.564l-.19-1.524h.66a.761.761 0 100-1.523h-9.14a.761.761 0 100 1.523h.66l-.19 1.524H.762zm8.29-1.524H13.8l.191 1.524h-5.13l.19-1.524zM0 19.145c0 .42.34.762.762.762h4.47L5.04 21.43H3.809a.761.761 0 100 1.523H4.85l-.274 2.191a.762.762 0 001.512.19l.297-2.38h10.082l.297 2.38a.762.762 0 101.512-.19l-.274-2.19h1.041a.761.761 0 100-1.524h-1.232l-.19-1.523h4.47c.42 0 .761-.341.761-.762v-3.797c-.254 0-.505.047-.72.19-.95.634-2.18.634-3.13 0a1.295 1.295 0 00-1.44 0 2.84 2.84 0 01-3.13 0 1.295 1.295 0 00-1.44 0c-.952.634-2.18.634-3.131 0a1.295 1.295 0 00-1.44 0c-.883.589-2.166.643-3.13 0a1.295 1.295 0 00-1.44 0c-.951.634-2.18.634-3.13 0a1.278 1.278 0 00-.721-.2v3.807zm16.086.762l.19 1.523h-9.7l.19-1.523h9.32zm-8.441-5.67c.149-1.964 1.777-3.522 3.78-3.522 2.001 0 3.63 1.558 3.779 3.523a2.808 2.808 0 00-3.06.033 1.295 1.295 0 01-1.44 0 2.808 2.808 0 00-3.06-.034z"
      clipRule="evenodd"
    />
  </svg>
);
