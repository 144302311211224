import React from 'react';

export default function GooglePayBW(properties) {
  const { param: parameter } = properties;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={parameter} width={parameter}>
      <path
        fill="#8a8a8a"
        d="M8.079 11.7c0-.281-.023-.562-.071-.838H4.121v1.587h2.226a1.905 1.905 0 01-.823 1.252v1.031h1.329c.778-.716 1.226-1.777 1.226-3.032z"
      />
      <path
        fill="#a9a9a9"
        d="M4.121 15.728c1.113 0 2.05-.365 2.732-.995l-1.329-1.031c-.37.251-.846.394-1.404.394-1.075 0-1.987-.724-2.313-1.701H.439v1.062a4.12 4.12 0 003.682 2.271z"
      />
      <path
        fill="#FBBC04"
        d="M1.808 12.395a2.477 2.477 0 010-1.578V9.756H.439a4.118 4.118 0 000 3.702l1.369-1.063z"
      />
      <path
        fill="#8a8a8a"
        d="M4.121 9.117a2.24 2.24 0 011.581.618l1.177-1.176a3.964 3.964 0 00-2.758-1.074c-1.557 0-2.982.88-3.682 2.271l1.369 1.062c.326-.977 1.238-1.701 2.313-1.701z"
      />
      <path
        fill="#a9a9a9"
        d="M1.808 12.395L.439 13.457a4.123 4.123 0 003.682 2.271v-1.632c-1.075 0-1.987-.724-2.313-1.701z"
      />
      <path
        fill="#cccccc"
        d="M.439 9.756a4.118 4.118 0 000 3.702l1.369-1.062a2.477 2.477 0 010-1.578L.439 9.756z"
      />
      <path
        fill="#8a8a8a"
        d="M4.121 7.485c-1.557 0-2.982.88-3.682 2.271l1.369 1.062c.326-.977 1.239-1.701 2.313-1.701V7.485z"
      />
      <path
        fill="#8a8a8a"
        d="M10.917 11.497v2.904h-.922V7.227h2.444c.59-.012 1.16.211 1.583.621.429.386.67.938.661 1.514a1.984 1.984 0 01-.661 1.523c-.428.408-.955.612-1.583.61l-1.522.002zm0-3.387v2.505h1.545c.343.01.673-.125.912-.372.484-.47.495-1.245.024-1.729l-.024-.024a1.204 1.204 0 00-.912-.381h-1.545v.001zm5.89 1.222c.682 0 1.219.182 1.613.546.394.364.59.864.59 1.497v3.026h-.88v-.682h-.04c-.381.561-.89.841-1.523.841-.54 0-.993-.16-1.357-.481a1.534 1.534 0 01-.545-1.202c0-.508.192-.912.576-1.212.384-.3.896-.45 1.538-.45.547 0 .999.1 1.352.3v-.211a1.05 1.05 0 00-.381-.816 1.31 1.31 0 00-.89-.336c-.515 0-.922.218-1.222.651l-.811-.511c.444-.64 1.105-.96 1.98-.96zm-1.193 3.566a.739.739 0 00.305.601c.204.16.457.245.716.24a1.472 1.472 0 001.037-.431c.305-.288.458-.624.458-1.011-.287-.229-.688-.344-1.203-.344-.374 0-.687.09-.937.271-.25.184-.376.407-.376.674zm8.457-3.406l-3.077 7.073h-.951l1.142-2.475-2.024-4.598h1.001l1.462 3.526h.02l1.423-3.526h1.004z"
      />
      <path
        fill="#8a8a8a"
        d="M10.917 12.395h-.922V14.4h.922v-2.005zm8.094 0h-.883c-.024.346-.176.65-.455.913-.275.275-.648.43-1.037.431h-.022a1.13 1.13 0 01-.694-.24.736.736 0 01-.305-.601.75.75 0 01.189-.503h-1.073a1.691 1.691 0 00-.067.482c-.009.463.192.904.545 1.202.364.321.817.481 1.357.481.633 0 1.142-.28 1.523-.841h.04v.682h.881v-2.006h.001zm3.797 0h-.912l-.251.623h-.02l-.258-.623h-.928l.746 1.695-1.142 2.475h.951l1.814-4.17z"
      />
    </svg>
  )
}
