import * as React from 'react';

const PaintingVertical = props => {
  return (
    <svg
      width="24"
      height="33"
      viewBox="0 0 24 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.2205 33H1.7795C0.796697 33 0 31.9966 0 30.7588V2.24117C0 1.00339 0.796697 0 1.7795 0H22.2205C23.2033 0 24 1.00339 24 2.24117V30.7588C24 31.9966 23.2033 33 22.2205 33Z"
        fill="#EFDCFB"
      />
      <path
        d="M20.5171 31.0588H3.48291C2.66394 31.0588 2 29.9278 2 28.5326V4.46736C2 3.07221 2.66394 1.94116 3.48291 1.94116H20.5171C21.3361 1.94116 22 3.07221 22 4.46736V28.5326C22 29.9278 21.3361 31.0588 20.5171 31.0588Z"
        fill="#2BB9F9"
      />
      <path
        d="M13.1275 31.7496H3.43326C2.61485 31.7496 1.95117 31.1059 1.95117 30.3116V28.0659L4.4108 25.1642C5.22253 24.2057 6.73346 24.2057 7.54576 25.1642L9.07721 26.9713L13.1275 31.7496Z"
        fill="#CDEC7A"
      />
      <path
        d="M21.9332 26.7008V30.3119C21.9332 31.1062 21.2695 31.7498 20.4511 31.7498H5.02588L9.07626 26.9715L14.052 21.1008C14.8638 20.1428 16.3753 20.1428 17.187 21.1008L21.9332 26.7008Z"
        fill="#B0E995"
      />
      <path
        d="M7.12649 20.6687C8.28689 20.6687 9.22759 19.7557 9.22759 18.6294C9.22759 17.5031 8.28689 16.5901 7.12649 16.5901C5.96608 16.5901 5.02539 17.5031 5.02539 18.6294C5.02539 19.7557 5.96608 20.6687 7.12649 20.6687Z"
        fill="#FFCD3E"
      />
    </svg>
  );
};

export default PaintingVertical;
