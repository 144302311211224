const ACTION_TYPES = {
  SET_SHOW_RESULT: 'SEARCH/SET_SHOW_RESULT',
  SET_CURRENT_TAB: 'SEARCH/SET_CURRENT_TAB',
  SET_SEARCH_RESULT: 'SEARCH/SET_SEARCH_RESULT',
  SET_SEARCH_LOADING: 'SEARCH/SET_SEARCH_LOADING',
  CLEAR_SEARCH_RESULT: 'SEARCH/CLEAR_SEARCH_RESULT',
  CLEAR_SEARCH_URLS: 'SEARCH/CLEAR_SEARCH_URLS',
  SET_SAVED_URL: 'SEARCH/SET_SAVED_URL',
  ADD_TO_CART_SEARCH_RESULT: 'SEARCH/ADD_TO_CART_SEARCH_RESULT',
};

export default ACTION_TYPES;
