import React from 'react';

export default function PayPalIconColorDisabled(properties) {
  const { param: parameter } = properties;
  return (
    <svg fill="none" height={parameter} viewBox="0 0 39 45" width={parameter}>
      <g opacity={0.4}>
        <path
          d="M34.1 10.347a.937.937 0 00-1.406.707 10.34 10.34 0 01-.152.938c-1.835 8.33-5.522 12.382-11.272 12.382h-8.438a.937.937 0 00-.917.75L9.103 38.308 8.6 41.664A2.813 2.813 0 0011.351 45h5.644a2.8 2.8 0 002.728-2.136l2.28-9.114h4.892c5.773 0 9.763-4.604 11.235-12.962a9.799 9.799 0 00-4.03-10.44z"
          fill="#03A9F4"
        />
        <path
          d="M32.329 3.341a9.866 9.866 0 00-7.313-3.34H9.491a4.663 4.663 0 00-4.639 3.985L.032 36.145a2.813 2.813 0 002.784 3.23h7.2a.937.937 0 00.917-.75l2.653-12.375h7.688c6.671 0 11.08-4.663 13.104-13.875.082-.367.144-.738.188-1.112a9.68 9.68 0 00-2.237-7.922z"
          fill="#283593"
        />
      </g>
    </svg>
  );
}
