export const SET_NEWSLETTERS_LOADING = 'SET_NEWSLETTERS_LOADING';

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const SAVE_LETTER_SUCCESS = 'SAVE_LETTER_SUCCESS';

export const SAVE_LETTER_ERROR = 'SAVE_LETTER_ERROR';

export const SET_CHECKED_ARTWORKS_DATA = 'SET_CHECKED_ARTWORKS_DATA';

export const SET_CHECKED_ARTISTS_DATA = 'SET_CHECKED_ARTISTS_DATA';

export const SAVE_LETTER_DIALOG = 'SAVE_LETTER_DIALOG';

export const RESET_TEMPLATE = 'RESET_TEMPLATE';

export const RESET_SELECTED_ARTWORK_DATE = 'RESET_SELECTED_ARTWORK_DATE';

export const RESET_SELECTED_ARTIST_DATE = 'RESET_SELECTED_ARTIST_DATE';

// Weekly

export const GET_NEW_ARTWORKS_SUCCESS = 'GET_NEW_ARTWORKS_SUCCESS';

export const GET_NEW_ARTWORKS_ERROR = 'GET_NEW_ARTWORKS_ERROR';

export const GET_NEW_ARTISTS_SUCCESS = 'GET_NEW_ARTISTS_SUCCESS';

export const GET_NEW_ARTISTS_ERROR = 'GET_NEW_ARTISTS_ERROR';

export const SET_ARTISTS_START_DATE = 'SET_ARTISTS_START_DATE';

export const SET_ARTISTS_END_DATE = 'SET_ARTISTS_END_DATE';

export const SET_ARTWORKS_START_DATE = 'SET_ARTWORKS_START_DATE';

export const SET_ARTWORKS_END_DATE = 'SET_ARTWORKS_END_DATE';

export const SET_ARTWORKS_SELECTED_DATE = 'SET_ARTWORKS_SELECTED_DATE';

export const SET_ARTISTS_SELECTED_DATE = 'SET_ARTISTS_SELECTED_DATE';

// Auto

export const GET_AUTO_LETTERS_SUCCESS = 'GET_AUTO_LETTERS_SUCCESS';

export const GET_AUTO_LETTERS_ERROR = 'GET_AUTO_LETTERS_ERROR';

export const SET_AUTO_LETTER_TYPE = 'SET_AUTO_LETTER_TYPE';

// Custom

export const GET_RECIPIENTS_SUCCESS = 'GET_RECIPIENTS_SUCCESS';

export const GET_RECIPIENTS_ERROR = 'GET_RECIPIENTS_ERROR';

export const SET_RECIPIENTS_LIST = 'SET_RECIPIENTS_LIST';

export const SELECT_ALL_RECIPIENTS = 'SELECT_ALL_RECIPIENTS';

export const SET_RECIPIENTS_FILTER = 'SET_RECIPIENTS_FILTER';

export const RESET_RECIPIENTS_LIST = 'RESET_RECIPIENTS_LIST';

export const SET_RECIPIENTS_PAGE = 'SET_RECIPIENTS_PAGE';

// Subs

export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';

export const GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR';

export const SET_SUBSCRIPTIONS_FILTER = 'SET_SUBSCRIPTIONS_FILTER';

export const SET_SUBSCRIPTIONS_ORDER = 'SET_SUBSCRIPTIONS_ORDER';

// Stats

export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';

export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';

export const GET_STATS_ERROR = 'GET_STATS_ERROR';

export const SET_MAIL_FORM = 'SET_MAIL_FORM';

export const SET_PREV_TEMPLATE = 'SET_PREV_TEMPLATE';
