export const SET_SALES_DASHBOARD_LOADING = 'SET_SALES_DASHBOARD_LOADING';

export const SET_COLLECTORS_INCOMING_OFFERS_SUCCESS =
  'SET_COLLECTORS_INCOMING_OFFERS_SUCCESS';

export const SET_COLLECTORS_OUTCOMING_OFFERS_SUCCESS =
  'SET_COLLECTORS_OUTCOMING_OFFERS_SUCCESS';

export const FETCH_PAYOUTS_ORDERS = 'FETCH_PAYOUTS_ORDERS';

export const FETCH_PAYOUTS_ORDERS_SUCCESS = 'FETCH_PAYOUTS_ORDERS_SUCCESS';

export const SET_PAGE = 'SET_PAGE';

export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
