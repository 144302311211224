export const SET_HOMEPAGE_LOADING = 'SET_HOMEPAGE_LOADING';

export const GET_HOMEPAGE_DATA_ERROR = 'GET_HOMEPAGE_DATA_ERROR';

export const GET_HOMEPAGE_DATA_SUCCESS = 'GET_HOMEPAGE_DATA_SUCCESS';

export const SET_MORE_HOMEPAGE_STYLES_SUCCESS =
  'SET_MORE_HOMEPAGE_STYLES_SUCCESS';

export const SET_LESS_HOMEPAGE_STYLES_SUCCESS =
  'SET_LESS_HOMEPAGE_STYLES_SUCCESS';

export const SET_SLIDES_SUCCESS = 'SET_SLIDES_SUCCESS';

export const SET_HOMEPAGE_FILTER_ARTWORKS_SUCCESS =
  'SET_HOMEPAGE_FILTER_ARTWORKS_SUCCESS';

export const SET_MORE_HOMEPAGE_FILTER_ARTWORKS_SUCCESS =
  'SET_MORE_HOMEPAGE_FILTER_ARTWORKS_SUCCESS';
export const GET_HOMEPAGE_ARTWORKS_BY_STYLE_SUCCESS =
  'GET_HOMEPAGE_ARTWORKS_BY_STYLE_SUCCESS';

export const ADD_ARTWORK_TO_CART_IN_FILTER_ARTWORKS =
  'ADD_ARTWORK_TO_CART_IN_FILTER_ARTWORKS';

export const ADD_ARTWORK_TO_CART_IN_FILTER_ARTWORKS_BY_PRICE =
  'ADD_ARTWORK_TO_CART_IN_FILTER_ARTWORKS_BY_PRICE';

export const SET_FILTER_ARTWORKS_BY_PRICE_SUCCESS =
  'SET_FILTER_ARTWORKS_BY_PRICE_SUCCESS';

export const SET_LOADING_ARTWORKS_TRUE = 'SET_LOADING_ARTWORKS_TRUE';

export const GET_HOMEPAGE_ARTIST_BY_COUNTRY_SUCCESS =
  'GET_HOMEPAGE_ARTIST_BY_COUNTRY_SUCCESS';

export const GET_HOMEPAGE_ARTIST_BY_NAME_SUCCESS =
  'GET_HOMEPAGE_ARTIST_BY_NAME_SUCCESS';

export const GET_HOMEPAGE_ARTWORKS_BY_HASHTAG_SUCCESS =
  'GET_HOMEPAGE_ARTWORKS_BY_HASHTAG_SUCCESS';

export const GET_HOMEPAGE_BACKGROUND_SUCCESS =
  'GET_HOMEPAGE_BACKGROUND_SUCCESS';

export const SET_HOMEPAGE_ART_STYLE = 'SET_HOMEPAGE_ART_STYLE';

export const SET_HOMEPAGE_COUNTRY = 'SET_HOMEPAGE_COUNTRY';

export const SET_HOMEPAGE_ARTIST_NAME = 'SET_HOMEPAGE_ARTIST_NAME';

export const SET_HOMEPAGE_HASHTAG = 'SET_HOMEPAGE_HASHTAG';

export const GET_LAST_BLOG_SUCCESS = 'GET_LAST_BLOG_SUCCESS';

export const GET_SlIDER_BLOG_SUCCESS = 'GET_SlIDER_BLOG_SUCCESS';

export const SET_REVIEWS = 'SET_REVIEWS';

export const SET_US_SECTION = 'SET_US_SECTION';

export const SET_OUR_SECTION = 'SET_OUR_SECTION';

export const SET_PAINTINGS = 'SET_PAINTINGS';

export const SET_SHIPMENT_SECTION = 'SET_SHIPMENT_SECTION';

export const SET_NEWSLETTER_SECTION = 'SET_NEWSLETTER_SECTION';
