import * as React from "react"

function CircleCross(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 438.533 438.533"
      {...props}
    >
      <path d="M409.133 109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736 9.801 259.058 0 219.273 0c-39.781 0-76.47 9.801-110.063 29.407-33.595 19.604-60.192 46.201-79.8 79.796C9.801 142.8 0 179.489 0 219.267c0 39.78 9.804 76.463 29.407 110.062 19.607 33.592 46.204 60.189 79.799 79.798 33.597 19.605 70.283 29.407 110.063 29.407s76.47-9.802 110.065-29.407c33.593-19.602 60.189-46.206 79.795-79.798 19.603-33.596 29.403-70.284 29.403-110.062.001-39.782-9.8-76.472-29.399-110.064zm-86.512 161.736c3.617 3.613 5.428 7.905 5.428 12.854 0 5.133-1.811 9.514-5.428 13.127l-25.693 25.701c-3.614 3.613-7.994 5.42-13.135 5.42-4.948 0-9.236-1.807-12.847-5.42l-51.676-51.682-51.678 51.682c-3.616 3.613-7.898 5.42-12.847 5.42-5.14 0-9.517-1.807-13.134-5.42l-25.697-25.701c-3.616-3.613-5.424-7.994-5.424-13.127 0-4.948 1.809-9.24 5.424-12.854l51.678-51.673-51.678-51.678c-3.616-3.612-5.424-7.898-5.424-12.847 0-5.14 1.809-9.517 5.424-13.134l25.697-25.693c3.617-3.616 7.994-5.424 13.134-5.424 4.949 0 9.231 1.809 12.847 5.424l51.678 51.674 51.676-51.674c3.61-3.616 7.898-5.424 12.847-5.424 5.141 0 9.521 1.809 13.135 5.424l25.693 25.693c3.617 3.617 5.428 7.994 5.428 13.134 0 4.948-1.811 9.235-5.428 12.847l-51.675 51.678 51.675 51.673z" />
    </svg>
  )
}

export default CircleCross
