export const DEFAULT_PRICE = '0.00';

export const NOT_AVAILABLE = 'Not available';

export const ARTWORK_PRICE = 'Artwork price';

export const SHIPPING_COST = 'Shipping cost';

export const DRAINT_FEE = 'Draint fee';

export const PROCESSING_FEES = 'Processing fees';
