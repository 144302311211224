export const initialSizeList = [
  {
    id: '1',
    key: 's',
    label: 'Small',
  },
  {
    id: '2',
    key: 'm',
    label: 'Medium',
  },
  {
    id: '3',
    key: 'l',
    label: 'Large',
  },
  {
    id: '4',
    key: 'xl',
    label: 'Extra Large',
  },
];
