export const SET_WELCOME_MODAL = 'SET_WELCOME_MODAL';

export const SET_FEEDBACK_MODAL = 'SET_FEEDBACK_MODAL';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const SET_SUBS_MODAL = 'SET_SUBS_MODAL';
