import * as React from 'react';

const PaintingSmall = props => {
  return (
    <svg
      width="33"
      height="28"
      viewBox="0 0 33 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.3">
        <path
          d="M30.3337 28H2.42924C1.08759 28 0 26.9124 0 25.5708V2.42924C0 1.08759 1.08759 0 2.42924 0H30.3337C31.6753 0 32.7629 1.08759 32.7629 2.42924V25.5708C32.7629 26.9124 31.6753 28 30.3337 28Z"
          fill="#EFDCFB"
        />
        <path
          d="M28.0579 25.7178H4.70308C3.58022 25.7178 2.66992 24.8075 2.66992 23.6846V4.31612C2.66992 3.19326 3.58022 2.28296 4.70308 2.28296H28.0579C29.1808 2.28296 30.0911 3.19326 30.0911 4.31612V23.6846C30.0911 24.8075 29.1808 25.7178 28.0579 25.7178Z"
          fill="#2BB9F9"
        />
        <path
          d="M18.0102 25.7182H4.70715C3.58407 25.7182 2.67334 24.8082 2.67334 23.6852V20.5101L6.04858 16.4075C7.16248 15.0523 9.23586 15.0523 10.3505 16.4075L12.4521 18.9624L18.0102 25.7182Z"
          fill="#CDEC7A"
        />
        <path
          d="M30.095 18.5793V23.6848C30.095 24.8079 29.1842 25.7178 28.0612 25.7178H6.8938L12.452 18.9621L19.28 10.6618C20.3939 9.30741 22.4681 9.30741 23.582 10.6618L30.095 18.5793Z"
          fill="#B0E995"
        />
        <path
          d="M9.77387 10.0765C11.3662 10.0765 12.6571 8.78568 12.6571 7.1933C12.6571 5.60093 11.3662 4.31006 9.77387 4.31006C8.1815 4.31006 6.89062 5.60093 6.89062 7.1933C6.89062 8.78568 8.1815 10.0765 9.77387 10.0765Z"
          fill="#FFCD3E"
        />
      </g>
      <path
        d="M15.1669 28H1.21462C0.543795 28 0 27.4562 0 26.7854V15.2146C0 14.5438 0.543795 14 1.21462 14H15.1669C15.8377 14 16.3815 14.5438 16.3815 15.2146V26.7854C16.3815 27.4562 15.8377 28 15.1669 28Z"
        fill="#EFDCFB"
      />
      <path
        d="M14.0271 26.8585H2.34959C1.78816 26.8585 1.33301 26.4034 1.33301 25.842V16.1577C1.33301 15.5963 1.78816 15.1411 2.34959 15.1411H14.0271C14.5885 15.1411 15.0436 15.5963 15.0436 16.1577V25.842C15.0436 26.4034 14.5885 26.8585 14.0271 26.8585Z"
        fill="#2BB9F9"
      />
      <path
        d="M9.00682 26.8606H2.35528C1.79375 26.8606 1.33838 26.4056 1.33838 25.844V24.2565L3.026 22.2052C3.58295 21.5276 4.61965 21.5276 5.17699 22.2052L6.22777 23.4827L9.00682 26.8606Z"
        fill="#CDEC7A"
      />
      <path
        d="M15.0486 23.2907V25.8434C15.0486 26.4049 14.5932 26.8599 14.0317 26.8599H3.448L6.22709 23.482L9.64112 19.3319C10.1981 18.6547 11.2352 18.6547 11.7921 19.3319L15.0486 23.2907Z"
        fill="#B0E995"
      />
      <path
        d="M4.88865 19.0375C5.68484 19.0375 6.33027 18.3921 6.33027 17.5959C6.33027 16.7997 5.68484 16.1543 4.88865 16.1543C4.09246 16.1543 3.44702 16.7997 3.44702 17.5959C3.44702 18.3921 4.09246 19.0375 4.88865 19.0375Z"
        fill="#FFCD3E"
      />
    </svg>
  );
};

export default PaintingSmall;
