import * as React from 'react';

function BankBlue(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 508 508"
      style={{
        enableBackground: 'new 0 0 508 508',
      }}
      xmlSpace="preserve"
      width={props.param}
      height={props.param}
      {...props}
    >
      <circle
        style={{
          fill: '#54c0eb',
        }}
        cx={254}
        cy={254}
        r={254}
      />
      <path
        style={{
          fill: '#84dbff',
        }}
        d="M38.4 388C83.2 460 163.2 508 254 508c91.2 0 170.8-48 215.6-120H38.4z"
      />
      <path
        style={{
          fill: '#fff',
        }}
        d="m414 135.6-157.2-108c-1.6-1.2-3.6-1.2-5.2 0L94 135.6c-4 2.8-2 8.8 2.8 8.8h314.4c4.8-.4 6.4-6.4 2.8-8.8z"
      />
      <path
        style={{
          fill: '#e6e9ee',
        }}
        d="M159.6 122 254 57.6l94.4 64.4zM392.8 144h-48.4v1.6c0 6.4 5.2 11.6 11.6 11.6h25.2c6.4 0 11.6-5.2 11.6-11.6V144z"
      />
      <path
        style={{
          fill: '#ced5e0',
        }}
        d="M344.4 329.6h48.4V328c0-6.4-5.2-11.6-11.6-11.6H356c-6.4 0-11.6 5.2-11.6 11.6v1.6z"
      />
      <path
        style={{
          fill: '#fff',
        }}
        d="M378.4 316.4c2.8-20 4.4-48.4 4.4-79.6s-1.6-59.6-4.4-79.6H358c-2.8 20-4.4 48.4-4.4 79.6s1.6 59.6 4.4 79.6h20.4z"
      />
      <path
        style={{
          fill: '#e6e9ee',
        }}
        d="M316.4 144H268v1.6c0 6.4 5.2 11.6 11.6 11.6h25.2c6.4 0 11.6-5.2 11.6-11.6V144z"
      />
      <path
        style={{
          fill: '#ced5e0',
        }}
        d="M268 329.6h48.4V328c0-6.4-5.2-11.6-11.6-11.6h-25.2c-6.4 0-11.6 5.2-11.6 11.6v1.6z"
      />
      <path
        style={{
          fill: '#fff',
        }}
        d="M302.4 316.4c2.8-20 4.4-48.4 4.4-79.6s-1.6-59.6-4.4-79.6H282c-2.8 20-4.4 48.4-4.4 79.6s1.6 59.6 4.4 79.6h20.4z"
      />
      <path
        style={{
          fill: '#e6e9ee',
        }}
        d="M240 144h-48.4v1.6c0 6.4 5.2 11.6 11.6 11.6h25.2c6.4 0 11.6-5.2 11.6-11.6V144z"
      />
      <path
        style={{
          fill: '#ced5e0',
        }}
        d="M191.6 329.6H240V328c0-6.4-5.2-11.6-11.6-11.6h-25.2c-6.4 0-11.6 5.2-11.6 11.6v1.6z"
      />
      <path
        style={{
          fill: '#fff',
        }}
        d="M226 316.4c2.8-20 4.4-48.4 4.4-79.6s-1.6-59.6-4.4-79.6h-20.4c-2.8 20-4.4 48.4-4.4 79.6s1.6 59.6 4.4 79.6H226z"
      />
      <path
        style={{
          fill: '#e6e9ee',
        }}
        d="M163.6 144h-48.4v1.6c0 6.4 5.2 11.6 11.6 11.6H152c6.4 0 11.6-5.2 11.6-11.6V144z"
      />
      <path
        style={{
          fill: '#ced5e0',
        }}
        d="M115.2 329.6h48.4V328c0-6.4-5.2-11.6-11.6-11.6h-25.2c-6.4 0-11.6 5.2-11.6 11.6v1.6z"
      />
      <path
        style={{
          fill: '#fff',
        }}
        d="M149.6 316.4c2.8-20 4.4-48.4 4.4-79.6s-1.6-59.6-4.4-79.6h-20.4c-2.8 20-4.4 48.4-4.4 79.6s1.6 59.6 4.4 79.6h20.4zM419.2 349.2v-8.8c0-6-4.8-10.8-10.8-10.8H99.6c-6 0-10.8 4.8-10.8 10.8v8.8h330.4z"
      />
      <path
        style={{
          fill: '#e6e9ee',
        }}
        d="M434.4 368.8V360c0-6-4.8-10.8-10.8-10.8H84.4c-6 0-10.8 4.8-10.8 10.8v8.8h360.8z"
      />
      <path
        style={{
          fill: '#acb3ba',
        }}
        d="M449.2 388v-8.8c0-6-4.8-10.8-10.8-10.8H69.6c-6 0-10.8 4.8-10.8 10.8v8.8h390.4z"
      />
    </svg>
  );
}

export default BankBlue;
