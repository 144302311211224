export const isoCountries = [
  {
    ccode: 'AD',
    cname: 'Andorra',
    continent: 'EU',
    phone: [376],
    euMember: false,
  },
  {
    ccode: 'AE',
    cname: 'United Arab Emirates',
    continent: 'AS',
    phone: [971],
    euMember: false,
  },
  {
    ccode: 'AF',
    cname: 'Afghanistan',
    continent: 'AS',
    phone: [93],
    euMember: false,
  },
  {
    ccode: 'AG',
    cname: 'Antigua and Barbuda',
    continent: 'NA',
    phone: [1268],
    euMember: false,
  },
  {
    ccode: 'AI',
    cname: 'Anguilla',
    continent: 'NA',
    phone: [1264],
    euMember: false,
  },
  {
    ccode: 'AL',
    cname: 'Albania',
    continent: 'EU',
    phone: [355],
    euMember: false,
  },
  {
    ccode: 'AM',
    cname: 'Armenia',
    continent: 'AS',
    phone: [374],
    euMember: false,
  },
  {
    ccode: 'AO',
    cname: 'Angola',
    continent: 'AF',
    phone: [244],
    euMember: false,
  },
  {
    ccode: 'AR',
    cname: 'Argentina',
    continent: 'SA',
    phone: [54],
    euMember: false,
  },
  {
    ccode: 'AS',
    cname: 'American Samoa',
    continent: 'OC',
    phone: [1684],
    euMember: false,
  },
  {
    ccode: 'AT',
    cname: 'Austria',
    continent: 'EU',
    phone: [43],
    euMember: true,
  },
  {
    ccode: 'AU',
    cname: 'Australia',
    continent: 'OC',
    phone: [61],
    euMember: false,
  },
  {
    ccode: 'AW',
    cname: 'Aruba',
    continent: 'NA',
    phone: [297],
    euMember: false,
  },
  {
    ccode: 'AX',
    cname: 'Åland',
    continent: 'EU',
    phone: [358],
    euMember: false,
  },
  {
    ccode: 'AZ',
    cname: 'Azerbaijan',
    continent: 'AS',
    phone: [994],
    euMember: false,
  },
  {
    ccode: 'BA',
    cname: 'Bosnia and Herzegovina',
    continent: 'EU',
    phone: [387],
    euMember: false,
  },
  {
    ccode: 'BB',
    cname: 'Barbados',
    continent: 'NA',
    phone: [1246],
    euMember: false,
  },
  {
    ccode: 'BD',
    cname: 'Bangladesh',
    continent: 'AS',
    phone: [880],
    euMember: false,
  },
  {
    ccode: 'BE',
    cname: 'Belgium',
    continent: 'EU',
    phone: [32],
    euMember: true,
  },
  {
    ccode: 'BF',
    cname: 'Burkina Faso',
    continent: 'AF',
    phone: [226],
    euMember: false,
  },
  {
    ccode: 'BG',
    cname: 'Bulgaria',
    continent: 'EU',
    phone: [359],
    euMember: true,
  },
  {
    ccode: 'BH',
    cname: 'Bahrain',
    continent: 'AS',
    phone: [973],
    euMember: false,
  },
  {
    ccode: 'BI',
    cname: 'Burundi',
    continent: 'AF',
    phone: [257],
    euMember: false,
  },
  {
    ccode: 'BJ',
    cname: 'Benin',
    continent: 'AF',
    phone: [229],
    euMember: false,
  },
  {
    ccode: 'BL',
    cname: 'Saint Barthélemy',
    continent: 'NA',
    phone: [590],
    euMember: false,
  },
  {
    ccode: 'BM',
    cname: 'Bermuda',
    continent: 'NA',
    phone: [1441],
    euMember: false,
  },
  {
    ccode: 'BN',
    cname: 'Brunei',
    continent: 'AS',
    phone: [673],
    euMember: false,
  },
  {
    ccode: 'BO',
    cname: 'Bolivia',
    continent: 'SA',
    phone: [591],
    euMember: false,
  },
  {
    ccode: 'BQ',
    cname: 'Bonaire',
    continent: 'NA',
    phone: [5997],
    euMember: false,
  },
  {
    ccode: 'BR',
    cname: 'Brazil',
    continent: 'SA',
    phone: [55],
    euMember: false,
  },
  {
    ccode: 'BS',
    cname: 'Bahamas',
    continent: 'NA',
    phone: [1242],
    euMember: false,
  },
  {
    ccode: 'BT',
    cname: 'Bhutan',
    continent: 'AS',
    phone: [975],
    euMember: false,
  },
  {
    ccode: 'BW',
    cname: 'Botswana',
    continent: 'AF',
    phone: [267],
    euMember: false,
  },
  {
    ccode: 'BY',
    cname: 'Belarus',
    continent: 'EU',
    phone: [375],
    euMember: false,
  },
  {
    ccode: 'BZ',
    cname: 'Belize',
    continent: 'NA',
    phone: [501],
    euMember: false,
  },
  {
    ccode: 'CA',
    cname: 'Canada',
    continent: 'NA',
    phone: [1],
    euMember: false,
  },
  {
    ccode: 'CC',
    cname: 'Cocos [Keeling] Islands',
    continent: 'AS',
    phone: [61],
    euMember: false,
  },
  {
    ccode: 'CD',
    cname: 'Democratic Republic of the Congo',
    continent: 'AF',
    phone: [243],
    euMember: false,
  },
  {
    ccode: 'CF',
    cname: 'Central African Republic',
    continent: 'AF',
    phone: [236],
    euMember: false,
  },
  {
    ccode: 'CG',
    cname: 'Republic of the Congo',
    continent: 'AF',
    phone: [242],
    euMember: false,
  },
  {
    ccode: 'CH',
    cname: 'Switzerland',
    continent: 'EU',
    phone: [41],
    euMember: false,
  },
  {
    ccode: 'CI',
    cname: 'Ivory Coast',
    continent: 'AF',
    phone: [225],
    euMember: false,
  },
  {
    ccode: 'CK',
    cname: 'Cook Islands',
    continent: 'OC',
    phone: [682],
    euMember: false,
  },
  {
    ccode: 'CL',
    cname: 'Chile',
    continent: 'SA',
    phone: [56],
    euMember: false,
  },
  {
    ccode: 'CM',
    cname: 'Cameroon',
    continent: 'AF',
    phone: [237],
    euMember: false,
  },
  {
    ccode: 'CN',
    cname: 'China',
    continent: 'AS',
    phone: [86],
    euMember: false,
  },
  {
    ccode: 'CO',
    cname: 'Colombia',
    continent: 'SA',
    phone: [57],
    euMember: false,
  },
  {
    ccode: 'CR',
    cname: 'Costa Rica',
    continent: 'NA',
    phone: [506],
    euMember: false,
  },
  {
    ccode: 'CU',
    cname: 'Cuba',
    continent: 'NA',
    phone: [53],
    euMember: false,
  },
  {
    ccode: 'CV',
    cname: 'Cape Verde',
    continent: 'AF',
    phone: [238],
    euMember: false,
  },
  {
    ccode: 'CW',
    cname: 'Curacao',
    continent: 'NA',
    phone: [5999],
    euMember: false,
  },
  {
    ccode: 'CX',
    cname: 'Christmas Island',
    continent: 'AS',
    phone: [61],
    euMember: false,
  },
  {
    ccode: 'CY',
    cname: 'Cyprus',
    continent: 'EU',
    phone: [357],
    euMember: true,
  },
  {
    ccode: 'CZ',
    cname: 'Czech Republic',
    continent: 'EU',
    phone: [420],
    euMember: true,
  },
  {
    ccode: 'DE',
    cname: 'Germany',
    continent: 'EU',
    phone: [49],
    euMember: true,
  },
  {
    ccode: 'DJ',
    cname: 'Djibouti',
    continent: 'AF',
    phone: [253],
    euMember: false,
  },
  {
    ccode: 'DK',
    cname: 'Denmark',
    continent: 'EU',
    phone: [45],
    euMember: true,
  },
  {
    ccode: 'DM',
    cname: 'Dominica',
    continent: 'NA',
    phone: [1767],
    euMember: false,
  },
  {
    ccode: 'DO',
    cname: 'Dominican Republic',
    continent: 'NA',
    phone: [1809, 1829, 1849],
    euMember: false,
  },
  {
    ccode: 'DZ',
    cname: 'Algeria',
    continent: 'AF',
    phone: [213],
    euMember: false,
  },
  {
    ccode: 'EC',
    cname: 'Ecuador',
    continent: 'SA',
    phone: [593],
    euMember: false,
  },
  {
    ccode: 'EE',
    cname: 'Estonia',
    continent: 'EU',
    phone: [372],
    euMember: true,
  },
  {
    ccode: 'EG',
    cname: 'Egypt',
    continent: 'AF',
    phone: [20],
    euMember: false,
  },
  {
    ccode: 'EH',
    cname: 'Western Sahara',
    continent: 'AF',
    phone: [212],
    euMember: false,
  },
  {
    ccode: 'ER',
    cname: 'Eritrea',
    continent: 'AF',
    phone: [291],
    euMember: false,
  },
  {
    ccode: 'ES',
    cname: 'Spain',
    continent: 'EU',
    phone: [34],
    euMember: true,
  },
  {
    ccode: 'ET',
    cname: 'Ethiopia',
    continent: 'AF',
    phone: [251],
    euMember: false,
  },
  {
    ccode: 'FI',
    cname: 'Finland',
    continent: 'EU',
    phone: [358],
    euMember: true,
  },
  {
    ccode: 'FJ',
    cname: 'Fiji',
    continent: 'OC',
    phone: [679],
    euMember: false,
  },
  {
    ccode: 'FK',
    cname: 'Falkland Islands',
    continent: 'SA',
    phone: [500],
    euMember: false,
  },
  {
    ccode: 'FM',
    cname: 'Micronesia',
    continent: 'OC',
    phone: [691],
    euMember: false,
  },
  {
    ccode: 'FO',
    cname: 'Faroe Islands',
    continent: 'EU',
    phone: [298],
    euMember: false,
  },
  {
    ccode: 'FR',
    cname: 'France',
    continent: 'EU',
    phone: [33],
    euMember: true,
  },
  {
    ccode: 'GA',
    cname: 'Gabon',
    continent: 'AF',
    phone: [241],
    euMember: false,
  },
  {
    ccode: 'GB',
    cname: 'United Kingdom',
    continent: 'EU',
    phone: [44],
    euMember: false,
  },
  {
    ccode: 'GD',
    cname: 'Grenada',
    continent: 'NA',
    phone: [1473],
    euMember: false,
  },
  {
    ccode: 'GE',
    cname: 'Georgia',
    continent: 'AS',
    phone: [995],
    euMember: false,
  },
  {
    ccode: 'GF',
    cname: 'French Guiana',
    continent: 'SA',
    phone: [594],
    euMember: false,
  },
  {
    ccode: 'GG',
    cname: 'Guernsey',
    continent: 'EU',
    phone: [44],
    euMember: false,
  },
  {
    ccode: 'GH',
    cname: 'Ghana',
    continent: 'AF',
    phone: [233],
    euMember: false,
  },
  {
    ccode: 'GI',
    cname: 'Gibraltar',
    continent: 'EU',
    phone: [350],
    euMember: false,
  },
  {
    ccode: 'GL',
    cname: 'Greenland',
    continent: 'NA',
    phone: [299],
    euMember: false,
  },
  {
    ccode: 'GM',
    cname: 'Gambia',
    continent: 'AF',
    phone: [220],
    euMember: false,
  },
  {
    ccode: 'GN',
    cname: 'Guinea',
    continent: 'AF',
    phone: [224],
    euMember: false,
  },
  {
    ccode: 'GP',
    cname: 'Guadeloupe',
    continent: 'NA',
    phone: [590],
    euMember: false,
  },
  {
    ccode: 'GQ',
    cname: 'Equatorial Guinea',
    continent: 'AF',
    phone: [240],
    euMember: false,
  },
  {
    ccode: 'GR',
    cname: 'Greece',
    continent: 'EU',
    phone: [30],
    euMember: true,
  },
  {
    ccode: 'GT',
    cname: 'Guatemala',
    continent: 'NA',
    phone: [502],
    euMember: false,
  },
  {
    ccode: 'GU',
    cname: 'Guam',
    continent: 'OC',
    phone: [1671],
    euMember: false,
  },
  {
    ccode: 'GW',
    cname: 'Guinea-Bissau',
    continent: 'AF',
    phone: [245],
    euMember: false,
  },
  {
    ccode: 'GY',
    cname: 'Guyana',
    continent: 'SA',
    phone: [592],
    euMember: false,
  },
  {
    ccode: 'HK',
    cname: 'Hong Kong',
    continent: 'AS',
    phone: [852],
    euMember: false,
  },
  {
    ccode: 'HN',
    cname: 'Honduras',
    continent: 'NA',
    phone: [504],
    euMember: false,
  },
  {
    ccode: 'HR',
    cname: 'Croatia',
    continent: 'EU',
    phone: [385],
    euMember: true,
  },
  {
    ccode: 'HT',
    cname: 'Haiti',
    continent: 'NA',
    phone: [509],
    euMember: false,
  },
  {
    ccode: 'HU',
    cname: 'Hungary',
    continent: 'EU',
    phone: [36],
    euMember: true,
  },
  {
    ccode: 'ID',
    cname: 'Indonesia',
    continent: 'AS',
    phone: [62],
    euMember: false,
  },
  {
    ccode: 'IE',
    cname: 'Ireland',
    continent: 'EU',
    phone: [353],
    euMember: true,
  },
  {
    ccode: 'IL',
    cname: 'Israel',
    continent: 'AS',
    phone: [972],
    euMember: false,
  },
  {
    ccode: 'IM',
    cname: 'Isle of Man',
    continent: 'EU',
    phone: [44],
    euMember: false,
  },
  {
    ccode: 'IN',
    cname: 'India',
    continent: 'AS',
    phone: [91],
    euMember: false,
  },
  {
    ccode: 'IO',
    cname: 'British Indian Ocean Territory',
    continent: 'AS',
    phone: [246],
    euMember: false,
  },
  {
    ccode: 'IQ',
    cname: 'Iraq',
    continent: 'AS',
    phone: [964],
    euMember: false,
  },
  {
    ccode: 'IR',
    cname: 'Iran',
    continent: 'AS',
    phone: [98],
    euMember: false,
  },
  {
    ccode: 'IS',
    cname: 'Iceland',
    continent: 'EU',
    phone: [354],
    euMember: false,
  },
  {
    ccode: 'IT',
    cname: 'Italy',
    continent: 'EU',
    phone: [39],
    euMember: true,
  },
  {
    ccode: 'JE',
    cname: 'Jersey',
    continent: 'EU',
    phone: [44],
    euMember: false,
  },
  {
    ccode: 'JM',
    cname: 'Jamaica',
    continent: 'NA',
    phone: [1876],
    euMember: false,
  },
  {
    ccode: 'JO',
    cname: 'Jordan',
    continent: 'AS',
    phone: [962],
    euMember: false,
  },
  {
    ccode: 'JP',
    cname: 'Japan',
    continent: 'AS',
    phone: [81],
    euMember: false,
  },
  {
    ccode: 'KE',
    cname: 'Kenya',
    continent: 'AF',
    phone: [254],
    euMember: false,
  },
  {
    ccode: 'KG',
    cname: 'Kyrgyzstan',
    continent: 'AS',
    phone: [996],
    euMember: false,
  },
  {
    ccode: 'KH',
    cname: 'Cambodia',
    continent: 'AS',
    phone: [855],
    euMember: false,
  },
  {
    ccode: 'KI',
    cname: 'Kiribati',
    continent: 'OC',
    phone: [686],
    euMember: false,
  },
  {
    ccode: 'KM',
    cname: 'Comoros',
    continent: 'AF',
    phone: [269],
    euMember: false,
  },
  {
    ccode: 'KN',
    cname: 'Saint Kitts and Nevis',
    continent: 'NA',
    phone: [1869],
    euMember: false,
  },
  {
    ccode: 'KP',
    cname: 'North Korea',
    continent: 'AS',
    phone: [850],
    euMember: false,
  },
  {
    ccode: 'KR',
    cname: 'South Korea',
    continent: 'AS',
    phone: [82],
    euMember: false,
  },
  {
    ccode: 'KW',
    cname: 'Kuwait',
    continent: 'AS',
    phone: [965],
    euMember: false,
  },
  {
    ccode: 'KY',
    cname: 'Cayman Islands',
    continent: 'NA',
    phone: [1345],
    euMember: false,
  },
  {
    ccode: 'KZ',
    cname: 'Kazakhstan',
    continent: 'AS',
    phone: [76, 77],
    euMember: false,
  },
  {
    ccode: 'LA',
    cname: 'Laos',
    continent: 'AS',
    phone: [856],
    euMember: false,
  },
  {
    ccode: 'LB',
    cname: 'Lebanon',
    continent: 'AS',
    phone: [961],
    euMember: false,
  },
  {
    ccode: 'LC',
    cname: 'Saint Lucia',
    continent: 'NA',
    phone: [1758],
    euMember: false,
  },
  {
    ccode: 'LI',
    cname: 'Liechtenstein',
    continent: 'EU',
    phone: [423],
    euMember: false,
  },
  {
    ccode: 'LK',
    cname: 'Sri Lanka',
    continent: 'AS',
    phone: [94],
    euMember: false,
  },
  {
    ccode: 'LR',
    cname: 'Liberia',
    continent: 'AF',
    phone: [231],
    euMember: false,
  },
  {
    ccode: 'LS',
    cname: 'Lesotho',
    continent: 'AF',
    phone: [266],
    euMember: false,
  },
  {
    ccode: 'LT',
    cname: 'Lithuania',
    continent: 'EU',
    phone: [370],
    euMember: true,
  },
  {
    ccode: 'LU',
    cname: 'Luxembourg',
    continent: 'EU',
    phone: [352],
    euMember: true,
  },
  {
    ccode: 'LV',
    cname: 'Latvia',
    continent: 'EU',
    phone: [371],
    euMember: true,
  },
  {
    ccode: 'LY',
    cname: 'Libya',
    continent: 'AF',
    phone: [218],
    euMember: false,
  },
  {
    ccode: 'MA',
    cname: 'Morocco',
    continent: 'AF',
    phone: [212],
    euMember: false,
  },
  {
    ccode: 'MC',
    cname: 'Monaco',
    continent: 'EU',
    phone: [377],
    euMember: false,
  },
  {
    ccode: 'MD',
    cname: 'Moldova',
    continent: 'EU',
    phone: [373],
    euMember: false,
  },
  {
    ccode: 'ME',
    cname: 'Montenegro',
    continent: 'EU',
    phone: [382],
    euMember: false,
  },
  {
    ccode: 'MF',
    cname: 'Saint Martin',
    continent: 'NA',
    phone: [590],
    euMember: false,
  },
  {
    ccode: 'MG',
    cname: 'Madagascar',
    continent: 'AF',
    phone: [261],
    euMember: false,
  },
  {
    ccode: 'MH',
    cname: 'Marshall Islands',
    continent: 'OC',
    phone: [692],
    euMember: false,
  },
  {
    ccode: 'MK',
    cname: 'North Macedonia',
    continent: 'EU',
    phone: [389],
    euMember: false,
  },
  {
    ccode: 'ML',
    cname: 'Mali',
    continent: 'AF',
    phone: [223],
    euMember: false,
  },
  {
    ccode: 'MM',
    cname: 'Myanmar [Burma]',
    continent: 'AS',
    phone: [95],
    euMember: false,
  },
  {
    ccode: 'MN',
    cname: 'Mongolia',
    continent: 'AS',
    phone: [976],
    euMember: false,
  },
  {
    ccode: 'MO',
    cname: 'Macao',
    continent: 'AS',
    phone: [853],
    euMember: false,
  },
  {
    ccode: 'MP',
    cname: 'Northern Mariana Islands',
    continent: 'OC',
    phone: [1670],
    euMember: false,
  },
  {
    ccode: 'MQ',
    cname: 'Martinique',
    continent: 'NA',
    phone: [596],
    euMember: false,
  },
  {
    ccode: 'MR',
    cname: 'Mauritania',
    continent: 'AF',
    phone: [222],
    euMember: false,
  },
  {
    ccode: 'MS',
    cname: 'Montserrat',
    continent: 'NA',
    phone: [1664],
    euMember: false,
  },
  {
    ccode: 'MT',
    cname: 'Malta',
    continent: 'EU',
    phone: [356],
    euMember: true,
  },
  {
    ccode: 'MU',
    cname: 'Mauritius',
    continent: 'AF',
    phone: [230],
    euMember: false,
  },
  {
    ccode: 'MV',
    cname: 'Maldives',
    continent: 'AS',
    phone: [960],
    euMember: false,
  },
  {
    ccode: 'MW',
    cname: 'Malawi',
    continent: 'AF',
    phone: [265],
    euMember: false,
  },
  {
    ccode: 'MX',
    cname: 'Mexico',
    continent: 'NA',
    phone: [52],
    euMember: false,
  },
  {
    ccode: 'MY',
    cname: 'Malaysia',
    continent: 'AS',
    phone: [60],
    euMember: false,
  },
  {
    ccode: 'MZ',
    cname: 'Mozambique',
    continent: 'AF',
    phone: [258],
    euMember: false,
  },
  {
    ccode: 'NA',
    cname: 'Namibia',
    continent: 'AF',
    phone: [264],
    euMember: false,
  },
  {
    ccode: 'NC',
    cname: 'New Caledonia',
    continent: 'OC',
    phone: [687],
    euMember: false,
  },
  {
    ccode: 'NE',
    cname: 'Niger',
    continent: 'AF',
    phone: [227],
    euMember: false,
  },
  {
    ccode: 'NF',
    cname: 'Norfolk Island',
    continent: 'OC',
    phone: [672],
    euMember: false,
  },
  {
    ccode: 'NG',
    cname: 'Nigeria',
    continent: 'AF',
    phone: [234],
    euMember: false,
  },
  {
    ccode: 'NI',
    cname: 'Nicaragua',
    continent: 'NA',
    phone: [505],
    euMember: false,
  },
  {
    ccode: 'NL',
    cname: 'Netherlands',
    continent: 'EU',
    phone: [31],
    euMember: true,
  },
  {
    ccode: 'NO',
    cname: 'Norway',
    continent: 'EU',
    phone: [47],
    euMember: false,
  },
  {
    ccode: 'NP',
    cname: 'Nepal',
    continent: 'AS',
    phone: [977],
    euMember: false,
  },
  {
    ccode: 'NR',
    cname: 'Nauru',
    continent: 'OC',
    phone: [674],
    euMember: false,
  },
  {
    ccode: 'NU',
    cname: 'Niue',
    continent: 'OC',
    phone: [683],
    euMember: false,
  },
  {
    ccode: 'NZ',
    cname: 'New Zealand',
    continent: 'OC',
    phone: [64],
    euMember: false,
  },
  {
    ccode: 'OM',
    cname: 'Oman',
    continent: 'AS',
    phone: [968],
    euMember: false,
  },
  {
    ccode: 'PA',
    cname: 'Panama',
    continent: 'NA',
    phone: [507],
    euMember: false,
  },
  {
    ccode: 'PE',
    cname: 'Peru',
    continent: 'SA',
    phone: [51],
    euMember: false,
  },
  {
    ccode: 'PF',
    cname: 'French Polynesia',
    continent: 'OC',
    phone: [689],
    euMember: false,
  },
  {
    ccode: 'PG',
    cname: 'Papua New Guinea',
    continent: 'OC',
    phone: [675],
    euMember: false,
  },
  {
    ccode: 'PH',
    cname: 'Philippines',
    continent: 'AS',
    phone: [63],
    euMember: false,
  },
  {
    ccode: 'PK',
    cname: 'Pakistan',
    continent: 'AS',
    phone: [92],
    euMember: false,
  },
  {
    ccode: 'PL',
    cname: 'Poland',
    continent: 'EU',
    phone: [48],
    euMember: true,
  },
  {
    ccode: 'PM',
    cname: 'Saint Pierre and Miquelon',
    continent: 'NA',
    phone: [508],
    euMember: false,
  },
  {
    ccode: 'PN',
    cname: 'Pitcairn Islands',
    continent: 'OC',
    phone: [64],
    euMember: false,
  },
  {
    ccode: 'PR',
    cname: 'Puerto Rico',
    continent: 'NA',
    phone: [1787, 1939],
    euMember: false,
  },
  {
    ccode: 'PS',
    cname: 'Palestine',
    continent: 'AS',
    phone: [970],
    euMember: false,
  },
  {
    ccode: 'PT',
    cname: 'Portugal',
    continent: 'EU',
    phone: [351],
    euMember: true,
  },
  {
    ccode: 'PW',
    cname: 'Palau',
    continent: 'OC',
    phone: [680],
    euMember: false,
  },
  {
    ccode: 'PY',
    cname: 'Paraguay',
    continent: 'SA',
    phone: [595],
    euMember: false,
  },
  {
    ccode: 'QA',
    cname: 'Qatar',
    continent: 'AS',
    phone: [974],
    euMember: false,
  },
  {
    ccode: 'RE',
    cname: 'Réunion',
    continent: 'AF',
    phone: [262],
    euMember: false,
  },
  {
    ccode: 'RO',
    cname: 'Romania',
    continent: 'EU',
    phone: [40],
    euMember: true,
  },
  {
    ccode: 'RS',
    cname: 'Serbia',
    continent: 'EU',
    phone: [381],
    euMember: false,
  },
  {
    ccode: 'RU',
    cname: 'Russia',
    continent: 'EU',
    phone: [7],
    euMember: false,
  },
  {
    ccode: 'RW',
    cname: 'Rwanda',
    continent: 'AF',
    phone: [250],
    euMember: false,
  },
  {
    ccode: 'SA',
    cname: 'Saudi Arabia',
    continent: 'AS',
    phone: [966],
    euMember: false,
  },
  {
    ccode: 'SB',
    cname: 'Solomon Islands',
    continent: 'OC',
    phone: [677],
    euMember: false,
  },
  {
    ccode: 'SC',
    cname: 'Seychelles',
    continent: 'AF',
    phone: [248],
    euMember: false,
  },
  {
    ccode: 'SD',
    cname: 'Sudan',
    continent: 'AF',
    phone: [249],
    euMember: false,
  },
  {
    ccode: 'SE',
    cname: 'Sweden',
    continent: 'EU',
    phone: [46],
    euMember: true,
  },
  {
    ccode: 'SG',
    cname: 'Singapore',
    continent: 'AS',
    phone: [65],
    euMember: false,
  },
  {
    ccode: 'SH',
    cname: 'Saint Helena',
    continent: 'AF',
    phone: [290],
    euMember: false,
  },
  {
    ccode: 'SI',
    cname: 'Slovenia',
    continent: 'EU',
    phone: [386],
    euMember: true,
  },
  {
    ccode: 'SJ',
    cname: 'Svalbard and Jan Mayen',
    continent: 'EU',
    phone: [4779],
    euMember: false,
  },
  {
    ccode: 'SK',
    cname: 'Slovakia',
    continent: 'EU',
    phone: [421],
    euMember: true,
  },
  {
    ccode: 'SL',
    cname: 'Sierra Leone',
    continent: 'AF',
    phone: [232],
    euMember: false,
  },
  {
    ccode: 'SM',
    cname: 'San Marino',
    continent: 'EU',
    phone: [378],
    euMember: false,
  },
  {
    ccode: 'SN',
    cname: 'Senegal',
    continent: 'AF',
    phone: [221],
    euMember: false,
  },
  {
    ccode: 'SO',
    cname: 'Somalia',
    continent: 'AF',
    phone: [252],
    euMember: false,
  },
  {
    ccode: 'SR',
    cname: 'Suriname',
    continent: 'SA',
    phone: [597],
    euMember: false,
  },
  {
    ccode: 'SS',
    cname: 'South Sudan',
    continent: 'AF',
    phone: [211],
    euMember: false,
  },
  {
    ccode: 'ST',
    cname: 'São Tomé and Príncipe',
    continent: 'AF',
    phone: [239],
    euMember: false,
  },
  {
    ccode: 'SV',
    cname: 'El Salvador',
    continent: 'NA',
    phone: [503],
    euMember: false,
  },
  {
    ccode: 'SX',
    cname: 'Sint Maarten',
    continent: 'NA',
    phone: [1721],
    euMember: false,
  },
  {
    ccode: 'SY',
    cname: 'Syria',
    continent: 'AS',
    phone: [963],
    euMember: false,
  },
  {
    ccode: 'SZ',
    cname: 'Swaziland',
    continent: 'AF',
    phone: [268],
    euMember: false,
  },
  {
    ccode: 'TC',
    cname: 'Turks and Caicos Islands',
    continent: 'NA',
    phone: [1649],
    euMember: false,
  },
  {
    ccode: 'TD',
    cname: 'Chad',
    continent: 'AF',
    phone: [235],
    euMember: false,
  },
  {
    ccode: 'TG',
    cname: 'Togo',
    continent: 'AF',
    phone: [228],
    euMember: false,
  },
  {
    ccode: 'TH',
    cname: 'Thailand',
    continent: 'AS',
    phone: [66],
    euMember: false,
  },
  {
    ccode: 'TJ',
    cname: 'Tajikistan',
    continent: 'AS',
    phone: [992],
    euMember: false,
  },
  {
    ccode: 'TK',
    cname: 'Tokelau',
    continent: 'OC',
    phone: [690],
    euMember: false,
  },
  {
    ccode: 'TL',
    cname: 'East Timor',
    continent: 'OC',
    phone: [670],
    euMember: false,
  },
  {
    ccode: 'TM',
    cname: 'Turkmenistan',
    continent: 'AS',
    phone: [993],
    euMember: false,
  },
  {
    ccode: 'TN',
    cname: 'Tunisia',
    continent: 'AF',
    phone: [216],
    euMember: false,
  },
  {
    ccode: 'TO',
    cname: 'Tonga',
    continent: 'OC',
    phone: [676],
    euMember: false,
  },
  {
    ccode: 'TR',
    cname: 'Turkey',
    continent: 'AS',
    phone: [90],
    euMember: false,
  },
  {
    ccode: 'TT',
    cname: 'Trinidad and Tobago',
    continent: 'NA',
    phone: [1868],
    euMember: false,
  },
  {
    ccode: 'TV',
    cname: 'Tuvalu',
    continent: 'OC',
    phone: [688],
    euMember: false,
  },
  {
    ccode: 'TW',
    cname: 'Taiwan',
    continent: 'AS',
    phone: [886],
    euMember: false,
  },
  {
    ccode: 'TZ',
    cname: 'Tanzania',
    continent: 'AF',
    phone: [255],
    euMember: false,
  },
  {
    ccode: 'UA',
    cname: 'Ukraine',
    continent: 'EU',
    phone: [380],
    euMember: false,
  },
  {
    ccode: 'UG',
    cname: 'Uganda',
    continent: 'AF',
    phone: [256],
    euMember: false,
  },
  {
    ccode: 'UM',
    cname: 'U.S. Minor Outlying Islands',
    continent: 'OC',
    phone: [1],
    euMember: false,
  },
  {
    ccode: 'US',
    cname: 'United States',
    continent: 'NA',
    phone: [1],
    euMember: false,
  },
  {
    ccode: 'UY',
    cname: 'Uruguay',
    continent: 'SA',
    phone: [598],
    euMember: false,
  },
  {
    ccode: 'UZ',
    cname: 'Uzbekistan',
    continent: 'AS',
    phone: [998],
    euMember: false,
  },
  {
    ccode: 'VA',
    cname: 'Vatican City',
    continent: 'EU',
    phone: [379],
    euMember: false,
  },
  {
    ccode: 'VC',
    cname: 'Saint Vincent and the Grenadines',
    continent: 'NA',
    phone: [1784],
    euMember: false,
  },
  {
    ccode: 'VE',
    cname: 'Venezuela',
    continent: 'SA',
    phone: [58],
    euMember: false,
  },
  {
    ccode: 'VG',
    cname: 'British Virgin Islands',
    continent: 'NA',
    phone: [1284],
    euMember: false,
  },
  {
    ccode: 'VI',
    cname: 'U.S. Virgin Islands',
    continent: 'NA',
    phone: [1340],
    euMember: false,
  },
  {
    ccode: 'VN',
    cname: 'Vietnam',
    continent: 'AS',
    phone: [84],
    euMember: false,
  },
  {
    ccode: 'VU',
    cname: 'Vanuatu',
    continent: 'OC',
    phone: [678],
    euMember: false,
  },
  {
    ccode: 'WF',
    cname: 'Wallis and Futuna',
    continent: 'OC',
    phone: [681],
    euMember: false,
  },
  {
    ccode: 'WS',
    cname: 'Samoa',
    continent: 'OC',
    phone: [685],
    euMember: false,
  },
  {
    ccode: 'XK',
    cname: 'Kosovo',
    continent: 'EU',
    phone: [377, 381, 383, 386],
    euMember: false,
  },
  {
    ccode: 'YE',
    cname: 'Yemen',
    continent: 'AS',
    phone: [967],
    euMember: false,
  },
  {
    ccode: 'YT',
    cname: 'Mayotte',
    continent: 'AF',
    phone: [262],
    euMember: false,
  },
  {
    ccode: 'ZA',
    cname: 'South Africa',
    continent: 'AF',
    phone: [27],
    euMember: false,
  },
  {
    ccode: 'ZM',
    cname: 'Zambia',
    continent: 'AF',
    phone: [260],
    euMember: false,
  },
  {
    ccode: 'ZW',
    cname: 'Zimbabwe',
    continent: 'AF',
    phone: [263],
    euMember: false,
  },
];
