export const SET_ACCOUNTS_LOADING = 'SET_ACCOUNTS_LOADING';

export const UNSET_ACCOUNTS_LOADING = 'UNSET_ACCOUNTS_LOADING';

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const SET_ACCOUNTS_STATE_FROM_COOKIES = 'SET_STATE_FROM_COOKIES';

export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';

export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';

export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR';

export const SET_ACCOUNTS_PAGE = 'SET_ACCOUNTS_PAGE';

export const SAVE_ACCOUNTS_PAGE_STATE = 'SAVE_PAGE_STATE';

export const SET_ACCOUNTS_FILTER = 'SET_ACCOUNTS_FILTER';

export const SET_ACCOUNTS_DATE_FILTER = 'SET_ACCOUNTS_DATE_FILTER';

export const SET_ACCOUNTS_COUNTRY_FILTER = 'SET_ACCOUNTS_COUNTRY_FILTER';

export const UPDATE_ACCOUNT_STATUS_SUCCESS = 'UPDATE_ACCOUNT_STATUS_SUCCESS';

export const UPDATE_ACCOUNT_STATUS_ERROR = 'UPDATE_ACCOUNT_STATUS_ERROR';

export const SET_CSV_LOADING = 'SET_CSV_LOADING';

export const GENERATE_CSV_SUCCESS = 'GENERATE_CSV_SUCCESS';

export const GENERATE_CSV_ERROR = 'GENERATE_CSV_ERROR';

export const SET_USERNAME_TAKEN = 'SET_USERNAME_TAKEN';

export const SET_EMAIL_TAKEN = 'SET_EMAIL_TAKEN';
