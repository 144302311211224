export const SET_DOWNSIZE_LOADING = 'SET_DOWNSIZE_LOADING';

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const GET_DOWNSIZE_ACCOUNTS_SUCCESS = 'GET_DOWNSIZE_ACCOUNTS_SUCCESS';

export const GET_DOWNSIZE_ACCOUNTS_ERROR = 'GET_DOWNSIZE_ACCOUNTS_ERROR';

export const SET_DOWNSIZE_ACCOUNTS_PAGE = 'SET_DOWNSIZE_ACCOUNTS_PAGE';

export const GET_DOWNSIZE_ARTWORKS_SUCCESS = 'GET_DOWNSIZE_ARTWORKS_SUCCESS';

export const GET_DOWNSIZE_ARTWORKS_ERROR = 'GET_DOWNSIZE_ARTWORKS_ERROR';

export const SET_DOWNSIZE_ARTWORKS_PAGE = 'SET_DOWNSIZE_ARTWORKS_PAGE';

export const DOWNSIZE_IMAGE_SUCCESS = 'DOWNSIZE_IMAGE_SUCCESS';

export const DOWNSIZE_IMAGE_ERROR = 'DOWNSIZE_IMAGE_ERROR';
