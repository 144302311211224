import { OUTLINE_COLOR } from 'constants/colors';
import React from 'react';

function Icon({ fill = OUTLINE_COLOR }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6078_2800)">
        <path
          d="M30.459 16.7816C30.2337 16.2842 30.2337 15.7149 30.459 15.2176L31.6524 12.5936C32.0964 11.6162 32.1164 10.4869 31.7057 9.4949C31.295 8.5029 30.483 7.71757 29.479 7.34157L26.779 6.32823C26.2684 6.13623 25.8657 5.73357 25.6737 5.22157L24.6604 2.5229C24.2844 1.51757 23.499 0.705568 22.507 0.294901C21.515 -0.115766 20.3857 -0.0970989 19.4097 0.348234L16.7844 1.54157C16.2857 1.7669 15.7164 1.76557 15.2204 1.54157L12.5937 0.349568C11.6164 -0.0957656 10.487 -0.113099 9.49504 0.296234C8.50438 0.708234 7.71904 1.52023 7.34304 2.52423L6.32971 5.22423C6.13771 5.7349 5.73504 6.13757 5.22304 6.32957L2.52438 7.3429C1.52038 7.7189 0.708376 8.50424 0.29771 9.49623C-0.112957 10.4882 -0.0942903 11.6176 0.351043 12.5936L1.54304 15.2189C1.76838 15.7162 1.76838 16.2856 1.54304 16.7829L0.34971 19.4069C-0.0942904 20.3842 -0.11429 21.5136 0.296376 22.5056C0.707043 23.4976 1.51904 24.2829 2.52304 24.6589L5.22304 25.6722C5.73371 25.8642 6.13638 26.2669 6.32838 26.7789L7.34171 29.4776C7.71771 30.4829 8.50304 31.2949 9.49504 31.7056C9.96971 31.9016 10.4764 32.0002 10.983 32.0002C11.5337 32.0002 12.0844 31.8842 12.5924 31.6522L15.2177 30.4602C15.715 30.2336 16.2844 30.2349 16.7817 30.4602L19.4057 31.6536C20.383 32.0989 21.5124 32.1162 22.5044 31.7069C23.4964 31.2962 24.2817 30.4842 24.6577 29.4802L25.671 26.7802C25.863 26.2696 26.2657 25.8669 26.7777 25.6749L29.4764 24.6616C30.4817 24.2856 31.2937 23.5002 31.7044 22.5082C32.115 21.5162 32.0964 20.3869 31.651 19.4109L30.459 16.7816ZM18.7204 22.5216C18.7204 23.3349 18.0537 24.0016 17.227 24.0016H14.7737C14.0537 24.0016 13.2804 23.4682 13.2804 22.2816V21.5616H18.7204V22.5216ZM19.8004 18.5749C19.2404 19.0282 18.8804 19.6149 18.7604 20.2282H13.2537C13.1604 19.6282 12.827 19.0682 12.2804 18.6416C10.8004 17.4816 9.96038 15.7349 10.0004 13.8416C10.067 10.6682 12.7204 8.04157 15.907 8.00157C17.5204 7.96157 19.067 8.58823 20.227 9.7349C21.3737 10.8549 22.0004 12.3616 22.0004 13.9616C22.0004 15.7616 21.2017 17.4416 19.8004 18.5749Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_6078_2800">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
