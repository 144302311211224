import { OUTLINE_COLOR } from 'constants/colors';
import React from 'react';

function Icon({ fill = OUTLINE_COLOR }) {
  return (
    <svg
      width="39"
      height="32"
      viewBox="0 0 39 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.43737 26.3124C9.43737 24.2443 7.75558 22.5625 5.68742 22.5625C3.61926 22.5625 1.93747 24.2443 1.93747 26.3124V28.1874C1.93747 29.221 1.03361 30.1249 0 30.1249V31.9999H3.81245C6.91422 31.9999 9.43737 29.4142 9.43737 26.3124Z"
        fill={fill}
      />
      <path
        d="M8.4886 16.9373C8.49123 16.6236 8.49992 16.3148 8.49992 15.9998C8.49992 8.72969 8.01192 0 5.68746 0C3.36299 0 2.875 8.72969 2.875 15.9998C2.875 16.3148 2.88369 16.6236 2.88631 16.9373H8.4886Z"
        fill={fill}
      />
      <path
        d="M5.68706 20.6875C6.66392 20.6875 7.5696 20.9601 8.3709 21.3998C8.4114 20.5585 8.44234 19.6919 8.46396 18.8125H2.91016C2.93178 19.6919 2.96266 20.5585 3.00322 21.3998C3.80452 20.96 4.7102 20.6875 5.68706 20.6875V20.6875Z"
        fill={fill}
      />
      <mask
        id="mask0_6584_748"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="0"
        width="32"
        height="32"
      >
        <g clipPath="url(#clip0_6584_748)">
          <path
            d="M33.4041 6.53533C33.2658 6.94821 33.0314 7.32821 32.7248 7.63489L31.7122 8.64746C31.5373 8.82233 31.4375 9.06402 31.4375 9.31027V10.4089C31.4375 10.9261 31.8577 11.3464 32.375 11.3464C33.9259 11.3464 35.1875 12.608 35.1875 14.1589V15.5917C35.1875 16.0257 35.0849 16.4605 34.8909 16.8487L34.5704 17.4896C34.3589 17.9117 34.5283 18.2678 34.6116 18.4015C34.694 18.5352 34.9366 18.8464 35.4091 18.8464H38.7362C38.8999 17.9317 39 16.995 39 16.0339C39 11.5386 37.0594 7.48021 34.0558 4.58008L33.4041 6.53533Z"
            fill={fill}
          />
          <path
            d="M15.1884 11.2506C14.9742 11.6397 15.1161 12.1514 15.5125 12.4151L17.4608 13.7142C17.437 12.243 18.2692 10.8102 19.7313 10.0594C20.005 9.91937 20.1882 9.56049 20.1882 9.16587V8.53418C20.1882 6.9833 21.4498 5.72168 23.0007 5.72168H24.1322C24.438 5.72168 24.7164 5.57887 24.894 5.3298C25.0725 5.0808 25.1183 4.77224 25.0194 4.48205L23.5359 0.0613047C23.3567 0.0553672 23.1813 0.0341797 23.0006 0.0341797C17.881 0.0341797 13.2674 2.53299 10.3496 6.2963L13.3464 7.02999C15.1865 7.48718 16.1107 9.56805 15.1884 11.2506Z"
            fill={fill}
          />
          <path
            d="M26.4189 6.42088C25.8925 7.15695 25.0374 7.59645 24.1319 7.59645H23.0003C22.4831 7.59645 22.0628 8.0167 22.0628 8.53395V9.16563C22.0628 10.2624 21.4833 11.2677 20.587 11.7273C19.4975 12.2866 19.0398 13.5162 19.5452 14.526L19.8301 15.0964H20.9038C22.5535 15.0964 24.0377 16.0138 24.7765 17.4896C25.1948 18.3264 26.0362 18.8464 26.9719 18.8464H28.6253C29.1435 18.8464 29.5628 19.2657 29.5628 19.7839V21.6589C29.5628 22.9223 29.0675 24.107 28.1694 24.9941C26.7222 26.4226 25.3282 29.365 24.3538 31.9653C30.9129 31.4099 36.4163 26.8132 38.2947 20.7214H35.4094C34.427 20.7214 33.5326 20.2224 33.0162 19.3874C32.5008 18.5525 32.4541 17.5289 32.8935 16.6509L33.214 16.0101C33.279 15.881 33.3128 15.7363 33.3128 15.5917V14.1589C33.3128 13.6416 32.8926 13.2214 32.3753 13.2214C30.8245 13.2214 29.5628 11.9598 29.5628 10.4089V9.31026C29.5628 8.55951 29.8558 7.85276 30.3868 7.32176L31.3994 6.3092C31.502 6.20663 31.5798 6.07938 31.6265 5.94113L32.5144 3.27788C30.5124 1.78076 28.1534 0.68432 25.5842 0.262695L26.7934 3.87757C27.0854 4.73357 26.9453 5.68476 26.4189 6.42088V6.42088Z"
            fill={fill}
          />
          <path
            d="M21.4507 31.9557C20.5606 30.0934 20.1875 26.2475 20.1875 24.6036C20.1875 24.1641 20.0163 23.7512 19.7059 23.4408L18.5872 22.3221C18.221 21.9559 18.221 21.3626 18.5872 20.9964L19.1457 20.4379C19.6474 19.9362 19.771 19.1726 19.4543 18.5391L18.5227 16.6759L14.4719 13.9751C13.2735 13.1768 12.8652 11.5837 13.5436 10.3478C13.8717 9.7528 13.5488 9.01287 12.8917 8.84912L9.22762 7.94824C7.82194 10.3224 7 13.0809 7 16.0342C7 24.2994 13.3873 31.1737 21.4507 31.9557Z"
            fill={fill}
          />
          <path
            d="M27.686 21.6592V20.7217H26.97C25.3203 20.7217 23.8361 19.8043 23.0973 18.3285C22.679 17.4917 21.8376 16.9717 20.9019 16.9717H20.7655L21.1298 17.7004C21.7863 19.0133 21.5592 20.5844 20.5723 21.6574L21.03 22.1151C21.6948 22.7798 22.061 23.6633 22.061 24.6036C22.061 26.1261 22.3786 28.9397 22.8959 30.5309C23.7528 28.3804 25.1508 25.3369 26.8501 23.6606C27.3893 23.1277 27.686 22.4172 27.686 21.6592V21.6592Z"
            fill={fill}
          />
        </g>
      </mask>
      <g mask="url(#mask0_6584_748)">
        <rect x="12" y="-2" width="29" height="36" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_6584_748">
          <rect width="32" height="32" fill="white" transform="translate(7)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
