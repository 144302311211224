import * as React from 'react';

function ArtistCertificate(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M38.331 33.222h-9.34v-1.781c3.346-1.806 5.625-5.34 5.625-9.394v-6.07c.303-.103.601-.23.89-.382a6.056
          6.056 0 002.986-3.583 6.102 6.102 0 00-4.005-7.631 34.696 34.696 0 00-9.092-1.558V1.406a1.406
          1.406 0 00-2.812 0v1.431c-3.08.142-6.146.7-9.135 1.67a6.101 6.101 0 00-3.912 7.68 6.079 6.079
          0 003.683 3.837v6.023c0 4.054 2.278 7.588 5.625 9.394v1.781H9.669C4.337 33.222 0 37.55 0
          42.867v.917A4.225 4.225 0 004.225 48h39.55A4.225 4.225 0 0048
          43.784v-.917c0-5.318-4.337-9.645-9.669-9.645zM23.917 38.79a5.08 5.08 0
          004.516-2.755h2.74v9.153h-14.34v-9.153h2.57a5.08 5.08 0 004.514 2.755zM12.21
          11.318a3.286 3.286 0 012.107-4.135 31.825 31.825 0 019.848-1.569c3.19 0 6.38.484
          9.485 1.452a3.285 3.285 0 012.157 4.11 3.259 3.259 0 01-1.608 1.929 3.258 3.258
          0 01-2.5.227 25.174 25.174 0 00-15.354.093 3.275 3.275 0 01-4.135-2.107zm3.821
          10.73v-5.69c.4-.046.796-.132 1.183-.258a22.376 22.376 0 0113.647-.083c.311.097.626.169.943.215v5.815c0
          4.334-3.538 7.86-7.887 7.86-4.348 0-7.886-3.526-7.886-7.86zm7.886 10.67c.776 0
          1.532-.082 2.262-.24v1.25c0 1.24-1.015 2.25-2.262 2.25a2.259 2.259 0 01-2.26-2.25v-1.25c.728.158
          1.485.24 2.26.24zM2.812 43.785v-.917c0-3.767 3.076-6.832 6.857-6.832h4.351v9.153h-2.78v-2.624a1.406
          1.406 0 10-2.812 0v2.623H4.225a1.41 1.41 0 01-1.412-1.403zm42.376 0a1.41 1.41 0 01-1.413
          1.404h-4.203v-2.624a1.406 1.406 0 10-2.813 0v2.623h-2.774v-9.152h4.346c3.78 0 6.856 3.065 6.856 6.832v.917z"
        fill="#3F4041"
      />
    </svg>
  );
}

export default ArtistCertificate;
