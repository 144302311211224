import * as React from "react"

function DashboardSales(props) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)" fill={props.fill || '#3F4041'}>
        <path d="M9 11.307V4.442c-.195.018-.388.049-.583.058H2.812a.563.563 0 00-.562.562v.563H.562A.563.563 0 000 6.187v3.375c0 .31.252.562.562.562H2.25v.563c0 .31.252.562.562.562h.341l2.261 6.028A1.119 1.119 0 006.467 18c.3 0 .586-.12.791-.333l1.351-1.34c.345-.345.428-.884.207-1.301l-1.888-3.777h1.494c.194.01.385.04.577.058zM17.907.272a.556.556 0 00-.271-.233c-.003 0-.005-.005-.009-.006-.011-.004-.021.002-.032-.002C17.544.017 17.492 0 17.436 0c-.038 0-.072.015-.11.022-.02.005-.04.006-.062.013a.558.558 0 00-.246.16c-.005.004-.012.005-.016.01a11.836 11.836 0 01-6.878 4.075v7.19a11.835 11.835 0 016.878 4.074c.004.006.012.008.018.015.04.044.088.079.14.108.016.01.03.025.047.033a.578.578 0 00.42.016c.003 0 .005-.005.008-.006a.547.547 0 00.235-.18c.014-.018.024-.034.036-.053a.544.544 0 00.093-.29V.562a.544.544 0 00-.093-.29z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DashboardSales
