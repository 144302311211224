/* eslint-disable max-len */
import React from 'react';
import styles from './icons.module.scss';

export const Cart = ({ size = 'md', className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 26"
    className={`${styles.svg} ${styles[size]} ${className}`}
  >
    <path
      d={`M26.4557 4.34124C26.4288 4.34124 26.3959 4.3352 26.3596 4.32854C26.3151 4.32038 26.2657
      4.31131 26.2162 4.31131H7.35429L7.05489 2.30535C6.87525 0.988008 5.73755 0 4.39027 0H1.96516C1.30649
      0 0.767578 0.538913 0.767578 1.19758C0.767578 1.85626 1.30649 2.39517 1.96516 2.39517H4.39027C4.53997
      2.39517 4.65972 2.51493 4.68966 2.66463L6.54592 15.2991C6.78544 16.8859 8.16266 18.0835 9.7794
      18.0835H22.2343C23.7911 18.0835 25.1384 16.9758 25.4677 15.4488L27.4138 5.71847C27.5336 5.08973
      27.1144 4.461 26.4557 4.34124ZM20.975 19.1904C19.2685 19.1904 17.8613 20.5676 17.8613
      22.3041C17.8613 24.0107 19.2385 25.4179 20.975 25.4179C22.6816 25.4179 24.0887 24.0406
      24.0887 22.3041C24.0588 20.5976 22.6816 19.1904 20.975 19.1904ZM10.2596 19.1904C11.9063 19.1904
      13.2835 20.5078 13.3434 22.1545C13.4332 23.861 12.0859 25.2981 10.3794 25.3879H10.3195C8.67281
      25.3879 7.32551 24.0706 7.26563 22.4239C7.20575 20.7174 8.52311 19.2802 10.2596 19.1904Z`}
    />
  </svg>
);
