export const SET_GALLERY_LOADING = 'SET_GALLERY_LOADING';

export const GET_USER_ARTWORKS_SUCCESS = 'GET_USER_ARTWORKS_SUCCESS';

export const GET_USER_ARTWORKS_PAGINATION = 'GET_USER_ARTWORKS_PAGINATION';

export const GET_USER_AVATAR_SUCCESS = 'GET_USER_AVATAR_SUCCESS';

export const GET_SUBSCRIBED_ARTIST_SUCCESS = 'GET_SUBSCRIBED_ARTIST_SUCCESS';

export const SET_SUBSCRIBED_ARTIST_ALL = 'SET_SUBSCRIBED_ARTIST_ALL';

export const GET_SUBSCRIBED_ARTIST_PAGINATION = 'GET_SUBSCRIBED_ARTIST_PAGINATION';

export const SET_USER_AVATAR_LOADING = 'SET_USER_AVATAR_LOADING';

export const SET_BLOG_POSTS = 'SET_BLOG_POSTS';

export const SET_BLOG_POST = 'SET_BLOG_POST';

export const DELETE_SINGLE_BLOG_POST = 'DELETE_SINGLE_BLOG_POST';

export const OPEN_BLOG_EDITING_MODAL = 'OPEN_BLOG_EDITING_MODAL';

export const UPDATE_SINGLE_BLOG_POST = 'UPDATE_SINGLE_BLOG_POST';

export const SET_MODAL_TRUE = 'SET_MODAL_TRUE';

export const SET_MODAL_FALSE = 'SET_MODAL_FALSE';

export const SET_CONTACTS = 'SET_CONTACTS';

export const SET_CONTACT = 'SET_CONTACT';

export const UNSET_CONTACT = 'UNSET_CONTACT';

export const SET_EDIT_MODE_FALSE = 'SET_EDIT_MODE_FALSE';

export const SET_EDIT_MODE_TRUE = 'SET_EDIT_MODE_TRUE';

export const SET_RECIPIENTS = 'SET_RECIPIENTS';

export const UNSET_RECIPIENTS = 'UNSET_RECIPIENTS';

export const RESET_RECIPIENTS = 'RESET_RECIPIENTS';

export const SET_FOR_SALE_STATUS = 'SET_FOR_SALE_STATUS';

export const UPDATE_ARTWORK_PRICE = 'UPDATE_ARTWORK_PRICE';

export const SET_DELETE_MODE_TRUE = 'SET_DELETE_MODE_TRUE';

export const SET_DELETE_MODE_FALSE = 'SET_DELETE_MODE_FALSE';

export const SET_DELETE_CONTACT = 'SET_DELETE_CONTACT';

export const TOGGLE_HISTORY_LIST = 'TOGGLE_HISTORY_LIST';

export const TOGGLE_CONTACTS_LIST = 'TOGGLE_CONTACTS_LIST';

export const SET_ACTIVE_MESSAGE = 'SET_ACTIVE_MESSAGE';

export const SET_IS_CAN_USE = 'SET_IS_CAN_USE';

export const SET_MESSAGES = 'SET_MESSAGES';

export const SET_DELETE_MESSAGE = 'SET_DELETE_MESSAGE';

export const SET_PREVIEW_MODAL = 'SET_PREVIEW_MODAL';

export const GET_PREVIEW_TEMPLATE_SUCCESS = 'GET_PREVIEW_TEMPLATE_SUCCESS';

export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';

export const SET_RESET_QUILL = 'SET_RESET_QUILL';

export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';
